<template>
    <div class="">			        
        <ul class="dashborad-menus">
            <li>
                <router-link 
                    to="/admin-dashboard"
                >
                    <i class="feather-grid"></i> <span>Inicio</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/admin-franchise-list"
                >
                    <i class="fa-solid fa-store"></i> <span>Franquicias</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/admin-category-list"
                >
                    <i class="feather-list"></i> <span>Rubros</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/admin-news-list"
                >
                    <i class="fa-solid fa-newspaper"></i> <span>Noticias</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/admin-consulting-list"
                >
                    <i class="fa-solid fa-users-between-lines"></i> <span>Consultoras</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/admin-user-list"
                >
                    <i class="fas fa-solid fa-users"></i> <span>Usuarios</span>
                </router-link>
            </li>
            <li>
                <router-link 
                    to="/login"
                >
                <i class="fas fa-sign-out-alt"></i> <span>Cerrar Sesión</span>
                </router-link>
            </li>
        </ul>											
    </div>
</template>

<script>

export default {
      data() {
          return {
        	menu: [
				{
				header: 'Franquiciar',
				hiddenOnCollapse: true
				},
				{
				href: '/',
				title: 'Inicio',
				icon: 'fa fa-user'
				},
				{
				href: '/charts',
				title: 'Contenido web',
				icon: 'fa fa-chart-area',
				child: [
					{
					href: '/charts/sublink',
					title: 'Franquicias'
					},
					{
					href: '/charts/sublink',
					title: 'Categorías'
					},
					{
					href: '/charts/sublink',
					title: 'Noticias'
					},
					{
					href: '/charts/sublink',
					title: 'Datos'
					},
				]
				}
            ]
          }
      },

  }
</script>