<template>
    <mainsidebar/> 
      <div 
        class="main-wrapper"
        style="background: #f9fafc;"  
      >
          <div class="page-wrapper"
            style="background: #f9fafc;"
          >
            <div class="dashboard-content">		
			<div class="container">
				<dashboardmenu />
				<div class="profile-content">
				    <div class="messages-form">
					    <div class="card">
						    <div class="card-header">
                                <h2> {{ company.name }} </h2>	
							    <h4>Información Basica</h4>							
							</div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-lg-4 col-md-6">
									    <ul>
                                            <li>
                                                <label class="custom_check">
                                                    <input 
														type="checkbox" 
														name="wireless-internet"
														v-model="company.active"
														:value="company.active"
													>
                                                    <span class="checkmark"></span> Activa 
                                                </label>
                                            </li>
                                        </ul>
									</div>
                                    <div class="col-lg-4 col-md-6">
									    <ul>
                                            <li>
                                                <label class="custom_check">
                                                    <input 
														type="checkbox" 
														name="wireless-internet"
														v-model="company.outstanding"
														:value="company.outstanding"
													>
                                                    <span class="checkmark"></span> Ficha Destacada
                                                </label>
                                            </li>
                                        </ul>
									</div>
									<div class="col-lg-4 col-md-6">
									    <ul>
                                            <li>
                                                <label class="custom_check">
                                                    <input 
														type="checkbox" 
														name="wireless-internet"
														v-model="company.master"
														:value="company.master"
													>
                                                    <span class="checkmark"></span> Franquicia Master
                                                </label>
                                            </li>
                                        </ul>
									</div>											
								</div>
							    <div class="form-group">
								    <label class="col-form-label">Nombre de la Franquicia <span>*</span></label>								    
									<input 
                                        type="text" 
                                        class="form-control pass-input" 
                                        placeholder="Escriba el nombre de la franquicia o negocio"
										v-model="company.name"
                                    >									   
								</div>
								<div class="form-group">
								    <label class="col-form-label">Descripción del negocio <span>*</span></label>
								   	<textarea 
                                        rows="6" 
                                        class="form-control listingdescription" 
										v-model="company.description"
                                        placeholder="Escriba una descripcion de la franquicia o negocio"
                                    >{{ company.description }}</textarea>												
								</div>
								<div class="form-group">
                                    <label class="col-form-label label-heading">Seleccione una Categoria </label>
                                    <div class="row category-listing">
                                    <div v-for="(column, index) in categorizedColumns" :key="index" class="col-lg-4">
                                        <ul>
											<li 
												v-for="category in column" 
												:key="category.id"
											>
												<label class="custom_check">
												<input 
													type="checkbox" 
													:name="category.name"
													:value="category.id"
													v-model="categoryData"
													:checked="isChecked(category.id)"
												>
												<span class="checkmark"></span> {{ category.name }}
												</label>
											</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
								<div class="form-group formlast-input">
								    <label class="col-form-label label-heading">Enlace</label>								    
									<textarea 
                                        rows="2" 
                                        class="form-control tagline" 
                                        readonly
                                        placeholder=""
                                    >{{ company.link }}</textarea>									   
								</div>
								<div class="form-group formlast-input">
								    <label class="col-form-label label-heading">Palabras Clave (Coleccion de palabras clave para posicionar esta ficha)</label>								    
									<textarea 
                                        rows="1" 
                                        class="form-control" 
                                        placeholder=""
                                    >{{ company.keyword }}</textarea>									   
								</div>					
							</div>   							
						</div>	
                        <div class="card">
						    <div class="card-header">
							    <h4>Información de contacto</h4>							
							</div>
                            <div class="card-body">
							    
								<div class="row">
									<div class="col-lg-6 col-md-6">
									    <div class="form-group formlast-input">
											<label class="col-form-label">Numero de Teléfono</label>
											<input 
                                                type="text" 
                                                class="form-control pass-input" 
                                                placeholder="Escriba el numero de teléfono"
												v-model="company.phone"
                                                :value="company.phone"
                                            >		
										</div>
									</div>
									<div class="col-lg-6 col-md-6">
									    <div class="form-group formlast-input formlast-input-inner">
											<label class="col-form-label">Email</label>
											<input 
                                                type="email" 
                                                class="form-control pass-input" 
                                                placeholder="Escriba el numero de teléfono"
												v-model="company.email"
                                                :value="company.email"
                                            >
										</div>
									</div>											
								</div>
                                <div class="row">
									<div class="col-lg-6 col-md-6">
									    <div class="form-group formlast-input">
											<label class="col-form-label">Pagina Web</label>
											<input 
                                                type="url" 
                                                class="form-control pass-input" 
                                                placeholder="Escriba el numero de teléfono"
												v-model="company.website"
                                                :value="company.website"
												@input="addHttp"
                                            >		
										</div>
									</div>
									<div class="col-lg-6 col-md-6">
									    <div class="form-group formlast-input formlast-input-inner">
											<label class="col-form-label">Teléfono secundario</label>
											<input 
                                                type="text" 
                                                class="form-control pass-input" 
                                                placeholder="Escriba el numero de teléfono"
												v-model="company.phone2"
                                                :value="company.phone2"
                                            >
										</div>
									</div>											
								</div>
							</div>
						</div>					
                        <div class="card">
						    <div class="card-header">
							    <h4>Ubicaciones y Sucursales</h4>							
							</div>
                            <div class="card-body">
							    <div class="form-group">
								    <label class="col-form-label">Dirección</label>								    
									<input 
                                        type="text" 
                                        class="form-control pass-input" 
                                        placeholder="Escriba una dirección"
										v-model="company.address"
                                        :value="company.address"
                                    >									   
								</div>

								<b-overlay :show="showSave" rounded="sm">
								<div class="form-group">
									<label class="col-form-label">Selecciona los países la franquicia está presente </label>		
									<VueMultiselect
										v-model="countries"
										:options="options"
										:multiple="true"
										:taggable="true"
										@tag="addTag"
										tag-placeholder="Add this as new tag"
										@update:model-value="updateCompany"
										placeholder="Type to search or add tag"
										label="name"
										track-by="id"
									/>
								</div>
								</b-overlay>
								

								<hr/>
								<label class="col-form-label">Sucursales</label>	
                                <div class="listing-map">
									<GoogleMap 
										ref="mapRef" 
										api-key="AIzaSyDz228dol3MOtegTgC_2Qh5vjHAf9y79qA" 
										style="width: 100%; height: 400px;" 
										class="map" 
										@click="handleMapClick"
										:center="center" 
										:zoom="4"
									>
										<template v-if="markerPosition">
											<Marker :options="markerPosition" />
										</template>
									</GoogleMap>
								</div>	
								<div class="row">
									<div class="col-lg-6 col-md-6">	
										<div class="form-group">
											<label class="col-form-label">Titulo de la Sucursal</label>								    
											<input 
												type="text" 
												class="form-control pass-input" 
												placeholder="Escriba una dirección"
												v-model="headquarter.name"
											>									   
										</div>
									</div>
									<div class="col-lg-6 col-md-6">	
										<div class="form-group">
											<label class="col-form-label">Dirección de la Sucursal</label>								    
											<input 
												type="text" 
												class="form-control pass-input" 
												placeholder="Escriba una dirección"
												v-model="headquarter.address"
											>									   
										</div>
									</div>
								    <div class="col-lg-6 col-md-6">									     
									    <div class="form-group formlast-input lat-input">
											<input 
												type="text" 
												class="form-control" 
												v-model="headquarter.latitude"
												size="sm"
												readonly
											>									   
										</div>
									</div>
									<div class="col-lg-6 col-md-6">									     
									      <div class="form-group formlast-input">
											<input 
												type="text" 
												class="form-control" 
												v-model="headquarter.longitude"
												size="sm"
												readonly
											>									   
										</div>
									</div>
								</div>	
								<div class="row">
									<div class="col-lg-12 col-md-12">	
									<b-overlay
										:show="showSave"
										rounded
										opacity="0.6"
										spinner-small
										spinner-variant="primary"
										class="d-inline-block"
										@hidden="onHidden"
									>	
										<b-button
											ref="cancel"
											variant="outline-secundary"
											size="sm"
											class="mt-4"
											block
											@click="newHeadquarter"
										>
											<i class="fas fa-plus"></i> Nueva Sucursal
										</b-button>
									</b-overlay>
									</div>
								</div>
								<div class="row">
									<b-overlay :show="showSave" rounded="sm">
										<b-table striped hover 
										:items="flattenedHeadquarters"
										:fields="fields"
										>
											<template #cell(action)="row">
												<b-button 
													size="sm"
													@click="deleteHeadquarter(row.index)"
												>
													<i class="fas fa-trash-alt"></i>
												</b-button>
											</template>
										</b-table>
									</b-overlay>
								</div>							
							</div>
						</div>	
						<div 
							v-for="(group, type) in groupedData" 
							:key="type"
						>
							<div class="card" v-if="type != 9">
							<div class="card-header">
								<h4>{{ getBlockTitle(type) }}</h4>
							</div>
							<div class="card-body">
								<div 
									v-for="(item, index) in group" 
									:key="index" 
									class="form-group"
								>
									<label class="col-form-label">{{ item.description }}</label>
									<div class="pass-group group-img">
										<span class="lock-icon"><i :class="item.icon"></i></span>
										<input 
											:type="getInputType(item.id)" 
											class="form-control" 
											value=""
											v-model="companyDataValue[item.id]"
										>
										<input 
											type="hidden"
											:value="companyDataCount[item.id]"
											v-model="companyDataCount[item.id]"
										>												
									</div>
								</div>
							</div>
							</div>
						</div>

						<b-overlay :show="showSave" rounded="sm">
							<b-button 
								variant="primary" 
								type="submit"
								@click="updateCompany()"
							>Guardar</b-button>		
                        </b-overlay>
                  		
						<b-overlay :show="showFile" rounded="sm">
                        <div class="card media-section mt-4">
						    <div class="card-header">
							    <h4>Documentos e Imagenes </h4>							
							</div>
                            <div class="card-body">
							    <div class="row">
									<div class="row">
										<div class="col-lg-6 col-md-6 featured-img2">
											<h6 class="Featured image">Logo</h6>
											<b-overlay :show="showLogo" rounded="sm">
												<div class="media-image">
													<img 
														v-if="company.logo" 
														:src="company.logo.route" 
														alt=""
														class="img-fluid"
													>
													<a 
														v-if="company.logo" 
														href="javascript:void(0)" 
														class="profile-img-del" 
														@click="deleteGalleryImage(company.logo.id)">
														<i class="feather-trash-2"></i>
													</a>
												</div>
											</b-overlay>
											<div class="settings-upload-btn" v-if="!company.logo">
												<input type="file" @change="uploadGalleryImage($event, 'logo')" accept="image/*" name="image" class="hide-input image-upload" id="file2">
												<label for="file2" class="file-upload">Subir Logo</label>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 featured-img1">
											<h6 class="media-title">Brochure</h6>
											<div class="media-image">
												<b-overlay :show="showCof" rounded="sm">
													<template v-for="(image, index) in company.uploads">
														<div v-if="image.type === 'cof'">
															<a :href="image.route" target="_blank">
																<i class="fa fa-file-download fa-4x"></i>
															</a>
															<a href="javascript:void(0)" class="profile-img-del mt-4" @click="deleteGalleryImage(image.id)">
																<i class="feather-trash-2"></i>
															</a>
														</div>
													</template>
												</b-overlay>
											</div>
											<div class="settings-upload-btn" v-if="!hasCof">
												<input type="file" @change="uploadGalleryImage($event, 'cof')" accept=".pdf" name="pdf" class="hide-input pdf-upload" id="file">
												<label for="file" class="file-upload">Subir Archivo PDF</label>
											</div>
										</div>
										
									</div>										
								</div>
								<hr/>
                                <div class="gallery-media">
									<b-overlay :show="showCover" rounded="sm">
									<h6 class="media-title">Portada</h6>
									<div class="galleryimg-upload">
										<!-- Renderizar las imágenes de la galería -->
										<div 
											v-for="(image, index) in company.uploads" 
											:key="index" 
											class="gallery-upload"
										>
											<div
												v-if="image.type == 'cover'"
											>
												<img 
													:src="image.route" 
													class="img-fluid"
													style="max-height: 200px;" 
													alt=""
												>
												<!-- Añadir un botón para eliminar la imagen -->
												<a 
													href="javascript:void(0)" 
													class="profile-img-del" 
													@click="deleteGalleryImage(image.id)">
													<i class="feather-trash-2"></i>
												</a>
											</div>
										</div>
									</div>
									</b-overlay>
									<!-- Añadir un botón para subir imágenes -->
									<div class="settings-upload-btn" v-if="!hasCoverImage">
										<input type="file"  @change="uploadGalleryImage($event, 'cover')" accept="image/*" name="image" class="hide-input image-upload" id="file2">
										<label for="file2" class="file-upload">Subir Imagen de Portada</label>
									</div>
								</div>	
								<hr/>
								<div class="gallery-media">
									<b-overlay :show="showImage" rounded="sm">
									<h6 class="media-title">Galeria de Imagenes</h6>
									<div class="galleryimg-upload">
										<!-- Renderizar las imágenes de la galería -->
										<div 
											v-for="(image, index) in company.uploads" 
											:key="index" 
											class="gallery-upload"
										>
											<div
												v-if="image.type == 'image'"
											>
												<img 
													:src="image.route" 
													class="img-fluid"
													style="max-width: 200px; max-height: 200px;" 
													alt=""
												>
												<!-- Añadir un botón para eliminar la imagen -->
												<a 
													href="javascript:void(0)" 
													class="profile-img-del" 
													@click="deleteGalleryImage(image.id)">
													<i class="feather-trash-2"></i>
												</a>
											</div>
										</div>
									</div>
									</b-overlay>
									<!-- Añadir un botón para subir imágenes -->
									<div class="settings-upload-btn">
										<input type="file"  @change="uploadGalleryImage($event, 'image')" accept="image/*" name="image" class="hide-input image-upload" id="file2">
										<label for="file2" class="file-upload">Subir Imagen para Galería</label>
									</div>
								</div>						
							</div>
						</div>	
						<template #overlay>
							<div class="text-center">
							<b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
							<p id="cancel-label">Para agregar documentos e imagenes primero configure una ficha</p>
							<b-button
								ref="cancel"
								variant="outline-danger"
								size="sm"
								aria-describedby="cancel-label"
								@click="show = false"
							>
								Cancel
							</b-button>
							</div>
						</template>
                        </b-overlay>				
					</div>			
				</div>
			</div>		
		</div>		
  
          
  
              <scroll />
          </div>
      </div>
  </template>
  
  
  <script>
  import axios from 'axios'; 
  import VueMultiselect from 'vue-multiselect'
  import { defineComponent, ref, computed, watch } from "vue"
  import { GoogleMap, Marker } from "vue3-google-map";

  export default {
	  components: { GoogleMap, Marker, VueMultiselect },
	  setup() {
			const mapRef = ref(null);
			const center = { lat: 17.979399552360842, lng: -74.44997322752937 };

			const _lng = ref(0);
			const lng = computed(
				{
					get: () => _lng.value,
					set: (v) => {
						if (!Number.isFinite(v)) {
						_lng.value = 0;
						} else if (v > 180) {
						_lng.value = 180;
						} else if (v < -180) {
						_lng.value = -180;
						} else {
						_lng.value = v;
						}
					},
				}
			);

			watch(lng, () => {
			if (mapRef.value?.ready) {
				mapRef.value.map.panTo({ lat: 0, lng: lng.value });
			}
			}); 

			return { mapRef, center, lng };
	  },
      data() {
          return {
            categories: [],
			selectedCountries: [],
			countries: [],
			fields: [
				{ key: 'titulo', label: 'Listado de sucursales' },
				{ key: 'action', label: '' }
			],
			editData: {
                description: '',
                type: '',
                selection: '',
                icon: '',
                company_type: '',
            },
			itemValue: '',
        	cleanedValue: '',
            company: {
				phone: 'N/A',
				email: 'prueba@prueba.com',
				website: 'https://prueba.com',
				phone2: 'N/A',
			},
			fileSelected: false,
			typeDatas: [
                { code: 10, name: '5 Razones para elegir nuestra franquicia' },
                { code: 14, name: 'CONTRATACIONES' },
                { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
                { code: 3, name: 'DATOS OPERATIVOS' },
                { code: 11, name: 'EXPERIENCIA' },
                { code: 4, name: 'DATOS ECONOMICOS' },
                { code: 13, name: 'INFORMACIÓN GENERAL' },
                { code: 9, name: 'Sucursales' },
                { code: 1, name: 'OBJETIVO' },
                { code: 5, name: 'OTROS DATOS IMPORTANTES' },
                { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
                { code: 6, name: 'Información de la Empresa para Facturación' },
                { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
                { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
                { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
            ],
			companyData: [],
			headquarters: [],
			headquarter: {
				name: '',
				address: '',
				latitude: '',
				longitude: '',
			},
			companyDataValue: [],
			companyDataCount: [],
			options: [],
        	search: '',
			showImage: false,
			showCover: false,
			showCof: false,
			showLogo: false,
			showSave: false,
			showFile: false,
			markerPosition: null,
        	value: [],
			categoryData: [],
			title: "Login",
			text: "Inicio",
			text1: "Login",
			name: "/",
          }
      },
	mounted() {
		// Llamar a la API para obtener la lista de compañías
		this.fetchCategories();
		this.fetchCompany();
		this.fetchCompanyData();
		this.fetchTags();
	},
	computed: {

		categorizedColumns() {
			const columns = 3;
			const categoriesCopy = [...this.categories];
			const categorized = Array.from({ length: columns }, (_, index) =>
				categoriesCopy.filter((_, i) => i % columns === index)
			);
			return categorized;
		},
		groupedData() {
			const result = {};
			this.companyData.forEach(item => {
				if (!result[item.type]) {
				result[item.type] = [];
				}
				result[item.type].push(item);
			});
			return result;
		},
		hasCoverImage() {
			if (this.company && this.company.uploads) {
				return this.company.uploads.some(image => image.type === 'cover');
			}
			return false;
		},
		hasCof() {
			if (this.company && this.company.uploads) {
				return this.company.uploads.some(image => image.type === 'cof');
			}
			return false;
		},
		flattenedHeadquarters() {
			const formatted = [];
			this.headquarters.forEach(group => {
				group.forEach(item => {
				const existing = formatted.find(el => el.count === item.count);
				if (existing) {
					existing[item.description.toLowerCase()] = item.value;
				} else {
					const newItem = { count: item.count };
					newItem[item.description.toLowerCase()] = item.value;
					newItem.id = item.id;
					formatted.push(newItem);
				}
				});
			});
			return formatted;
		}

	},
	methods: {

		onHidden() {
			this.$refs.button.focus()
		},
		addTag (newTag) {
			const tag = {
				name: newTag,
				id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
			}
			this.options.push(tag)
			this.countries.push(tag)
			this.updateCompany()
		},
		fetchCategories() {
				axios.get(process.env.VUE_APP_API_DIRECTORY+'categories?limit=300&type=0', {
					headers: {
						"Accept": "application/json",
						"Authorization": "Bearer "+process.env.VUE_APP_TOKEN
					}
				})
				.then(response => {
					this.categories = response.data; // Actualiza la propiedad categories con la respuesta de la API
				})
				.catch(error => {
					console.error('Error al obtener categorías:', error);
				});
		},
		newHeadquarter() {
			let count_api = Math.ceil(Math.random()*10000000)
			this.headquarters.push([
				{ id: 33, description: 'Titulo', value: this.headquarter.name, count: count_api },
				{ id: 34, description: 'Dirección', value: this.headquarter.address, count: count_api },
				{ id: 35, description: 'Latitud', value: this.headquarter.latitude, count: count_api },
				{ id: 36, description: 'Longitud', value: this.headquarter.longitude, count: count_api }
			]);
			this.headquarter = {
				name: '',
				address: '',
				latitude: '',
				longitude: '',
			}
			this.updateCompany()
		},
		deleteHeadquarter(index) {
			this.$swal
				.fire({
					title: "¿Estás seguro?",
					text: "¡No podrás revertir esto!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Sí, eliminarlo",
				})
				.then(async (result) => {
					if (result.isConfirmed) {
						try {
							this.headquarters.splice(index, 1)
							console.log(this.headquarters)
							this.updateCompany()
						} catch (error) {
							this.$swal.fire({
								title: "Error al eliminar",
								text: "Hubo un error al intentar eliminar la categoria.",
								icon: "error",
							});
							console.error("Error deleting data:", error);
						}
					}
				});
		},
		sanitizeInput(dataId) {
			console.log(dataId)
			const inputValue = this.companyDataValue[dataId];

			if (typeof inputValue === 'number') { 
				const sanitizedValue = inputValue.toString().replace(/[.,e]/g, ''); 
				const intValue = parseInt(sanitizedValue); 

				if (!isNaN(intValue)) { 
					this.companyDataValue[dataId] = intValue; 
				} else {
					console.log('Por favor, ingrese un número entero válido.');
				}
			} else {
				console.log('El valor no es numérico.');
			}
		},

	getInputType(itemId) {
		switch (itemId) {
			case 8:
			case 9:
			case 12:
				return 'number';
			default:
				return 'text'; 
		}
	},

	addHttp() {
		if (this.company.website && !/^https?:\/\//i.test(this.company.website)) {
			this.company.website = 'http://' + this.company.website;
		}
	},
        
	fetchCompany() {
		if(this.$route.params.idFranchise == 0){
			this.showFile = true
		}else{
			this.showFile = false
		}
		axios.get(process.env.VUE_APP_API_DIRECTORY+'companies/'+this.$route.params.idFranchise, {
			headers: {
				"Accept": "application/json",
				"Authorization": "Bearer "+process.env.VUE_APP_TOKEN
			}
		})
		.then(response => {
			this.company = response.data; // Actualiza la propiedad categories con la respuesta de la API
			
			this.categoryData = this.company.categories.map(category => category.id);
			this.countries = this.company.countries
			let headquartersMap = {};

			response.data.data.forEach(item => {
				let description = item.company_data.value;
				let value = item.id;
				const count = item.company_data.count;

				if(item.company_data.data_id != null){
					//rellenar el arrray head quaters
					if (item.id >= 33 && item.id <= 36 && item.type === 9) {
						if (!headquartersMap[count]) {
							headquartersMap[count] = [];
						}
						headquartersMap[count].push({
							id: item.id,
							description: item.description,
							value: item.company_data.value,
							count: count
						});
					}else{
						const value = item.company_data.value;
						this.companyDataValue[item.id] = value
						this.companyDataCount[item.id] = count
					}
				}
			});

			this.headquarters = Object.values(headquartersMap);

		})
		.catch(error => {
			console.error('Error al obtener fichas:', error);
		});
	},

	isChecked(categoryId) {
		return this.categoryData.includes(categoryId);
	},

	fetchTags() {
      axios.get(process.env.VUE_APP_API_DIRECTORY + 'countries?active=1&limit=150', {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + process.env.VUE_APP_TOKEN
          }
        })
        .then(response => {
          this.options = response.data.map(country => ({
            id: country.id.toString(),
            name: country.name
          }));
        })
        .catch(error => console.error('Error fetching countries:', error));
    },

	getBlockTitle(type) {
		const typeData = this.typeDatas.find(data => data.code === parseInt(type));
		return typeData ? typeData.name : `Contact Information ${type}`;
	},

	fetchCompanyData() {
		axios.get(process.env.VUE_APP_API_DIRECTORY + 'data?order=created_at&by=asc&limit=50&company_type=1', {
			headers: {
			"Accept": "application/json",
			"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
			}
		})
		.then(response => {
			this.companyData = response.data;
		})
		.catch(error => {
			console.error('Error al obtener datos de la compañía:', error);
		});
	},



	handleMapClick(event) {
      this.headquarter.latitude = event.latLng.lat();
      this.headquarter.longitude = event.latLng.lng();
	  let algo = { lat: event.latLng.lat(), lng: event.latLng.lng() }; 
	  this.markerPosition = { position: algo, label: "L", title: "LADY LIBERTY" };
	},

	uploadGalleryImage(event, type) {
		if(type == 'cover'){
			this.showCover = true;
		}else if(type == 'cof'){
			this.showCof = true;
		}else if(type == 'logo'){
			this.showLogo = true;
		}else{
			this.showImage = true;
		}
		
		const formData = new FormData();
		formData.append('_method', 'patch');
		formData.append('fileType', type);
   		formData.append('file', event.target.files[0]); 

		const headers = {
			'Content-Type': 'multipart/form-data',
			'Accept': 'application/json',
			"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
		};

		axios.post(process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.params.idFranchise, formData, { headers })
			.then(response => {
				this.fetchCompany();
				console.log('Imagen subida exitosamente:', response.data);
				this.showImage = false;
				this.showCof = false;
				this.showLogo = false;
				this.showCover = false;
			})
			.catch(error => {
				console.error('Error al subir la imagen:', error);
				this.showImage = false;
				this.showCover = false;
				this.showCof = false;
				this.showLogo = false;
			});
	},

	deleteGalleryImage(imageId) {
		axios.delete(process.env.VUE_APP_API_DIRECTORY + 'upload-management/delete-upload/' + imageId, {
			headers: {
				"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
			}
		})
		.then(response => {
			console.log('Imagen de la galería eliminada exitosamente:', response.data);
			this.fetchCompany()
		})
		.catch(error => {
			console.error('Error al eliminar la imagen de la galería:', error);
		});
	},

	async updateCompany() {
		let response;
		try {    
			this.showSave = true
			const formData = new FormData();
			let count = 0 
			formData.append("description", this.company.description);
			formData.append("name", this.company.name);
			if(this.company.active === true){
				formData.append("active", 1);
			}else{
				formData.append("active", 0);
			}
			if(this.company.outstanding === true){
				formData.append("outstanding", 1);
			}else{
				formData.append("outstanding", 0);
			}
			if(this.company.master === true){
				formData.append("master", 1);
			}else{
				formData.append("master", 0);
			}
			if(this.categoryData !== null){
				this.categoryData.forEach((value, index) => {
					formData.append("categories["+index+"]", value);
				});
			}
			this.countries.forEach((country) => {
				formData.append('countries[]', country.id)
			})

			// Agregar datos específicos de la empresa
			for (const key in this.companyDataValue) {
				const value = this.companyDataValue[key];
				if (value != '') {
					count = key
					formData.append(`data[${count}][data_id]`, key);
					formData.append(`data[${count}][value]`, value);
					formData.append(`data[${count}][count]`, 0);
				}
			}
			count ++
			// Agregar los datos de headquarters
			if(this.headquarters.length > 0)
			{
				this.headquarters.forEach(group => {
					group.forEach(item => {
						formData.append(`data[${count}][data_id]`, item.id);
						formData.append(`data[${count}][value]`, item.value);
						formData.append(`data[${count}][count]`, item.count);
						count ++
					});
				});
			}
			
			formData.append("address", this.company.address);
			formData.append("keyword", this.company.keyword);
			formData.append("phone", this.company.phone);
			formData.append("email", this.company.email);
			formData.append("website", this.company.website);
			formData.append("phone2", this.company.phone2);
			formData.append("user_id", 1); // nota: obtener id de la secion logeada 
			formData.append("type", 1);
			// formData.append("link", this.company.link);

			if (this.$route.params.idFranchise == 0) {
				response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "companies", formData, {
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/x-www-form-urlencoded",
						"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
					}
				});
			} else {
				formData.append("_method", "patch");
				response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "companies/" + this.$route.params.idFranchise, formData, {
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/x-www-form-urlencoded",
						"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
					}
				});
			}
			if (response.status === 201) {
				this.showSave = false
				this.showFile = false
				if(this.$route.params.idFranchise == 0){

					this.$swal.fire({
						title: "Creado!",
						text: "La ficha de esta empresa ha sido creada exitosamente.",
						icon: "success",
					}).then(() => {
						// Redireccionar a la misma página con el ID del objeto creado
						const id = response.data.company.id; 
						const newRoute = this.$route.path.replace(/\/\d+$/, `/${id}`);
						this.$router.push(newRoute);
					});

				}
			}else if (response.status === 200) {
				this.showSave = false
				this.$swal.fire({
					title: "¡Actualizado!",
					text: "La descripción de la empresa se ha actualizado exitosamente.",
					icon: "success",
				});
			} else {
				this.showSave = false
				console.error(
					`Error al actualizar la descripción de la empresa: ${response.statusText} (${response.status})`
				);
				this.$swal.fire({
					title: "Ocurrio un error!",
					text: `Error al actualizar la descripción de la empresa: ${response.statusText} (${response.status})`,
					icon: "warning",
				});
			}

			console.log(response.data); // Muestra la respuesta del servidor
		} catch (error) {
			console.error("Error al actualizar la descripción de la empresa:", error);
			this.$swal.fire({
					title: "Ocurrio un error!",
					text: "Error al actualizar la descripción de la empresa: "+error,
					icon: "warning",
				});
		}
	}

    },
  }
  </script>

<style scoped>
.map {
  position: relative;
  width: 100%;
  height: 500px;
}

.map::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: red;
}

input[type="number"] {
  width: 200px;
  margin-top: 20px;
  margin-left: 10px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>