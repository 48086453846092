<template>
    <!-- Timeline Section -->
		<section class="timeline-section">
		    <div class="container">
			    <div class="row">
			        <div class="col-md-12 aos" data-aos="fade-up">
						<div class="section-heading-two text-center">
							<p>How it Works</p>
							<h2>Wedding Timeline & How it Works</h2>
							<img src="../../../assets/img/title-img.png" class="img-fluid" alt="title-img">
						</div>		
					</div>			     
				</div>	
				<div class="timeline-sec">
					<div class="row">
						<div class="col-md-12 aos" data-aos="fade-up">
							<ul class="timeline-wrap">
								<li class="time-box box-bottom">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-01.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>4:30<span>Ceremony</span></p>
									</div>
								</li>
								<li class="time-box box-top">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-02.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>7:30<span>Wedding</span></p>
									</div>
								</li>
								<li class="time-box box-bottom">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-03.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>10:00<span>Album Time</span></p>
									</div>
								</li>
								<li class="time-box box-top">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-04.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>12:30<span>Food Lunch</span></p>
									</div>
								</li>
								<li class="time-box box-bottom">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-05.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>3:30<span>Reception</span></p>
									</div>
								</li>
								<li class="time-box box-top">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-06.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>6:30<span>Cake Cutting</span></p>
									</div>
								</li>
								<li class="time-box box-bottom">
									<div class="date">
										<img src="../../../assets/img/icons/timeline-07.svg" class="img-fluid" alt="img">
									</div>
									<div class="box-content">
										<p>7:30<span>DJ & Dinner</span></p>
									</div>
								</li>
							</ul>
						</div>
					</div>			   
				</div>			   
			</div>		
		</section>		
		<!-- /Timeline Section -->
</template>