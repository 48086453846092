<template>

<b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      variant="white"
      opacity="1"
    >
    


    <div 
        v-for="(group, type) in groupedData" 
        :key="type"
    >
    <div 
        v-if="type == 3 || type == 4"
    >
        <div>
            <h5 style="text-transform: uppercase;  font-size: 13px;" >{{ getBlockTitle(type) }}</h5>
        </div>
        <div >
            <div class="row">
                <div 
                    v-for="(item, index) in group" 
                    :key="index" 
                    class="col-lg-12 col-md-12 col-sm-12"
                >
                    <div 
                        class="featureslist d-flex align-items-center"
                        v-if="companyDataValueArray[item.id] != null"
                    >
                        <div class="feature-img" style="padding: 9px;">
                            <i 
                              :class="item.icon" 
                              style="margin: 5px;"
                            ></i>
                        </div>
                        <div class="featues-info">
                            <h6
                                style="text-transform: uppercase;  font-size: 12px;"
                            >{{ item.description }} <br> <strong>{{ companyDataValueArray[item.id] }}</strong></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

</b-overlay>

</template>

<script>
import axios from 'axios'; // Importar Axios

export default {
      props: {
            idCompany: String
      },
      data() {
          return {
        	inversionInicial: 0,
            inversionFinal: 0,
            companyDataValue: [],
            show: true,
            companyDataValueArray: [],
            typeDatas: [
                { code: 10, name: '5 Razones para elegir nuestra franquicia' },
                { code: 14, name: 'CONTRATACIONES' },
                { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
                { code: 3, name: 'DATOS OPERATIVOS' },
                { code: 11, name: 'EXPERIENCIA' },
                { code: 4, name: 'DATOS ECONOMICOS' },
                { code: 13, name: 'INFORMACIÓN GENERAL' },
                { code: 9, name: 'Sucursales' },
                { code: 1, name: 'OBJETIVO' },
                { code: 5, name: 'OTROS DATOS IMPORTANTES' },
                { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
                { code: 6, name: 'Información de la Empresa para Facturación' },
                { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
                { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
                { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
            ],
          }
      },
      mounted() {
        this.fetchTotalInvestment(this.idCompany);
      },
      computed: {
			groupedData() {
				const result = {};
				this.companyDataValue.forEach(item => {
					if (!result[item.type]) {
					result[item.type] = [];
					}
					result[item.type].push(item);
				});
				return result;
			},
	  },
      methods: {
        async fetchTotalInvestment(companyId) {
            try {
                this.show = true
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY+'companies/'+companyId, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer '+process.env.VUE_APP_TOKEN,
                    },
                });

                if (response && response.data && response.data.data) {

                    // Itera sobre los datos de la respuesta para obtener inversionInicial e inversionFinal
                    response.data.data.forEach(item => {
                        if (item.id === 8) {
                            this.inversionInicial = item.company_data.value;
                        } else if (item.id === 9) {
                            this.inversionFinal = item.company_data.value;
                        }
                    });

                    const data = response.data;
                    this.fetchCompanyData();
                    response.data.data.forEach(item => {
                            let description = item.company_data.value;
                            let value = item.id;

                            // Agregar la descripción y el valor al arreglo companyDataValue
                            if(item.company_data.data_id != null){
                                //this.companyDataValue.push(null);
                                this.companyDataValueArray[value] = description;
                            }
                            
                        });
                        this.show = false

                } else {
                    console.error("Error en la API");
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching total investment for company ${companyId}:`, error);
                return null;
            }
        },
        fetchCompanyData() {
			axios.get(process.env.VUE_APP_API_DIRECTORY + 'data?order=created_at&by=asc&limit=30&company_type=1', {
				headers: {
				"Accept": "application/json",
				"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
				}
			})
			.then(response => {
				this.companyDataValue = response.data;
				
			})
			.catch(error => {
				console.error('Error al obtener datos de la compañía:', error);
			});
		},
        formatMoney(amount) {
            amount = parseInt(amount)
            return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        getBlockTitle(type) {
			// Buscar el nombre del bloque en base al código del tipo
			const typeData = this.typeDatas.find(data => data.code === parseInt(type));
			return typeData ? typeData.name : `Contact Information ${type}`;
		}
      }

  }
</script>

<style>
.price-card:hover {
    background: #3c4549;
    transition: all 0.5s ease-in-out;
    -webkit-transition: ease all 0.5s;
    color: #fff;
}
</style>