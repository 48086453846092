<template>

    <b-modal id="modal-1" v-model="modal" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
    </b-modal>
    
    <mainsidebar/>
    <div class="main-wrapper" style="background: #f9fafc;">
    
        <div class="page-wrapper" style="background: #f9fafc;">
    
            <!-- Dashboard Content -->
            <div class="dashboard-content">
                <div class="container">
                    <dashboardmenu />
    
    
                    <div class="dash-listingcontent dashboard-info">
                        <div class="dash-cards card">
                            <div class="card-header">
                                <h4>Datos de la Categoría</h4>
                                <b-button variant="outline-primary" v-b-toggle.accordion-2 size="sm" @click="createData">
                                    Nueva Categoría
                                </b-button>
                            </div>
    
                            <div class="card-body">
                                <div class="accordion" role="tablist">
                                    <b-card no-body class="mb-4">
                                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" v-model="modal">
                                            <b-card-body>
                                                <div class="card-header">
                                                    <h5 v-if="recordId == 0">Crear Categoría</h5>
                                                    <h5 v-else>Editar Categoría </h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input">
                                                                <label class="col-form-label">Nombre</label>
                                                                <input 
                                                                    type="text" 
                                                                    class="form-control pass-input" 
                                                                    placeholder="Escriba el nombre de la categoría" 
                                                                    v-model="editData.name">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input formlast-input-inner">
                                                                <label class="col-form-label">Tipo de Empresa</label>
                                                                <b-form-select 
                                                                    :options="types"
                                                                    value-field="code"
                                                                    text-field="name"
                                                                    v-model="editData.type"
                                                                ></b-form-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input">
                                                                <label class="col-form-label">Icono</label>
                                                                <input 
                                                                    type="text" 
                                                                    class="form-control" 
                                                                    placeholder="Escriba el codigo del icono"
                                                                    v-model="editData.icon"
                                                                >		
                                                            </div>
                                                        </div>
                            
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input formlast-input-inner">
                                                                <br><br>
                                                                <a 
                                                                    href="https://fontawesome.com/icons/coins?f=classic&s=solid"
                                                                    target="_blank"
                                                                >Galeria de Iconos</a>
                                                            </div>
                                                        </div>										
                                                    </div>
                                                    <div 
                                                        class="row"
                                                        v-if="recordId != 0"
                                                    >
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="gallery-media">
                                                                <b-overlay :show="showCover" rounded="sm">
                                                                <h6 class="media-title">Portada</h6>
                                                                <div class="galleryimg-upload">
                                                                    <!-- Renderizar las imágenes de la galería -->
                                                                    <div 
                                                                        class="gallery-upload"
                                                                        v-if="editData.image"
                                                                    >
                                                                        <div
                                                                            v-if="editData.image.type == 'image'"
                                                                        >
                                                                            <img 
                                                                                :src="editData.image.route" 
                                                                                class="img-fluid"
                                                                                style="max-height: 200px;" 
                                                                                alt=""
                                                                            >
                                                                            <!-- Añadir un botón para eliminar la imagen -->
                                                                            <a 
                                                                                href="javascript:void(0)" 
                                                                                class="profile-img-del" 
                                                                                @click="deleteGalleryImage(editData.image.id)">
                                                                                <i class="feather-trash-2"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </b-overlay>
                                                                <!-- Añadir un botón para subir imágenes -->
                                                                <div class="settings-upload-btn" v-if="!editData.image">
                                                                    <input type="file"  @change="uploadGalleryImage($event, 'image')" accept="image/*" name="image" class="hide-input image-upload" id="file2">
                                                                    <label for="file2" class="file-upload">Subir Imagen de Portada</label>
                                                                </div>
                                                            </div>
                                                        </div>									
                                                    </div>
                                                </div>
                                                <b-button variant="primary" type="submit" @click="handleSubmit">Guardar</b-button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
    
                                <div class="listing-search">
                                    <div class="filter-content form-group">
                                        <div class="group-img">
                                            <input type="text" class="form-control" placeholder="Search...">
                                            <i class="feather-search"></i>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="table-responsive">
                                    <a-table 
                                        class="stripped table-hover listing-table datatable" 
                                        :columns="columns" 
                                        :data-source="categories"
                                    >
                                            <template #bodyCell="{ column, record }">
                                            <template v-if="column.key === 'type'">
                                                <span 
                                                
                                                >
                                                    {{ getTypeName(record.type) }}
                                                </span>
                                            </template>
                                            <template v-else-if="column.key === 'action'">
                                                <div class="action">
                                                    <a class="action-btn btn-edit" @click="openEditModal(record.id)">
                                                        <i class="feather-edit-3"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" class="action-btn btn-trash" @click="deleteData(record.id)">
                                                        <i class="feather-trash-2"></i>
                                                    </a>
                                                </div>
                                            </template>
                                        </template>
                                    </a-table>
                                </div>
                                <!--Pagination-->
						<div class="blog-pagination" v-if="total != 0">
							<nav>
							<ul class="pagination">
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('prev') }"
								>
									<a @click="nextPage('left')" href="#"  class="page-link"  aria-label="Previous">
										<i class="fas fa-regular fa-arrow-left"></i>
									</a>
								</li>
								<li class="justify-content-center pagination-center">
									<div class="pagelink">
										<ul>
											<li 
                                                class="page-item"
                                                v-for="(column, index) in pages" 
                                                :key="index" 
                                            >
												<a 
                                                    :class="{ 'page-link': true, 'active': page === column }"
                                                    @click="nextPage(column)"
                                                    href="#"
                                                >{{ column }}</a>
											</li>
										</ul>
									</div>
								</li>
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('next') }"
								>
                                    <a 
                                        class="page-link" 
                                        @click="nextPage('right')"
                                        href="#"
                                    >
                                        <i class="fas fa-regular fa-arrow-right"></i>
                                    </a>
                                </li>
							</ul>
						</nav>
						</div>
						<!--/Pagination-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <scroll />
    </div>
    </template>
    
    <script>
      import Dashboard from '../../../assets/json/dashboard.json'
  import DashboardReview from '../../../assets/json/dashboardReview.json'
    import axios from 'axios';
    
    export default {
        data() {
            return {
                columns: [
                    {
                        title: "Nombre",
                        dataIndex: "name",
                        key: "name",
                        sorter: true,
                    },
                    {
                        title: "Tipo de Categoria",
                        dataIndex: "type",
                        key: "type",
                        sorter: true,
                    },
                    {
                        title: "Action",
                        key: "action",
                        class: "text-end",
                        sorter: true,
                    },
                ],
                types: [
                    { code: '0', name: 'Empresas' },
                    { code: '1', name: 'Blog' },
                    { code: '2', name: 'Eventos' },
                    { code: '3', name: 'Noticias' },
                    { code: '4', name: 'Glosario de Términos' },
                ],
                categories: [],
                currentPage: 1, // Página actual
                lastPage: 0,    // Última página
                links: [],  
                editData: {
                    name: '',
                    type: '',
                    icon: '',
                    image: {},
                },
                Dashboard: Dashboard,
                DashboardReview: DashboardReview,
                modal: false,
                showCover: false,
                recordId: 0,
                // variables para la paginacion
                page: 1,
                pages: 1,
                totalItems: 0,
                itemsPerPage: 10,
                loadingText: ''
            }
        },
        mounted() {
            this.fetchCategories();
        },
        computed: {
            isPageButtonDisabled() {
                return direction => {
                    if (direction === 'prev') {
                    return this.page === 1;
                    } else if (direction === 'next') {
                    return this.page === this.pages;
                    }
                };
            }
        },
        methods: {
            async fetchCategories() {
                try {
                    const response = await axios.get(
                            process.env.VUE_APP_API_DIRECTORY + 
                            "categories?order=created_at&by=asc&page=" +
                            this.page, 
                        {
                            headers: {
                                "Accept": "application/json",
                                "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    this.categories = response.data.data; 

                    //datos para la paginacion
                    if (this.companies.length < 1) {
                        this.loadingText = 'No hay registros'
                    }
                    this.totalItems = response.data.total
                    this.currentPage = response.data.current_page;
                    this.lastPage = response.data.last_page;
                    this.links = response.data.links;
                    //datos para la paginacion
                    // Actualizar datos de paginación
                    //this.page = response.data.current_page;
                    this.pages = response.data.last_page;
                    this.total = response.data.total;
                    this.page = currentPage;
                    this.show = false;

                } catch (error) {
                    console.error('Error fetching categories:', error);
                }
            },

            async fetchPageData(url) {
                try {
                    const response = await axios.get(url, {
                        headers: {
                            "Accept": "application/json",
                            "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                        }
                    });
                    this.categories = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.lastPage = response.data.last_page;
                    this.links = response.data.links;
                } catch (error) {
                    console.error('Error fetching categories:', error);
                }
            },
    
            openEditModal(cordId) {
                const record = this.categories.find(category => category.id === cordId);
                
                this.recordId = cordId;
                this.editData.name = record.name;
                this.editData.type = record.type;
                this.editData.icon = record.icon;
                this.editData.image = record.image;
                this.modal = true;
            },

            nextPage(orientation) {
                if (orientation === 'right' && this.page < this.pages) {
                    this.page++;
                } else if (orientation === 'left' && this.page > 1) {
                    this.page--;
                } else {
                    this.page = orientation;
                }
                this.fetchCategories();
            },
    
            createData() {
                this.editData.name = '';
                this.editData.icon = '';
                this.editData.image = '';
                this.editData.type = 0;
                this.recordId = 0;
            },

            getTypeName(typeCode) {
                const typeData = this.types.find(type => type.code === typeCode);
                return typeData ? typeData.name : '';
            },

            uploadGalleryImage(event, type) {
                if(type == 'image'){
                    this.showCover = true;
                }
                
                const formData = new FormData();
                formData.append('_method', 'patch');
                formData.append('fileType', type);
                formData.append('file', event.target.files[0]); 

                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                };

                axios.post(process.env.VUE_APP_API_DIRECTORY + 'categories/' + this.recordId, formData, { headers })
                    .then(response => {
                        this.editData.image = response.data.category.image
                        this.fetchCategories();
                        console.log('Imagen subida exitosamente:', response.data);
                        this.showCover = false;
                    })
                    .catch(error => {
                        console.error('Error al subir la imagen:', error);
                        this.showCover = false;
                    });
            },


            	// Método para eliminar archivos de la galería
            deleteGalleryImage(imageId) {
                axios.delete(process.env.VUE_APP_API_DIRECTORY + 'upload-management/delete-upload/' + imageId, {
                    headers: {
                        "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                    }
                })
                .then(response => {
                    console.log('Imagen de Portada eliminada exitosamente:', response.data);
                    this.editData.image = {}
                    this.fetchCategories()
                })
                .catch(error => {
                    console.error('Error al eliminar la portada de la categoria:', error);
                });
            },


            async deleteData(corId) {

                this.$swal
                    .fire({
                        title: "¿Estás seguro?",
                        text: "¡No podrás revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminarlo",
                    })
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                const response = await axios.delete(
                                    `${process.env.VUE_APP_API_DIRECTORY}categories/${corId}`,
                                    {
                                        headers: {
                                            Accept: "application/json",
                                            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                                        },
                                    }
                                );

                                // Si la eliminación es exitosa, muestra el mensaje de éxito
                                this.$swal.fire({
                                    title: "¡Eliminado!",
                                    text: "Esta categoria ha sido eliminada.",
                                    icon: "success",
                                });

                                // Recarga la lista de compañías después de la eliminación
                                this.fetchCategories();
                            } catch (error) {
                                // Si hay un error, muestra el mensaje de error
                                this.$swal.fire({
                                    title: "Error al eliminar",
                                    text: "Hubo un error al intentar eliminar la categoria.",
                                    icon: "error",
                                });

                                console.error("Error deleting data:", error);
                            }
                        }
                    });
            },
    
            async handleSubmit() {
                try {
                    if (this.recordId == 0) {
                        const response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "categories", this.editData, {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    } else {
                        const response = await axios.patch(process.env.VUE_APP_API_DIRECTORY + "categories/" + this.recordId, this.editData, {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    }
                    this.modal = false;
                    this.fetchCategories();
                } catch (error) {
                    console.error('Error submitting data:', error);
                }
            },
    
        }
    }
    </script>
    