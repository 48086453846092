<template>
    <Carousel 
        :autoplay="3000" 
        :loop="true" 
        :wrap-around="true" 
        :settings="settings" 
        :breakpoints="breakpoints"
        class="mt-4"
    >
        <Slide 
            class="partnerslist d-flex" 
            v-for="item in companies" 
            :key="item"
        >	
            <li class="carousel__item item">
                <a 
                    :href="'/franquicia/'+item.link"
                >
                    <img 
                        class="img-fluid" 
                        style="max-width: 110px;"
                        v-if="item.logo"
                        :src="item.logo.route" 
                        alt="partners"
                    >
                </a>
            </li>
        </Slide> 
        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>

<script>
import axios from 'axios'; // Importar Axios
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
      components: {
            Carousel,
            Slide,
            Navigation
      },
      data() {
          return {
            companies: [],
            settings: {
				itemsToShow: 1,
				snapAlign: "center"
			},
			breakpoints: {
				575: {
					itemsToShow: 3,
					snapAlign: "center"
				},
                // 700px and up
                700: {
                    itemsToShow: 3,
                    snapAlign: "center"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 6,
                    snapAlign: "start"
                }
            },
          }
      },
      mounted() {
        this.getCompanies();
      },
      methods: {
        async getCompanies() 
        {
            try {
                const response = 
                await axios.get(
                    process.env.VUE_APP_API_DIRECTORY + 
                    "companies-list?limit=20&random=1&order=updated_at&by=asc&outstanding=1&active=1"
                );
                this.companies = response.data;
                this.show = false;


            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        },
      }

  }
</script>