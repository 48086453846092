<template>
    <div class="main-wrapper">
        <navbar></navbar>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
            
            <dashboardcontent />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Mi Cuenta",
            text: "Bienvenida",
            text1: "Dashboard",
            name: "/",
            cover: "dashboard.jpg"
        }
    },
    name: 'dashboard',
}
</script>