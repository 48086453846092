<template>
        <div class="recommended-carousel">
                    <div class="row">
						<div class="col-lg-3 col-md-6 aos" data-aos="fade-up" v-for="item in IndexSpace" :key="item.id">
							<div class="space-wrap">
								<div class="space-img">
									<router-link to="/service-details">
										<img :src="require(`../../../assets/img/work/${item.img1}`)" class="img-fluid" alt="blog-img">
									</router-link>
									<div class="fav-item">
										<span class="featured-text">Featured</span>
									</div>	
								</div>
								<div class="space-content">
									<div class="space-cat">
										<img :src="require(`../../../assets/img/logos/${item.img2}`)" class="img-fluid" alt="img">
									</div>
									<h5><router-link to="/service-details">{{ item.title }}</router-link></h5>
									<ul>
										<li class="addr"><i class="feather-map-pin"></i> {{ item.city }}</li>
										<li><a href="javascript:void(0);" class="fav-icon"><i class="fa-solid fa-heart"></i></a></li>
									</ul>
									<div class="price">
										<img 
                                            :src="require(`../../../assets/img/profiles/investment.png`)" 
                                            class="img-fluid" 
                                            alt="img"
                                        >
										<span>
                                            {{ item.rate }}
                                        </span>
									</div> 
								</div> 
							</div> 
						</div>	 
					</div>	
        </div>
    
</template>

<script>

import RecommendSlide from '../../../assets/json/recommendSlide.json'
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexSpace from '../../../assets/json/indexSpace.json'
import IndexCity from '../../../assets/json/indexCity.json'

export default {
    data() {
        return {
            RecommendSlide: RecommendSlide,
            settings: {
                itemsToShow: 1,
                snapAlign: "center"
            },
            IndexSpace: IndexSpace,
			IndexCity: IndexCity,
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 991 and up
                991: {
                    itemsToShow: 3,
                    snapAlign: "center"
                },
                // 1024 and up
                1000: {
                    itemsToShow: 4.25,
                    snapAlign: "center"
                }
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    mounted() {
			$('.fav-icon').on('click', function () {
			$(this).toggleClass('selected');
		});
		}
}
</script>

<style>
.carousel__slide.recommended-widget {
	margin-right: 22px;
}
</style>