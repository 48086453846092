<template>

	<sidebar-menu 
      :menu="menu"  
      collapsed
    /> 

</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

export default {
      components: 
      {
          SidebarMenu
      },
      data() {
          return {
        	menu: [
                {
                header: 'Franquiciar',
                hiddenOnCollapse: true
                },
                {
                href: '/',
                title: 'Inicio',
                icon: 'fa fa-home'
                },
                {
                title: 'Contenido web',
                icon: 'fa fa-chart-area',
                  child: [
                    {
                    href: '/admin-franchise-list',
                    title: 'Franquicias'
                    },
                    {
                    href: '/admin-category-list',
                    title: 'Rubros'
                    },
                    {
                    href: '/admin-country-list',
                    title: 'Países'
                    },
                    {
                    href: '/admin-blog-list',
                    title: 'Noticias'
                    },
                    {
                    href: '/admin-data-list',
                    title: 'Datos'
                    },
                    {
                    href: '/admin-consulting-list',
                    title: 'Consultoras'
                    },
                  ],
                },
                {
                href: '/admin-users-list',
                title: 'Usuarios',
                icon: 'fa fa-users',
                  child: [
                    {
                    href: '/admin-user-list',
                    title: 'Inversiones'
                    },
                    {
                    href: '/admin-manager-list',
                    title: 'Administradores'
                    }
                  ],
                },
                {
                href: '/login',
                title: 'Cerrar Sesion',
                icon: 'fa fa-right-from-bracket'
                },
            ]
          }
      },

  }
</script>