<template>
    <!-- FAQ Section -->
		<section class="faq-section">
			<div class="container">
				<div class="faq-info">
					<div class="page-title ">
					   <h2 class="mt-0">Payments</h2>
					</div>
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqOne" aria-expanded="false">Why won’t my payment go through?</router-link>
						</h4>
						<div id="faqOne" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam placerat bibendum ligula ac facilisis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris consectetur, metus vel tincidunt scelerisque, quam diam faucibus est, ut viverra lectus odio non ante. Donec enim nisi, porttitor eu odio ac, sodales consequat lectus. Nunc pulvinar posuere metus ac laoreet. Nunc lacinia diam ut pulvinar porttitor. Cras quis ultrices tellus, id posuere turpis. Integer eget diam et metus dignissim pharetra sed ut mi. Integer volutpat odio sed odio feugiat finibus. Pellentesque quis purus vitae dolor elementum elementum eu ac ante. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam at neque a lorem auctor sagittis</p>
							<p class="border border-0">Mauris vestibulum lorem a condimentum vulputate. Integer vitae turpis turpis. Cras at tincidunt urna. Aenean leo justo, congue et felis a, elementum rutrum felis. Fusce rutrum ipsum eu pretium faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer dolor odio, iaculis nec tincidunt vel, dapibus a nisl. In feugiat maximus eros, id sollicitudin quam sagittis nec.</p>
						</div>
					</div>	
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqTwo" aria-expanded="false">How to I get a refund?</router-link>
						</h4>
						<div id="faqTwo" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqThree" aria-expanded="false">How do I redeem a coupon?</router-link>
						</h4>
						<div id="faqThree" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>	
					<div class="faq-card mb-0">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqFour" aria-expanded="false">How do I change Account Profile Name</router-link>
						</h4>
						<div id="faqFour" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>	
					<div class="page-title">
					   <h2>Suggestions</h2>
					</div>
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqFive" aria-expanded="false">Why won’t my payment go through?</router-link>
						</h4>
						<div id="faqFive" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>	
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqSix" aria-expanded="false">How to I get a refund?</router-link>
						</h4>
						<div id="faqSix" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>	
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqSeven" aria-expanded="false">How do I redeem a coupon?</router-link>
						</h4>
						<div id="faqSeven" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>	
					<div class="faq-card">
						<h4 class="faq-title">
							<router-link class="collapsed" data-bs-toggle="collapse" to="#faqEight" aria-expanded="false">How do I change Account Profile Name</router-link>
						</h4>
						<div id="faqEight" class="card-collapse collapse">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
						</div>
					</div>													
				</div>		
		    </div>	
		</section>	
		<!-- /FAQ Section -->
</template>