<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />

            <bookmarkcontent />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Mis Favoritos",
            text: "Bienvenido",
            text1: "Bookmarks",
            name: "/",
            cover: "fav.jpg",
        }
    },
}
</script>