<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />

            <forgotpassword  />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "¿Olvido su contraseña?",
            text: "Bienvenido",
            cover: "forgot.jpg",
            text1: "Forgot Password",
            name: "/",
        }
    },
}
</script>