<template>
    <mainsidebar/> 
      <div 
        class="main-wrapper"
        style="    background: #f9fafc;"  
      >
      
          
  
          <div class="page-wrapper"
            style="    background: #f9fafc;"
          >
  
              
                  <!-- Dashboard Content -->
                  <div class="dashboard-content">
		<div class="container">
			<dashboardmenu />
			<div class="dash-listingcontent dashboard-info">
				<div class="dash-cards card">
					<div class="card-header">
						<h4>Gestor de Fichas de Franquicias</h4>
						<router-link 
                            class="nav-link header-login add-listing" 
                            to="/admin-franchise-edit/0"
                        >
                            <i class="fa-solid fa-plus"></i> Nueva Ficha
                        </router-link>
					</div>
					<div class="card-body">
						<div class="listing-search">
							<div class="filter-content form-group">
								<div class="group-img">
									<input type="text" class="form-control" placeholder="Buscar...">
									    
								</div>
							</div>
							<div class="sorting-div">
								<div class="sortbyset">
									<span class="sortbytitle">Ordenar por</span>
									<div class="sorting-select">
										<vue-select :options="Newest" placeholder="Newest" />
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<a-table
                                class="stripped table-hover listing-table datatable"
                                :columns="columns"
                                :data-source="companies" 
     
                            >
                    <template #bodyCell="{ column, record }">
                      <template
                        v-if="column.key === 'logo'"
                      >
                        <div 
                            class="listingtable-img"
                        >
                                <router-link 
                                    :to="'admin-franchise-edit/'+record.id" 
                                    class="img-thumbnail"
                                >
                                    <img 
                                        class="img-fluid avatar-img"
                                        v-if="record.logo" 
                                        style="max-width: 150px; max-height: 110px;"
                                        :src="record.logo.route" 
                                        alt=""
                                    >
                                </router-link>
                         </div>
                      </template>
                      <template v-else-if="column.key === 'name'">
						<p v-if="record.outstanding == true">
                            <b-badge variant="warning">Ficha Destacada</b-badge>
                        </p>
                        <h6>
							<router-link 
                                :to="'franquicia/'+record.link" 
                            >
                                {{record.name}}
                            </router-link>
						</h6>
						<div class="listingtable-rate">
                            <div 
                                class="blog-features"
                                v-if="record.categories"
                            >
                                <a 
                                    class="cat-icon" 
                                    v-for="(column, index) in record.categories" 
                                    :key="index" 
                                    aria-current="true" 
                                    data-bs-toggle="tab" 
                                >
                                    <span>
                                        <i
                                            :class="column.icon" 
                                            alt="Dreamstrip Recommended"
                                        ></i>
                                    </span>
                                    {{ column.name }}
                                </a>
                            </div>
                            <span><small style="font-weight: 600;">Inversion total </small><br/></span>
                            <range-price 
                                :idCompany="record.id"
                            ></range-price>

						</div>
						
                      </template>
                      <template 
                        v-else-if="column.key === 'active'"
                      >
                        <span 
                        v-if="record.active == true" 
                        class="status-text">Activo</span>

                        <span 
                        v-else 
                        style="color:red;"
                        class="status-text">Inactivo</span>

                      </template>
					  <template v-else-if="column.key === 'views'">
                        <span class="views-count">{{record.views}}</span>
                      </template>
                      <template v-else-if="column.key === 'action'">
                        <div class="action">
							<router-link 
                                target= '_blank'
                                :to="'franquicia/'+record.link" 
                                class="action-btn btn-view"
                            >
                                <i class="feather-eye"></i>
                            </router-link>
                            <router-link 
                                :to="'admin-franchise-edit/'+record.id" 
                                class="action-btn btn-edit"
                            >
                                <i class="feather-edit-3"></i>
                            </router-link>
							<a 
                                href="javascript:void(0)" 
                                class="action-btn btn-trash"
                                @click="deleteCompany(record.id)"
                            >
                                <i class="feather-trash-2"></i>
                            </a>
						</div>
                      </template>
                    </template>
                  </a-table>
					</div>
					<div class="blog-pagination">
						<nav>
							<ul class="pagination">
								<li 
                                    class="page-item nextlink"
                                    v-if="page != 1"
                                >
                                    <a 
                                        class="page-link" 
                                        @click="nextPage('left')"
                                        href="#"
                                    >
                                        <i class="fas fa-regular fa-arrow-left"></i>
                                    </a>
                                </li>
								<li class="justify-content-center pagination-center">
									<div class="pagelink">
										<ul>
											<li 
                                                class="page-item"
                                                v-for="(column, index) in pages" 
                                                :key="index" 
                                            >
												<a 
                                                    :class="{ 'page-link': true, 'active': page === column }"
                                                    @click="nextPage(column)"
                                                    href="#"
                                                >{{ column }}</a>
											</li>
										</ul>
									</div>
								</li>
								<li 
                                    class="page-item nextlink"
                                    v-if="page != pages"
                                >
                                    <a 
                                        class="page-link" 
                                        @click="nextPage('right')"
                                        href="#"
                                    >
                                        <i class="fas fa-regular fa-arrow-right"></i>
                                    </a>
                                </li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
  
          
  
              <scroll />
          </div>
      </div>
  </template>
  
  
  <script>

  import Dashboard from '../../../assets/json/dashboard.json'
  import DashboardReview from '../../../assets/json/dashboardReview.json'
  import axios from 'axios'; // Importar Axios
  
  export default {
      data() {
          return {
            columns : [
                {
                    title: "Logo",
                    dataIndex: "logo",
                    key: "logo",
                    sorter: {
                    compare: (a, b) => {
                        a = a.logo.toLowerCase();
                        b = b.logo.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Detalles",
                    dataIndex: "name",
                    key: "name",
                    sorter: {
                    compare: (a, b) => {
                        a = a.name.toLowerCase();
                        b = b.name.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Estatus",
                    dataIndex: "active",
                    key: "active",
                    sorter: {
                    compare: (a, b) => {
                        a = a.active.toLowerCase();
                        b = b.active.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Visitas",
                    dataIndex: "user_id",
                    key: "user_id",
                    sorter: {
                    compare: (a, b) => {
                        a = a.user_id.toLowerCase();
                        b = b.user_id.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Acciones",
                    key: "action",
                    class: "text-end",
                    sorter: true,
                },
            ],
            Dashboard: Dashboard,
            DashboardReview: DashboardReview,
            companies: [],
            title: "Login",
            text: "Inicio",
            text1: "Login",
            name: "/",
            // variables para la paginacion
            page: 1,
            pages: 1,
            totalItems: 0,
            itemsPerPage: 10,
            loadingText: ''
          }
      },
      mounted() {
            // Llamar a la API para obtener la lista de compañías
            this.fetchCompanies();
        },
      methods: {
        async fetchCompanies() {
        try {
            const response = await axios.get( 
                process.env.VUE_APP_API_DIRECTORY + 
                "companies-list?order=created_at&by=asc&page=" +
                this.page , 
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                }
            });

            // Almacenar la lista de compañías en la propiedad 'companies'
            this.companies = response.data.data;

            //datos para la paginacion
            if (this.companies.length < 1) {
                this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
                this.page = response.data.current_page
                this.pages = response.data.last_page
            } else {
                this.page = 1
                this.pages = 1
            }

        } catch (error) {
            console.error('Error fetching companies:', error);
        }
        },

        nextPage(orientation) {
            if(orientation == 'right'){
                this.page = this.page + 1;
            }else if(orientation == 'left'){
                this.page = this.page - 1;
            }else{
                this.page = orientation
            }
            this.fetchCompanies();
        },

        deleteCompany(corId) {
            this.$swal
                .fire({
                    title: "¿Estás seguro?",
                    text: "¡No podrás revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sí, eliminarlo",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const response = await axios.delete(
                                `${process.env.VUE_APP_API_DIRECTORY}companies/${corId}`,
                                {
                                    headers: {
                                        Accept: "application/json",
                                        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                                    },
                                }
                            );

                            // Si la eliminación es exitosa, muestra el mensaje de éxito
                            this.$swal.fire({
                                title: "¡Eliminado!",
                                text: "La ficha ha sido eliminada.",
                                icon: "success",
                            });

                            // Recarga la lista de compañías después de la eliminación
                            this.fetchCompanies();
                        } catch (error) {
                            // Si hay un error, muestra el mensaje de error
                            this.$swal.fire({
                                title: "Error al eliminar",
                                text: "Hubo un error al intentar eliminar el archivo.",
                                icon: "error",
                            });

                            console.error("Error deleting data:", error);
                        }
                    }
                });
            },
    }
  }
  </script>