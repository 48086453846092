<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <blogcontent />

            <foot />

            <scroll />
        </div>
    </div>
</template>
