<template>
    <!-- Home Banner -->
		<section class="banner-section banner-three">
			<div class="container">
				<div class="row">
					<div class="banner-info-blk">
						<div class="row">
							<div class="col-md-12 col-lg-5">
								<br><br>
								<div class="banner-content-blk">
									<h1 style="font-size: 30px; font-weight: 400;">ENCUENTRA 
									<br><strong>LA FRANQUICIA IDEAL</strong>
									<br>PARA TI</h1>
									<br><br>
									<h5>
										<span class="typed-text">{{ typeValue }}</span>
										<span class="blinking-cursor">|</span>
										<span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
									</h5>
									<br><br>
								</div>
								<br><br>
							</div>
						</div>
					</div>
					<div class="banner-images">
						<div class="ban-image">
							<!-- <img src="../../../assets/img/bg/balloon-img.png" alt=""> -->
						</div>
						<div class="ban-image-01">
							<!-- <img src="../../../assets/img/bg/cloud-01.png" alt="" class="cloud-one">
							<img src="../../../assets/img/bg/cloud-02.png" alt="" class="cloud-two"> -->
						</div>
						<div class="ban-image-02">
							<!-- <img src="../../../assets/img/banner-02.png" alt=""> -->
						</div>
						<div class="ban-image-03">
							<!-- <img src="../../../assets/img/bg/plane.png" alt=""> -->
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="banner-btm-img-blk">
							<!-- <img src="../../../assets/img/banner-btm-img.png" alt=""> -->
						</div>

						<search-home />

						<carosuel-logos />

					</div>
				</div>
			</div>
		</section>
		<!-- /Home Banner -->	
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "typeWiriter",
  components: {
    Carousel,
    Slide,
	Navigation
  },
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      displayTextArray: ["Encuentra", "Compara", "Invierte"],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
    };
  },
  props: {},
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
  methods: {
    typeText() {
      if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.displayTextArrayIndex += 1;
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
};
</script>
<style  >

.v-text-field.v-text-field--solo .v-input__control{
    min-height: 10px;
}

.v-label{
  font-size: 10px;
}

.blinking-cursor {
  font-size: 2rem;
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

</style>
