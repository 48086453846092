<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb 
                :title="title" 
                :name="name" 
                :text="text" 
                :text1="text1" 
            />

            <activateaccount  />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Activa tu cuenta",
            text: "Bienvenido",
            text1: "Forgot Password",
            name: "/",
        }
    },
}
</script>