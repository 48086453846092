<template>
    <div class="main-wrapper">
        <navbar></navbar>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
            
            <profiledashboard /> 

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "",
            text: "Bienvenido",
            text1: "Profile",
            name: "/",
            cover: "profile.jpeg"
        }
    },
    mounted() {
        this.title = localStorage.getItem('userName')
    }
}
</script>