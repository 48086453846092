<template>
	<!-- Dashboard Content -->
	<div class="dashboard-content">
		<div class="container">
			<div class="">
				<ul class="dashborad-menus">
					<li class="active">
						<router-link 
							to="/dashboard"
						>
							<i class="feather-grid"></i> <span>Mi Cuenta</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/profile"
						>
							<i class="fa-solid fa-user"></i> <span>Perfil</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/my-listing"
						>
							<i class="feather-list"></i> <span>Mis Fichas</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/bookmarks"
						>
							<i class="fas fa-solid fa-heart"></i> <span>Mis Favoritos</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/login"
						>
						<i class="fas fa-sign-out-alt"></i>
							<span>Cerrar Sesión</span>
						</router-link>
					</li>
				</ul>
			</div>
			<div class="dashboard-details">

				<!-- dashboard-info -->
				<div class="row dashboard-info">
					<div class="col-lg-6 d-flex">
						<div class="card dash-cards w-100">
							<div class="card-header">
								<h4>Tutorial</h4>
							</div>
							<div class="card-body">
								<video 
									width="100%" 
									height="390" 
									controls
								>
									<source 
										src="https://franquiciar.com/image/video-tutorial.mp4" 
										type="video/mp4"
									>
									Your browser does not support the video tag.
								</video>
							</div>
						</div>
					</div>
					<div class="col-lg-6 d-flex">
						<div class="card dash-cards w-100">
							<div class="card-header">
								<h4>Favoritos</h4>
							</div>
							<div class="card-body" style="max-height: 400px; overflow-y: auto;">
								<ul class="review-list" v-for="item in Bookmarks" :key="item.id">
									<li class="review-box">
										
										<div class="review-profile">
											<div class="review-img" style="background-color: white;">
												<router-link 
											:to="'/franquicia/'+item.rateable.link" 
										>
												<img 
													:src="item.rateable.logo.route" 
													class="img-fluid" 
													alt="author"
												></router-link>
											</div>
										</div>
										<div class="review-details">
											<router-link 
											:to="'/franquicia/'+item.rateable.link" 
										>
											<h6>{{ item.rateable.name }}</h6></router-link>
											<div class="rating">
												<div class="rating-star">
													<i class="fas fa-heart" style="color: #ff4545;"></i>
												</div>
												<div><i class="fa-sharp fa-solid fa-calendar-days"></i> {{ tiempoTranscurrido(item.created_at) }} </div>
												<div>{{ item.test }}</div>
											</div>
										</div>
										
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- /dashboard-info -->
			</div>
		</div>
	</div>
	<!-- /Dashboard Content -->
</template>

<script>
import axios from 'axios'; // Importar axios

export default {
    data() {
        return {
            Bookmarks: [],
			show: true
        }
    },
    mounted() {
        // Llamar a la función para cargar los marcadores
        this.loadBookmarks();
    },
    methods: {
        loadBookmarks() {
			
            // Configuración de la solicitud
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("tokenSession")
                }
            };

            // Realizar la solicitud HTTP
            axios.get(process.env.VUE_APP_API_DIRECTORY + "rating-management/get-user-list?userId=" + localStorage.getItem("userId"), config)
                .then(response => {
                    // Asignar los datos de la respuesta al array Bookmarks
                    this.Bookmarks = response.data.data;
					this.show = false
                })
                .catch(error => {
                    console.error('Error al cargar los marcadores:', error);
                });
        },
		tiempoTranscurrido(fecha) {
			const fechaActual = new Date();
			const fechaOcurrencia = new Date(fecha);

			// Calcula la diferencia en milisegundos
			const diferencia = fechaActual.getTime() - fechaOcurrencia.getTime();

			// Convierte la diferencia de milisegundos a segundos, minutos, horas, días, meses y años
			const segundosTranscurridos = Math.floor(diferencia / 1000);
			const minutosTranscurridos = Math.floor(segundosTranscurridos / 60);
			const horasTranscurridas = Math.floor(minutosTranscurridos / 60);
			const diasTranscurridos = Math.floor(horasTranscurridas / 24);
			const mesesTranscurridos = Math.floor(diasTranscurridos / 30);
			const añosTranscurridos = Math.floor(mesesTranscurridos / 12);

			if (añosTranscurridos > 0) {
				if (añosTranscurridos === 1) {
					return 'Hace un año';
				} else {
					return `Hace ${añosTranscurridos} años`;
				}
			} else if (mesesTranscurridos > 0) {
				if (mesesTranscurridos === 1) {
					return 'Hace un mes';
				} else {
					return `Hace ${mesesTranscurridos} meses`;
				}
			} else if (diasTranscurridos > 0) {
				if (diasTranscurridos === 1) {
					return 'Ayer';
				} else {
					return `Hace ${diasTranscurridos} días`;
				}
			} else if (horasTranscurridas > 0) {
				return `Hace ${horasTranscurridas} horas`;
			} else if (minutosTranscurridos > 0) {
				return `Hace ${minutosTranscurridos} minutos`;
			} else {
				return `Hace ${segundosTranscurridos} segundos`;
			}
		}



		}
}
</script>