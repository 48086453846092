<template>
    <mainsidebar/> 
    <div 
      class="main-wrapper"
      style="    background: #f9fafc;"  
    >
        <div class="page-wrapper"
          style="    background: #f9fafc;"
        >
            	<!-- Dashboard Content -->
          <div class="dashboard-content">
            <div class="container">
              <dashboardmenu />
              <div class="dashboard-details">
                <div class="row">

                  <div 
                    class="col-lg-3 col-md-3 " 
                  >
                    <div class="card dash-cards">
                      <div class="card-body">
                        <div class="dash-top-content">
                          <div class="dashcard-img">
                            <i class="fa fa-store fa-3x"></i>
                          </div>
                        </div>
                        <div class="dash-widget-info">
                          <h6>Franquicias</h6>
                          <h3 class="counter">{{ countCompany }}</h3>
                        </div>

                        <br/>
                        <b-list-group>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            Activas
                            <b-badge variant="primary" pill>{{ countActiveCompany }}</b-badge>
                          </b-list-group-item>

                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            Destacadas y Activas
                            <b-badge variant="primary" pill>2</b-badge>
                          </b-list-group-item>

                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            No Visibles
                            <b-badge variant="primary" pill>1</b-badge>
                          </b-list-group-item>
                        </b-list-group>
                      </div>

                    </div>

                    
                    <br>
                  </div>

                  <div 
                    class="col-lg-3 col-md-3 " 
                  >
                    <div class="card dash-cards">
                      <div class="card-body">
                        <div class="dash-top-content">
                          <div class="dashcard-img">
                            <i class="fa fa-users fa-3x"></i>
                          </div>
                        </div>
                        <div class="dash-widget-info">
                          <h6>Usuarios</h6>
                          <h3 class="counter">{{ countUser }}</h3>
                        </div>

                        <br/>
                        <b-list-group>
                          <a href="/admin-users-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Activos
                              <b-badge variant="primary" pill>{{ countActiveUser }}</b-badge>
                            </b-list-group-item>
                          </a>
                          
                          <a href="/admin-user-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Inversionistas 
                              <b-badge variant="primary" pill>{{ countInvestUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                          <a href="/admin-manager-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Administradores 
                              <b-badge variant="primary" pill>{{ countInvestUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                        </b-list-group>

                      </div>
                    </div>
                  </div>





                  <div 
                    class="col-lg-3 col-md-3 " 
                  >
                    <div class="card dash-cards">
                      <div class="card-body">
                        <div class="dash-top-content">
                          <div class="dashcard-img">
                            <i class="far fa-file-alt fa-3x"></i>
                          </div>
                        </div>
                        <div class="dash-widget-info">
                          <h6>Publicaciones</h6>
                          <h3 class="counter">{{ countUser }}</h3>
                        </div>

                        <br/>
                        <b-list-group>
                          <a href="/admin-news-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Noticias
                              <b-badge variant="primary" pill>{{ countActiveUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                          <a href="/admin-interviews-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Entrevistas
                              <b-badge variant="primary" pill>{{ countActiveUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                          <a href="/admin-videos-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Videos 
                              <b-badge variant="primary" pill>{{ countInvestUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                        </b-list-group>

                      </div>
                    </div>
                  </div>



                  <div 
                    class="col-lg-3 col-md-3 " 
                  >
                    <div class="card dash-cards">
                      <div class="card-body">
                        <div class="dash-top-content">
                          <div class="dashcard-img">
                            <i class="fas fa-pencil-alt fa-3x"></i>
                          </div>
                        </div>
                        <div class="dash-widget-info">
                          <h6>Contenidos</h6>
                          <h3 class="counter">{{ countUser }}</h3>
                        </div>

                        <br/>
                        <b-list-group>

                          <a href="/admin-consulting-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Asociaciones
                              <b-badge variant="primary" pill>{{ countActiveUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                          <a href="/admin-association-list">
                            <b-list-group-item class="d-flex justify-content-between align-items-center">
                              Consultoras
                              <b-badge variant="primary" pill>{{ countActiveUser }}</b-badge>
                            </b-list-group-item>
                          </a>

                        </b-list-group>

                      </div>
                    </div>
                  </div>

                </div>

                <!-- dashboard-info -->
                <div class="row dashboard-info">
                  
                  
                </div>
                <!-- /dashboard-info -->
              </div>
            </div>
          </div>

            <scroll />
        </div>
    </div>
</template>


<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Dashboard from '../../../assets/json/dashboard.json'
import DashboardReview from '../../../assets/json/dashboardReview.json'
import DashboardMenu from '../../../assets/json/dashboard.json'
import axios from 'axios'; // Importar Axios

export default {
    components: 
    {
        SidebarMenu
    },
    data() {
        return {
            Dashboard: Dashboard,
			      DashboardReview: DashboardReview,
            countCompany: 0,
            countActiveCompany: 0,
            countUser: 0,
            countActiveUser: 0,
            countInvestUser: 0,
            companies: [],
            title: "Login",
            text: "Inicio",
            text1: "Login",
            name: "/",
        }
    },
    mounted() {
        this.countCompanies(0);
        this.countCompanies(1);
        this.countUsers(0);
        this.countUsers(1);        
        this.countUsers(2);
    },
    methods: {

      async countCompanies(filter) {
        try {
            let filter_string = '';
            if(filter == 0){ // registradas
              filter_string = '';
            }else if(filter == 1){ // activas
              filter_string = '&active=1';
            } 
            const response = await axios.get( 
                process.env.VUE_APP_API_DIRECTORY + 
                "companies-list?order=created_at&by=asc" +
                filter_string, 
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                }
            });

            // Almacenar la lista de compañías en la propiedad 'companies'
            this.companies = response.data.data;

            if(filter == 0){ // registradas
              this.countCompany = response.data.total;
            }else if(filter == 1){ // activas
              this.countActiveCompany = response.data.total;
            } 

        } catch (error) {
            console.error('Error fetching companies:', error);
        }
      },


      async countUsers(filter) {
        try {
            let filter_string = '';
            if(filter == 0){ // registradas
              filter_string = '';
            }else if(filter == 1){ // activas
              filter_string = '&active=1';
            }else if(filter == 2){ // inversionista
              filter_string = '&active=1&role=user';
            } 
            const response = await axios.get( 
                process.env.VUE_APP_API_DIRECTORY + 
                "users?order=created_at&by=asc" +
                filter_string, 
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                }
            });

            // Almacenar la lista de compañías en la propiedad 'companies'
            this.companies = response.data.data;

            if(filter == 0){ // registradas
              this.countUser = response.data.total;
            }else if(filter == 1){ // activas
              this.countActiveUser = response.data.total;
            }else if(filter == 2){ // activas
              this.countInvestUser = response.data.total;
            } 

        } catch (error) {
            console.error('Error fetching companies:', error);
        }
      },

    }
}
</script>