<template>
    <!-- Blog List -->
		<div class="bloglist-section blog-gridpage">
		    <div class="container">
		        <b-overlay :show="show" rounded="sm">
					<div class="row">
					
		            <div 
						class="col-lg-4 col-md-4 d-lg-flex" 
						v-for="item in publications" 
						:key="item.id"
					>
				      <div class="blog grid-blog" style="max-width: 424px;">
						<div class="blog-image">
							
		    				<router-link 
								:to="'/actualidad/'+item.slug"
							>
									<img 
										class="img-fluid"
										v-if="item.logo" 
										:src="item.logo.route" 
										alt="Post Image"
									>
									<img 
										v-else
										class="img-fluid" 
										:src="require(`../../../../assets/img/blog/blog-1.jpg`)" 
										alt="Post Image"
									>
							</router-link>
					    </div>
			   		    <div class="blog-content">
							<ul class="entry-meta meta-item">
							    <li>
								    <div class="post-author">
									 <div class="" style="border-radius:none !important;">
										  <img :src="require('../../../../assets/img/user.png')"  alt="author">
									</div>
									 <a href="javascript:void(0)"> <span> {{ item.author }} </span></a>
									</div>
								</li>
								<li class="">
									<h5 v-if="item.type == 'news'"><b-badge><i class="far fa-newspaper" style="color: white;"></i> Noticias</b-badge></h5>
									<h5 v-else-if="item.type == 'interviews'"><b-badge><i class="far fa-comments" style="color: white;"></i> Entrevistas</b-badge></h5>
									<h5 v-else-if="item.type == 'videos'"><b-badge><i class="fas fa-play" style="color: white;"></i> Videos</b-badge></h5>
								</li>
							</ul>
							<h3 class="blog-title">
								<router-link 
									:to="'/actualidad/'+item.slug"
								>{{ item.title }}</router-link>
							</h3>
								<p class="blog-description">{{ item.shortdesc }}</p>
								<i class="fa-solid fa-calendar-days"></i> {{ tiempoTranscurrido(item.date) }}
								<div class="viewlink">
									<router-link 
										:to="'/actualidad/'+item.slug"
									>
										Ver detalles<i class="feather-arrow-right"></i>
									</router-link>
								</div>
						</div>
					  </div>
					</div>	  
				</div></b-overlay>	
						   
				<!--Pagination--> 
				<div class="blog-pagination">
					<nav>
						<ul class="pagination">
							<li class="page-item previtem">
							<a class="page-link" href="#">
								<i class="fas fa-regular fa-arrow-left"></i>
							</a>
				        	</li>
							<li class="justify-content-center pagination-center"> 
								<div class="pagelink">
								    <ul>
										<li class="page-item">
											<a class="page-link" href="#">1</a>
										</li>
										<li class="page-item active">
											<a class="page-link" href="#">2 <span class="visually-hidden">(current)</span></a>
										</li>
						     			<li class="page-item">
							    			<a class="page-link" href="#">3</a>
									   </li>
									   <li class="page-item">
							    			<a class="page-link" href="#">...</a>
									   </li>
									   <li class="page-item">
							    			<a class="page-link" href="#">14</a>
									   </li>
								    </ul>
								</div>													
							</li>													
							<li class="page-item nextlink">
								<a class="page-link" href="#">
									<i class="fas fa-regular fa-arrow-right"></i>
								</a>
							</li>
						</ul>
					</nav>
				</div>
				 <!--/Pagination-->
				 
			</div>				   
		</div>			
		<!-- /Blog List -->
</template>

<script>
import axios from 'axios';

	export default {
		props: ['publicationType'],
		data() {
			return {
				publications: [],
				show: false
			};
		},
		mounted() {

			// Función para contar las líneas de texto en un elemento
			const contarLineas = (elemento) => {
				// Calcula la altura de una sola línea de texto
				const lineHeight = parseInt(window.getComputedStyle(elemento).lineHeight);

				// Calcula la altura del elemento
				const height = parseInt(window.getComputedStyle(elemento).height);

				// Calcula el número de líneas
				const lineas = height / lineHeight;

				return lineas;
			}

			// Verifica si el contenido excede las tres líneas y agrega puntos suspensivos si es necesario
			const descripcion = document.querySelector('.blog-description');
			if (descripcion && contarLineas(descripcion) > 3) {
				descripcion.style.overflow = 'hidden';
				descripcion.style.textOverflow = 'ellipsis';
				descripcion.style.webkitLineClamp = '3';
			}

			this.fetchPublications();
		},
		methods: {
			async fetchPublications() {
				this.show = true
				try {
					const response = await axios.get(
						process.env.VUE_APP_API_DIRECTORY + 
						"publications?type=" + 
						this.publicationType + 
						"&order=created_at&by=desc&active=1");
					// Actualiza IndexDestination con los datos obtenidos
					this.publications = response.data.data;
					this.show = false
				} catch (error) {
					console.error('Error fetching categories:', error);
				}
			},
			
			tiempoTranscurrido(fecha) {
				const fechaActual = new Date();
				const fechaOcurrencia = new Date(fecha);

				// Calcula la diferencia en milisegundos
				const diferencia = fechaActual.getTime() - fechaOcurrencia.getTime();

				// Convierte la diferencia de milisegundos a segundos, minutos, horas, días, meses y años
				const segundosTranscurridos = Math.floor(diferencia / 1000);
				const minutosTranscurridos = Math.floor(segundosTranscurridos / 60);
				const horasTranscurridas = Math.floor(minutosTranscurridos / 60);
				const diasTranscurridos = Math.floor(horasTranscurridas / 24);
				const mesesTranscurridos = Math.floor(diasTranscurridos / 30);
				const añosTranscurridos = Math.floor(mesesTranscurridos / 12);

				if (añosTranscurridos > 0) {
					if (añosTranscurridos === 1) {
						return 'Hace un año';
					} else {
						return `Hace ${añosTranscurridos} años`;
					}
				} else if (mesesTranscurridos > 0) {
					if (mesesTranscurridos === 1) {
						return 'Hace un mes';
					} else {
						return `Hace ${mesesTranscurridos} meses`;
					}
				} else if (diasTranscurridos > 0) {
					if (diasTranscurridos === 1) {
						return 'Ayer';
					} else {
						return `Hace ${diasTranscurridos} días`;
					}
				} else if (horasTranscurridas > 0) {
					return `Hace ${horasTranscurridas} horas`;
				} else if (minutosTranscurridos > 0) {
					return `Hace ${minutosTranscurridos} minutos`;
				} else {
					return `Hace ${segundosTranscurridos} segundos`;
				}
			}
		}
	};
</script>

<style>
.blog-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* Número de líneas a mostrar */
    -webkit-line-clamp: 3;
}
</style>