<template>
    <div class="main-wrapper">
        <navbarfive />

        <div class="page-wrapper">
            <indexdiscover />

            <indexbusiness />

            <indexfivespace />

            <indexfiveclient />

            <indexfivefooter />

            <scroll />
        </div>
    </div>
</template>