<template>
    <div class="main-wrapper">
        <layouts></layouts>

            <div class="page-wrapper">
                <servicegallery
                    :imgs="imgs_cover"
                />

                <servicedescription
                    :companyData="companyData"
                    :imgs="imgs_cof"
                    :show="show"
                    :isFavorite="isFavorite"
                    :isLoggedIn="isLoggedIn"
                />

                <servicecontent 
                    :companyData="companyData"
                    :imgs="imgs_image"
                />

                <foot />

                <scroll />
            </div>
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      /*companyData: {
        name: '',
        description: '',
        logo: '',
        rating: 0,
        investment: '',
      },*/
      companyData: [],
      show: true,
      isLoggedIn: false, 
	  isFavorite: false,
      companiesList: [],
      imgs_cof: [],
      imgs_cover: [],
      imgs_image: []
    };
  },
  mounted() {

        this.getData()

  },
  methods: {

        checkFavorite(idComp) {

            if (localStorage.getItem("tokenSession")) {
                // Usuario está logueado
                this.isLoggedIn = true;
            }

            if (this.isLoggedIn) {
                    //verifciar favorito
                    const config = {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.getItem("tokenSession")
                        }
                    };

                    axios.get(process.env.VUE_APP_API_DIRECTORY + 'rating-management/get-count-user?user_id='+localStorage.getItem("userId")+'&company_id='+idComp, config)
                        .then(response => {
                            const data = response.data;
                            if(response.data > 0){
                                this.isFavorite = true;
                            }
                        })
                        .catch(error => {
                            console.error('Error al verificar favorito:', error);
                        });
                }


        },
      getData() {

        
          // Realiza la solicitud a la API utilizando Axios
            axios.get(process.env.VUE_APP_API_DIRECTORY+'companies-by-slug/'+this.$route.params.slug, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer '+process.env.VUE_APP_TOKEN, 
                },
            })
            .then(response => {
                this.checkFavorite(response.data.id)
                // Actualiza los datos del estado con la respuesta de la API
                this.companyData = response.data;
                const data = response.data;
                this.imgs_cof = data.uploads
                            .filter(upload => upload.type === 'cof')
                            .map(upload => upload.route);
                this.imgs_cover = data.uploads
                            .filter(upload => upload.type === 'cover')
                            .map(upload => upload.route);
                this.imgs_image = data.uploads
                            .filter(upload => upload.type === 'image')
                            .map(upload => upload.route);
                            this.show = false
                })
            .catch(error => {
            console.error('Error al obtener datos de la API:', error);
            });
      }
  },
};
</script>
