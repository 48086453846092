<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

            <listcontent />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Listings-Grid",
            text: "Bienvenido",
            text1: "Grid",
            name: "/",
        }
    },
    mounted() {
			$('.fav-icon').on('click', function () {
			$(this).toggleClass('selected');
		});
	},
}
</script>