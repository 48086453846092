<template>
    <!--Galler Slider Section-->
    <div 
		class="bannergallery-section"
		style="margin-top: 120px;"
	>
        <div class="gallery-slider d-flex">
            <div 
                class="gallery-widget" 
                v-for="(src, index) in imgs" 
                :key="index" @click="() => showImg(index)"
            >   
                <img 
                    class="img-fluid" 
                    alt="Image" 
                    :src="src"
                >
            </div> 
            <vue-easy-lightbox
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
            ></vue-easy-lightbox>   
                                
        </div>         
    </div>      
    <!--/Galler Slider Section-->
</template>

<script>

export default {
    props: ["imgs"],
    data() {
        return {
            visible: false,
            index: 0, // default: 0
            img: [], 
        };
    },
    mounted() {
        
    },
    methods: {
        showImg(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        }
    },
};
</script>
