<template>
    <section class="cta-section">
		    <div class="container">
			    <div class="row align-items-center">
				     <div class="col-lg-7">
					      <div class="cta-content">
							<h3>¿Buscas una  <br>
								<span>Franquicia?</span>							     
							  </h3>
							  <p>Invierte en el futuro y regístrate gratis hoy. <br/>
								¡Tu próxima gran oportunidad está a solo un clic de distancia!</p>
							  <div class="cta-btn">
							     <router-link 
								 to="add-listing" 
								 class="btn-primary postad-btn">Quiero Registrarme</router-link>						  
							  </div>
						  </div>
					 </div>
				     <div class="col-lg-5">
					       <div class="cta-img about-img">
						        <img src="../../../assets/img/cta-img.png" class="img-fluid" alt="CTA">
						   </div>
					 </div>
				</div>
			</div>
		</section>
</template>