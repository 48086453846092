<template>
     <div class="header-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-4">
                    <div class="selection-list">
                        <div class="lang-select">
                            <span class="select-icon"><i class="feather-globe"></i> </span>
                            <vue-select 
                                class="select"
                                :options="idioms"
                                :settings="{ 
                                    dropdownAutoWidth: true, 
                                    width: '100%'
                                }"
                                placeholder="[ES] - Español"
                            />
                            
                        </div>
                        <div 
                            class="currency-select"
                            style="position: relative;"
                        >
                            <span class="select-icon">
                                <img 
                                    :src="require(`../assets/img/flag/venezuela.png`)" 
                                    class="img-fluid"
                                    alt="find your destination"
                                    style="max-width: 15px; position: absolute;
                                    top: 28%;
                                    z-index: 9;
                                    left: 6px;"
                                >
                            </span>
                            <vue-select
                                class="select"
                                style="margin-left: 13px;"
                                :options="countries"  
                                placeholder="Venezuela"
                                :settings="{ 
                                    dropdownAutoWidth: true, 
                                    width: '100%'
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <ul class="d-flex justify-content-end">
                        <li class="d-flex align-items-center"><i class="feather-phone me-1"></i> +1 (786) 408-9356</li>
                        <li class="d-flex align-items-center"><i class="feather-mail me-1"></i> info@franquiciar.com</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        data(){
            return{
                idioms: ["[ES] - Español", "[EN] - Ingles", "[PT] - Portugues"],
                countries: []
            }
        },
        mounted() {
            this.fetchCountries();
            $('body').append('<div class="sidebar-overlay"></div>');
            $(document).on('click', '#mobile_btn', function () {
                $('main-wrapper').toggleClass('slide-nav');
                $('.sidebar-overlay').toggleClass('opened');
                $('html').addClass('menu-opened');
                return false;
            });

            $(document).on('click', '.sidebar-overlay', function () {
                $('html').removeClass('menu-opened');
                $(this).removeClass('opened');
                $('main-wrapper').removeClass('slide-nav');
            });

            $(document).on('click', '#menu_close', function () {
                $('html').removeClass('menu-opened');
                $('.sidebar-overlay').removeClass('opened');
                $('main-wrapper').removeClass('slide-nav');
            });
        },  
        methods: {
            async fetchCountries() {
                try {
                    const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "countries?active=1&limit=100");
                    // Actualiza la propiedad Category con los datos obtenidos
                    this.countries = response.data.map(country => ({
                        id: country.id, 
                        text: country.name 
                    }));
                } catch (error) {
                    console.error('Error fetching countries:', error);
                }
            },
        }
    }
</script>