<template>
    <div class="main-wrapper">
        <layouts></layouts>

            <div class="page-wrapper">
                
                <franchisebreadcrumb />
                
                <franchisecontent />

                <foot />

                <scroll />
            </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "INVERTIR EN FRANQUICIAS",
            text: "Bienvenido",
            text1: "INVERTIR EN FRANQUICIAS",
            name: "/",
        }
    },
}
</script>