<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
            
            <termscontent />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Terminos y Condiciones",
            text: "Bienvenido",
            text1: "Terms",
            cover: "contract.jpg",
            name: "/",
        }
    },
}
</script>