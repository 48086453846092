<template>
    <ul class="main-nav">
        <ul class="navbar-nav main-nav my-2 my-lg-0">
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void()">Franquiciar <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'about' ? 'active' : 'notactive'"><router-link to="/about">¿Qué es Franquiciar?</router-link></li>
                                <li :class="currentPath == 'pricing' ? 'active' : 'notactive'"><router-link to="/pricing">Registra tu Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/howitworks">Tu Asesor</router-link>
                        </li>
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void()">Franchising <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'the-franchise' ? 'active' : 'notactive'"><router-link to="/the-franchise">La Franquicia</router-link></li>
                                <li :class="currentPath == 'association-franchise' ? 'active' : 'notactive'"><router-link to="/association-franchise">Asociaciones de Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class=" BlogMenu ? 'active' : 'notactive'">
                            <a href="#">Actualidad <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li 
                                    :class="currentPath == 'news' ? 'active' : 'notactive'"
                                >
                                    <a href="/news">Noticias</a>
                                </li>
                                <li 
                                    :class="currentPath == 'interviews' ? 'active' : 'notactive'"
                                >
                                    <a href="/interviews">Entrevistas</a>
                                </li>
                                <li 
                                    :class="currentPath == 'videos' ? 'active' : 'notactive'"
                                >
                                    <a href="/videos">Videos</a>
                                </li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/contact">Contacto</router-link>
                        </li>
                    </ul>
</ul>
</template>

<script>
    export default {
        components: {
        
        },
        mounted() {
            if ($(window).width() <= 991) {
            var Sidemenu = function () {
                this.$menuItem = $('.main-nav a');
            };

            function init() {
                var $this = Sidemenu;
                $('.main-nav a').on('click', function (e) {
                    if ($(this).parent().hasClass('has-submenu')) {
                        e.preventDefault();
                    }
                    if (!$(this).hasClass('submenu')) {
                        $('ul', $(this).parents('ul:first')).slideUp(350);
                        $('a', $(this).parents('ul:first')).removeClass('submenu');
                        $(this).next('ul').slideDown(350);
                        $(this).addClass('submenu');
                    } else if ($(this).hasClass('submenu')) {
                        $(this).removeClass('submenu');
                        $(this).next('ul').slideUp(350);
                    }
                });
            }
            init();
        }
        },
        computed:{
            currentPath() {
                return this.$route.name
            },
            IndexMenu() {
                return this.$route.name == 'index' || this.$route.name == 'index-2' || this.$route.name == 'index-3' || this.$route.name == 'index-4';
            },
            ListingsMenu() {
                return this.$route.name == 'listing-grid' || this.$route.name == 'listing-grid-sidebar' || this.$route.name == 'listing-list-sidebar' || this.$route.name == 'listingmap-list'  || this.$route.name == 'listingmap-grid';
            },
            PagesMenu() {
                return this.$route.name == 'about' || this.$route.name == 'service-details' || this.$route.name == 'pricing' || this.$route.name == 'faq' || this.$route.name == 'gallery' || this.$route.name == 'categories'
                || this.$route.name == 'howitworks' || this.$route.name == 'terms-condition' || this.$route.name == 'privacy-policy' || this.$route.name == 'error-404' || this.$route.name == 'error-500';
            },
            UserMenu() {
                return this.$route.name == 'dashboard' || this.$route.name == 'profile' || this.$route.name == 'my-listing' || this.$route.name == 'bookmarks' || this.$route.name == 'messages' || this.$route.name == 'reviews' || this.$route.name == 'add-listing';
            },
            BlogMenu() {
                return this.$route.name == 'blog-list' || this.$route.name == 'blog-grid' || this.$route.name == 'blog-details' || this.$route.name == 'blog-list-sidebar' || this.$route.name == 'blog-grid-sidebar';
            }
        }
    }
</script>