<template>
    <!-- Login Section -->		
		<div class="login-content">
			<div class="container">
				
        
        
        <div v-if="!validatedCode" class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap password-form">
							
							<div class="login-header">
								<p>Ingresa el código de recuperación que enviamos a tu correo electrónico</p>								
							</div>
							
							<!-- Login Form -->
							<b-form action="login">
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="fab fa-buromobelexperte"></i>
										<b-form-input 
											type="number" 
											class="form-control" 
											v-model="code"
											placeholder="Código"
                      :state="codeState"
                      aria-describedby="code-feedback"
											max="999999"
											min="100000"
                      required
										/>

                    <b-form-invalid-feedback 
                      id="code-feedback"
                      v-if="code && !codeState" 
                    >
                      El código debe ser un número de 6 dígitos
                    </b-form-invalid-feedback>

									</div>
								</div>
                <b-overlay
                  :show="show"
                  rounded
                  opacity="1"
                  spinner-small
                  spinner-variant="primary"
                  @hidden="onHidden"
								>
								  <b-button 
                    variant="primary w-100 login-btn" 
                    type="submit"
                    :disabled="codeState === false"
                    @click.prevent="checkCode()"
                  >Activar</b-button>	
                </b-overlay>									
							</b-form>
							<router-link to="/" class="back-home"><i class="fas fa-regular fa-arrow-left me-1"></i> Regresar</router-link>		
							<!-- /Login Form -->
											
						</div>
					</div>
				</div>

        <div v-else class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap password-form">
							
							<div class="login-header">
								<p>Ingresa el código de recuperación que enviamos a tu correo electrónico</p>								
							</div>
							
							<!-- Login Form -->
							<b-form action="login">

								<div class="form-group group-img">
								    <div class="group-img">
										<i class="fas fa-lock"></i>
										<b-form-input 
											type="text" 
                      v-model="password"
											class="form-control" 
											placeholder="Ingresa tu contraseña"
                      required
										/>
									</div>
								</div>

                <div class="form-group group-img">
								    <div class="group-img">
                    <i class="fas fa-lock"></i>
										<b-form-input 
											type="text" 
											class="form-control" 
											v-model="passwordConfirmation"
											placeholder="Confirmación de contraseña"
                      required
										/>
									</div>
								</div>

                <b-overlay
                  :show="show"
                  rounded
                  opacity="1"
                  spinner-small
                  spinner-variant="primary"
                  @hidden="onHidden"
								>
                  <b-button 
                    variant="primary w-100 login-btn" 
                    @click.prevent="checkPassword"
                    type="submit"
                  >Confirmar</b-button>								
                </b-overlay>

							</b-form>
							<router-link to="/" class="back-home"><i class="fas fa-regular fa-arrow-left me-1"></i> Regresar</router-link>		
							<!-- /Login Form -->
											
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<!-- /Login Section -->
</template>

<script>
import axios from 'axios';

export default {
    name: 'Recovery',
    data: () => ({
      show: false,
      email: '',
      code: '',
      password: '',
      passwordConfirmation: '',
      validatedCode: false,
      codeValid: false,
      error: false,
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
    }),
    computed: {
      codeState() {
        const codePattern = /^[0-9]{6}$/; // Regular expression for a 6-digit number
        this.codeValid = codePattern.test(this.code);
      },
    },
    methods: {
      checkCode() {
          this.show = true
          var urlRecoveryFind = process.env.VUE_APP_API_DIRECTORY + 'password/find/'
          axios
            .get(urlRecoveryFind + this.code)
            .then((response) => {
              this.email = response.data.email
              this.validatedCode = true
              this.show = false
            })
            .catch((error) => {
              this.$swal({
                title: 'Código inválido',
                text:
                  'No hemos encontrado usuarios asociados al código de recuperación que ingresaste en nuestros registros',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#e94e1b',
                showConfirmButton: true,
              })
              this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
              setTimeout(() => location.reload, 5000)
              console.log(error)
              this.show = false
              this.error = true
            })
      },
      onHidden() {
        // Return focus to the button once hidden
        this.$refs.button.focus()
      },
      checkPassword() {
        if (this.password == this.passwordConfirmation) {
          this.setPassword()
        } else {
          this.$swal({
            title: 'Falló la confirmación de la contraseña',
            text:
              'La confirmación de la contraseña no coincide. Por favor, intenta nuevamente',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#e94e1b',
            showConfirmButton: true,
          })
        }
      },
      setPassword() {
        this.show = true
        var urlSetPassword = process.env.VUE_APP_API_DIRECTORY + 'password/reset'
        axios
          .post(urlSetPassword, {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            token: this.code,
          })
          .then((response) => {
            this.$swal({
              title: 'Proceso completado',
              text: 'Haz restablecido tu contraseña exitosamente',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#003d74',
              showConfirmButton: true,
            })
            this.$router.push('/login')
          })
          .catch((error) => {
            console.log(error)
            this.error = true
            this.show = false
          })
      },
    },
  }

</script>