<template>
    <!-- Categories Section -->
		<div class="categorieslist-section">
            <div class="container">
		        <div class="row">
			       <div 
				   		class="col-lg-3 col-md-4"
						v-for="item in IndexDestination"
						:key="item.id"
					>
				    	<div class="categories-content">
							<a 
                                :href="'/listing?category='+item.id" 
                                class="text-center aos aos-init aos-animate" 
                                data-aos="fade-up"
                            >
							   <i 
							   	:class="item.icon"
								style="font-size: 60px;"
								></i>
							   <h6>{{ item.name }}</h6>
							   <span class="ads"><strong>12</strong> Franquicias</span>
							</a>								   
					   </div>
				   </div>
				</div>
			</div>
		</div>
		<!-- /Categories Section -->
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            IndexDestination: [],
            countCategories: 0,
        };
    },
    mounted() {
        this.fetchCategories(); // Llama a fetchCategories cuando el componente se monta
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=300&type=0");
                // Actualiza IndexDestination con los datos obtenidos
                this.IndexDestination = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
    }
};
</script>