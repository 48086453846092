<template>
    <!-- FAQ Banner -->
		<section class="section-blk faq-blk">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="title-blk justify-content-center text-center">
							<h2>Frequently asked Questions</h2>
							<h5>Have questions? We're here to help.</h5>
						</div>	
						<div class="row justify-content-center">
							<div class="col-md-8">
								<div class="faq-tabs-blk mb-50">
									<ul class="nav tabs-style gap-3" data-bs-tabs="tabs">
										<li>
											<a class="active" aria-current="true" data-bs-toggle="tab" href="#FAQhotels">
												hotels
											</a>
										</li>
										<li>
											<a data-bs-toggle="tab" href="#FAQpayments">
												Payments
											</a>
										</li>
										<li>
											<a data-bs-toggle="tab" href="#FAQtrip">
												trip
											</a>
										</li>
										<li>
											<a data-bs-toggle="tab" href="#FAQguide">
												guide
											</a>
										</li>
										<li>
											<a data-bs-toggle="tab" href="#FAQcancel">
												cancel
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="card-body tab-content">
							<div class="tab-pane active" id="FAQhotels">	
								<div class="row">
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How to book a tour?</h5>
											<p class="text-gray-style">To book a tour to India, we suggest you that you view the India Journeys Page on the website, which include many tours that are being taken by most of the tourists coming to India. If you intend to make any changes, or customize your travel itinerary, you can request a quote by filling our online query form or sending us an e mail at: bookings@journey.com</p>
										</div>	
									</div>
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How will I be picked up from the Airport ?</h5>
											<p class="text-gray-style">You will be picked up by our representative at the arrival hall of the particular International Airport of India. You should not leave Air Port without our representative if you are to be picked up. Pioneer Journeys representative would be holding a welcome board with your name on it. If you are part of a group, the name of the group will be mentioned on the welcome board.</p>
										</div>	
									</div>
								</div>				
							</div>
							<div class="tab-pane fade" id="FAQpayments">	
								<div class="row">
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How to book a tour?</h5>
											<p class="text-gray-style">To book a tour to India, we suggest you that you view the India Journeys Page on the website, which include many tours that are being taken by most of the tourists coming to India. If you intend to make any changes, or customize your travel itinerary, you can request a quote by filling our online query form or sending us an e mail at: bookings@journey.com</p>
										</div>	
									</div>
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How will I be picked up from the Airport ?</h5>
											<p class="text-gray-style">You will be picked up by our representative at the arrival hall of the particular International Airport of India. You should not leave Air Port without our representative if you are to be picked up. Pioneer Journeys representative would be holding a welcome board with your name on it. If you are part of a group, the name of the group will be mentioned on the welcome board.</p>
										</div>	
									</div>
								</div>				
							</div>
							<div class="tab-pane fade" id="FAQtrip">	
								<div class="row">
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How to book a tour?</h5>
											<p class="text-gray-style">To book a tour to India, we suggest you that you view the India Journeys Page on the website, which include many tours that are being taken by most of the tourists coming to India. If you intend to make any changes, or customize your travel itinerary, you can request a quote by filling our online query form or sending us an e mail at: bookings@journey.com</p>
										</div>	
									</div>
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How will I be picked up from the Airport ?</h5>
											<p class="text-gray-style">You will be picked up by our representative at the arrival hall of the particular International Airport of India. You should not leave Air Port without our representative if you are to be picked up. Pioneer Journeys representative would be holding a welcome board with your name on it. If you are part of a group, the name of the group will be mentioned on the welcome board.</p>
										</div>	
									</div>
								</div>				
							</div>
							<div class="tab-pane fade" id="FAQguide">	
								<div class="row">
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How to book a tour?</h5>
											<p class="text-gray-style">To book a tour to India, we suggest you that you view the India Journeys Page on the website, which include many tours that are being taken by most of the tourists coming to India. If you intend to make any changes, or customize your travel itinerary, you can request a quote by filling our online query form or sending us an e mail at: bookings@journey.com</p>
										</div>	
									</div>
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How will I be picked up from the Airport ?</h5>
											<p class="text-gray-style">You will be picked up by our representative at the arrival hall of the particular International Airport of India. You should not leave Air Port without our representative if you are to be picked up. Pioneer Journeys representative would be holding a welcome board with your name on it. If you are part of a group, the name of the group will be mentioned on the welcome board.</p>
										</div>	
									</div>
								</div>				
							</div>
							<div class="tab-pane fade" id="FAQcancel">	
								<div class="row">
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How to book a tour?</h5>
											<p class="text-gray-style">To book a tour to India, we suggest you that you view the India Journeys Page on the website, which include many tours that are being taken by most of the tourists coming to India. If you intend to make any changes, or customize your travel itinerary, you can request a quote by filling our online query form or sending us an e mail at: bookings@journey.com</p>
										</div>	
									</div>
									<div class="col-sm-6 d-flex">
										<div class="faq-shadow-blk flex-fill">
											<h5>How will I be picked up from the Airport ?</h5>
											<p class="text-gray-style">You will be picked up by our representative at the arrival hall of the particular International Airport of India. You should not leave Air Port without our representative if you are to be picked up. Pioneer Journeys representative would be holding a welcome board with your name on it. If you are part of a group, the name of the group will be mentioned on the welcome board.</p>
										</div>	
									</div>
								</div>				
							</div>
						</div>
						<div class="btm-style">
							<a href="#price" class="price-down">
								<span>
									<i class="feather-arrow-down"></i>
								</span>
							</a>
						</div> 
					</div>
				</div>
			</div>
		</section>	
		<!-- /FAQ Banner -->	
		
		<!-- Price Section -->
		<section class="section-blk price-section" id="price">
			<div class="container">					
				<div class="row text-center">		
					<div class="col-md-12">	
						<div class="title-blk justify-content-center text-center">
							<h2>Our Pricing Plan</h2>
							<h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
						</div>	
					 </div>									 
				</div>	 
			</div>
			<div class="container">
				<div class="row">
				    <div class="col-lg-3 d-flex col-md-6">
					    <div class="price-card price-three flex-fill">
							<div class="price-head">
								<div class="price-level">
									<h6>Intro</h6>
								</div>						
							</div>	
							<div class="price-body">
								<p>For most business that want to optimize web queries</p>
								<ul>
									<li class="active">Basic listing submission</li>
									<li class="inactive">One Listing</li>
									<li class="inactive">30 days Availabilty</li>
									<li class="inactive">Limited Support</li>
									<li class="inactive">Edit your listing</li>
								</ul>
								<h4>$10 <span>/ month</span></h4>		
								<div>
									<router-link to="/login" class="btn viewdetails-btn">Choose</router-link>
								</div>							
							</div>							
					    </div>
					</div>
					<div class="col-lg-3 d-flex col-md-6">
					    <div class="price-card price-three flex-fill">
							<div class="price-head">
								<div class="price-level">
									<h6>Basic</h6>
								</div>								
							</div>	
							<div class="price-body">
								<p>For most business that want to optimize web queries</p>
								<ul>
									<li class="active">Basic listing submission</li>
									<li class="active">One Listing</li>
									<li class="inactive">30 days Availabilty</li>
									<li class="inactive">Limited Support</li>
									<li class="inactive">Edit your listing</li>
								</ul>
								<h4>$25 <span>/ month</span></h4>
								<div>
									<router-link to="/login" class="btn viewdetails-btn">Choose</router-link>
								</div>							
							</div>							
					    </div>	
					</div>
					<div class="col-lg-3 d-flex col-md-6">
					    <div class="price-card price-three flex-fill">
							<div class="price-head">
								<div class="price-level">
									<h6>Pro</h6>
									<span>Save $40</span>
								</div>								
							</div>	
							<div class="price-body">
								<p>For most business that want to optimize web queries</p>
								<ul>
									<li class="active">Basic listing submission</li>
									<li class="active">One Listing</li>
									<li class="active">30 days Availabilty</li>
									<li class="inactive">Limited Support</li>
									<li class="inactive">Edit your listing</li>
								</ul>
								<h4>$50 <span>/ month</span></h4>	
								<div>
									<router-link to="/login" class="btn viewdetails-btn">Choose</router-link>
								</div>							
							</div>							
					    </div>
					</div>
					<div class="col-lg-3 d-flex col-md-6">
					     <div class="price-card price-three flex-fill">
							<div class="price-head">
								<div class="price-level">
									<h6>Enterprise</h6>
								</div>								
							</div>	
							<div class="price-body">
								<p>For most business that want to optimize web queries</p>
								<ul>
									<li class="active">Basic listing submission</li>
									<li class="active">One Listing</li>
									<li class="active">30 days Availabilty</li>
									<li class="inactive">Limited Support</li>
									<li class="inactive">Edit your listing</li>
								</ul>
								<h4>$100 <span>/ month</span></h4>	
								<div>
									<router-link to="/login" class="btn viewdetails-btn">Choose</router-link>
								</div>							
							</div>							
					    </div>
					</div>
				</div>
			</div>
		</section>	
		<!-- /Price Section -->
</template>