<template>
    <!--Blog Banner-->
        <div class="blogbanner" :style="{ 'background-image': 'url(' + coverImage + ')' }">
           <div class="blogbanner-content">
              <h1>{{ post.title }}</h1>
              <ul class="entry-meta meta-item">
                  <li>
                      <div class="post-author">
                          <div class="">
                              <img :src="require('../../../../assets/img/user.png')" alt="author">
                          </div>
                          <a href="javascript:void(0)"><span> {{ post.author }} </span></a>
                      </div>
                  </li>
                  <li class="date-icon"><i class="fa-solid fa-calendar-days"></i> {{ tiempoTranscurrido(post.date) }}</li>
              </ul>
          </div>	            
      </div>	
      <!--/Blog Banner-->

      
      
      <!--Blog Content-->
      <div class="blogdetail-content">
          <div class="container">
            <div class="share-postsection">
                    <div class="row">
                        <div class="col-lg-4">
                            <div 
                                class="sharelink"
                                @click="shareButtons(post.slug)"
                            >
                                <a 
                                    href="javasvript:void();" 
                                    class="share-img">
                                        <i class="fas fa-light fa-share-nodes"></i>
                                </a>
                                <a 
                                    href="javasvript:void();" 
                                    class="share-text"
                                >Compartir</a>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div v-if="post.type = 'news'" class="tag-list">
                                <h4>
                                    <b-badge><i class="far fa-newspaper"></i> Noticias</b-badge>
                                </h4>
                            </div>
                            <div v-else-if="post.type = 'interviews'" class="tag-list">
                                <h4>
                                    <b-badge><i class="far fa-comments"></i> Entrevistas</b-badge>
                                </h4>
                            </div>
                            <div v-else-if="post.type = 'videos'" class="tag-list">
                                <h4>
                                    <b-badge><i class="fas fa-play"></i> Videos</b-badge>
                                </h4>
                            </div>						
                    </div>					 
                </div>
            </div>

             <b-overlay :show="show" rounded="sm">
              <div v-html="post.content"></div>
             </b-overlay>
             <hr v-if="post.cta_text" />
             <div 
                class="row text-center justify-content-center"
                v-if="post.cta_text"
            >
                        <div 
                            v-if="post.cta_link == 'crm'"
                            v-html="post.cta_text"
                        ></div>
                        <a 
                            :href="post.cta_link"
                            class="btn btn-primary rounded-pill"
                            target="_blank"
                            style="width: 200px;"
                            v-else
                        >{{ post.cta_text }}</a>		
             </div>
             <hr v-if="imgs[0]" />
             <div 
				class="card gallery-section mt-4"
                v-if="imgs[0]"
			 >
             <div class="gallery-content">
             <div class="row">
                <div 
                    class="col-lg-3 col-md-3 col-sm-3 p-4" 
                    v-for="(src, index) in imgs" 
                    :key="index" 
                    @click="showImg(index)"
                >
                    <div class="gallery-widget">
                        <a href="javascript:void()" data-fancybox="gallery1">
                            <img 
                                :src="src" 
                                class="img-fluid"
                                alt="Image"
                            />
                        </a>
                    </div>
                </div>
                <vue-easy-lightbox 
                    :visible="visible" 
                    :imgs="imgs" 
                    :index="index" 
                    @hide="handleHide"
                ></vue-easy-lightbox>
             </div>
             </div>
             </div>

              <hr/>
              <div class="blogdetails-pagination">
                <ul>
                    <li v-if="previousPost">
                        <a 
                            :href="'/actualidad/' + previousPost.slug" 
                            class="prev-link"
                        >
                            <i class="fas fa-regular fa-arrow-left"></i> Publicación Anterior
                        </a>
                        <a :href="'/actualidad/' + previousPost.slug">
                            <h3>{{ previousPost.title }}</h3>
                        </a>
                    </li>
                    <li v-else>
                        <span class="prev-link disabled">
                            <i class="fas fa-regular fa-arrow-left"></i> Publicación Anterior
                        </span>
                        <span>
                            <h3>No hay publicaciones anteriores</h3>
                        </span>
                    </li>
                    <li v-if="nextPost">
                        <a 
                            :href="'/actualidad/' + nextPost.slug" 
                            class="next-link"
                        >
                            Siguiente Publicación <i class="fas fa-regular fa-arrow-right"></i>
                        </a>
                        <a 
                            :href="'/actualidad/' + nextPost.slug"
                        >
                            <h3>{{ nextPost.title }}</h3>
                        </a>
                    </li>
                    <li v-else>
                        <span class="next-link disabled">
                            Siguiente Publicación <i class="fas fa-regular fa-arrow-right"></i>
                        </span>
                        <span>
                            <h3>No hay publicaciones siguientes</h3>
                        </span>
                    </li>						
                </ul>				
            </div>

          </div>		
      </div>   
      <!--/Blog Content-->
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            post: [],
            posts: [],
            coverImage: require('../../../../assets/img/blog/blogbanner.jpg'),
            show: true,
            visible: false,
            index: 0, // default: 0
			imgs: [],
			img: [
				{
					src: "https://picsum.photos/785/501",
				},
				{
					src: "https://picsum.photos/785/502",
				},
			],
        }
    },
    mounted() {
        this.fetchPost(); // Llama a fetchCategories cuando el componente se monta
    },
    methods: {
        async fetchPost() {
            try {
                const response = await axios.get(
                    process.env.VUE_APP_API_DIRECTORY + 
                    "publication-by-slug/" + 
                    this.$route.params.slug);
                // Actualiza IndexDestination con los datos obtenidos
                this.post = response.data;
                this.imgs = this.post.uploads
                		.filter(upload => upload.type === 'image')
                		.map(upload => upload.route);
                // Check if there is a cover upload
                const coverUpload = this.post.uploads.find(upload => upload.type === 'cover');
                if (coverUpload) {
                    this.coverImage = coverUpload.route;
                }

                this.fetchPosts();

            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchPosts() {
            this.show = true;
            try {
                const response = await axios.get(
                    process.env.VUE_APP_API_DIRECTORY + 
                    "publications?type="+this.post.type+"&order=created_at&by=desc&active=1"
                );
                this.show = false;
                this.posts = response.data.data; // Almacena todas las publicaciones
                
                // Encuentra la posición de la publicación actual en la lista de publicaciones
                const currentPostIndex = this.posts.findIndex(post => post.slug === this.$route.params.slug);
                if (currentPostIndex !== -1) {
                    // Encuentra la publicación anterior y la siguiente
                    this.post = this.posts[currentPostIndex];
                    if (currentPostIndex > 0) {
                        this.previousPost = this.posts[currentPostIndex - 1];
                    }
                    if (currentPostIndex < this.posts.length - 1) {
                        this.nextPost = this.posts[currentPostIndex + 1];
                    }
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
        async fetchPreviousPost() {
            // Navega a la publicación anterior si existe
            if (this.previousPost) {
                this.$router.push(`/actualidad/${this.previousPost.slug}`);
            }
        },
        async fetchNextPost() {
            // Navega a la siguiente publicación si existe
            if (this.nextPost) {
                this.$router.push(`/actualidad/${this.nextPost.slug}`);
            }
        },
        showImg(index) {
			this.index = index;
			this.visible = true;
		},
        handleHide() {
			this.visible = false;
		},
        tiempoTranscurrido(fecha) {
				const fechaActual = new Date();
				const fechaOcurrencia = new Date(fecha);

				// Calcula la diferencia en milisegundos
				const diferencia = fechaActual.getTime() - fechaOcurrencia.getTime();

				// Convierte la diferencia de milisegundos a segundos, minutos, horas, días, meses y años
				const segundosTranscurridos = Math.floor(diferencia / 1000);
				const minutosTranscurridos = Math.floor(segundosTranscurridos / 60);
				const horasTranscurridas = Math.floor(minutosTranscurridos / 60);
				const diasTranscurridos = Math.floor(horasTranscurridas / 24);
				const mesesTranscurridos = Math.floor(diasTranscurridos / 30);
				const añosTranscurridos = Math.floor(mesesTranscurridos / 12);

				if (añosTranscurridos > 0) {
					if (añosTranscurridos === 1) {
						return 'Hace un año';
					} else {
						return `Hace ${añosTranscurridos} años`;
					}
				} else if (mesesTranscurridos > 0) {
					if (mesesTranscurridos === 1) {
						return 'Hace un mes';
					} else {
						return `Hace ${mesesTranscurridos} meses`;
					}
				} else if (diasTranscurridos > 0) {
					if (diasTranscurridos === 1) {
						return 'Ayer';
					} else {
						return `Hace ${diasTranscurridos} días`;
					}
				} else if (horasTranscurridas > 0) {
					return `Hace ${horasTranscurridas} horas`;
				} else if (minutosTranscurridos > 0) {
					return `Hace ${minutosTranscurridos} minutos`;
				} else {
					return `Hace ${segundosTranscurridos} segundos`;
				}
			},
        shareButtons(url){

            let uri = process.env.VUE_APP_WEBSITE + '/actualidad/' + url
            this.$swal.fire({
                html: `
                    <br/><br/>
                    <a 
                        href="whatsapp://send?text=Estoy compartiendo esta publicación que encontré. ¡Espero que sea útil para ustedes! ${uri}" 
                        data-action="share/whatsapp/share"
                        style=" border: 2px solid #25D366;
                                padding: 10px 40px; 
                                background: #fff;
                                width: 300px;
                                border-radius: 25px;"
                    >Compartir por WhatsApp</a>
                    <br/><br/><br/>
                    
                    <a 
                        class="twitter-share-button"
                        style=" border: 2px solid #000;
                                padding: 10px 77px; 
                                background: #fff;
                                width: 300px;
                                border-radius: 25px;"
                        href="https://twitter.com/intent/tweet?text=Estoy%20compartiendo%20esta%20publicacion%20que%20encontré.%20¡Espero%20que%20sea%20útil%20para%20ustedes%21${uri}"
                    >Compartir por X</a>
                    <br/><br/><br/>
                    <div 
                        class="fb-share-button" 
                        data-href="${uri}" 
                        data-layout="" 
                        data-size=""
                >
                    <a 
                        target="_blank" 
                        href="https://www.facebook.com/sharer/sharer.php?u=${uri}e" 
                        class="fb-xfbml-parse-ignore"
                        style=" border: 2px solid #4267B2;
                            padding: 10px 43px; 
                            background: #fff;
                            width: 300px;
                            border-radius: 25px;"
                    >Compartir por Facebook</a>
                </div>
                <br/><br/>
            `,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: `
                <i class="fa fa-thumbs-up"></i> OK
            `
        });
        }
    }
}
</script>

<style>
/* Add styles for the banner image */
.blogbanner {
    /* Add default styles for the blog banner */
    height: 300px; /* Set the height of the banner */
    background-size: cover; /* Ensure the background image covers the entire banner */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Ensure the background image doesn't repeat */
}
</style>