<template>
    <!--About Content-->
		<section class="about-content">
		    <div class="container">
		        <div class="about-listee">
					<div class="about-img">
						<img src="../../../assets/img/about-img2.jpg" class="img-fluid" alt="">
					</div>	
					<div class="about-info">
                        
						<p>El sistema de Franquicias está destinado tanto a emprendedores que proyectan gerenciar su propio negocio, bajo el paraguas de una marca más o menos reconocida, y contando con la experiencia del Franquiciante, como para empresarios que quieren expandir su concepto de negocio. El Franchising se ha convertido en el sistema de expansión de negocios más seguro. El Franquiciante otorga al Franquiciado el derecho a utilizarlo bajo la marca de su titularidad, o sobre la cual tiene derechos de utilización y transmisión, en los términos del Contrato de Franquicia que firmarán a tal efecto. Asimismo, lo proveerá del conjunto de conocimientos técnicos y continua asistencia técnica o comercial, todo ello bajo contraprestaciones específicas a cargo del franquiciado (Canon o Fee de ingreso/Regalías o Royalty). Basado en el win and win donde todos ganan, el sistema protege tanto el capital que el emprendedor confía a la marca franquiciante, como el prestigio de ésta y el respeto a los procedimientos del sistema desarrollado por el Franquiciante.</p>
						
					</div>
			    </div>		
			</div>		
		</section>
		<!--/About Content-->
		
		
		<section class="howitworks">
		    <div class="container">
		      <h3>VENTAJAS DEL SISTEMA DE FRANQUICIAS</h3>
			  <div class="row">
			        <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-store"></i>
                            </h5>
							<p>El franquiciado puede disfrutar de los beneficios de tener su propio negocio, pero con el respaldo y la experiencia de una marca reconocida que posee éxito demostrado.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-clock"></i>
                            </h5>
							<p>Acota los plazos para el inicio de actividad, ya que no hay que destinar tiempo a desarrollar el proyecto.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-bullhorn"></i>
                            </h5>
							<p>Favorece el aprovechamiento de las múltiples economías de escala en relación a temas como publicidad, compras, estrategias de marketing, etc.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-arrow-trend-down"></i>
                            </h5>
							<p>Minimiza los riesgos empresariales.</p>
						</div>					  
				    </div>						
			  	</div>
                  <div class="row">
			        <div class="col-lg-3 col-md-4 d-flex">
				        <div 
                            class="howitwork-info"
                            style="min-width: 100%;"
                        >
						    <h5>
                                <i class="fas fa-solid fa-graduation-cap"></i>
                            </h5>
							<p>Capacitación.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-headset"></i>
                            </h5>
							<p>Asesoramiento y asistencia permanente del franquiciante.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-handshake-angle"></i>
                            </h5>
                            <p>Manual de operaciones, que describe en detalle cómo deben llevarse a cabo las actividades, facilitando la gestión del negocio y la toma de decisiones.</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>
                                <i class="fas fa-solid fa-bullseye"></i>
                            </h5>
							<p>Propicia mejores estrategias de marketing, comunicación y difusión de marca..</p>
						</div>					  
				    </div>						
			  	</div>
            </div>	
		</section>


        
		<section class="howitworks">
		    <div class="container">
                <div class="row">
			        <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
							<h6>LA FRANQUICIA</h6>
							<p>Para el franquiciado, es una oportunidad de realizar el sueño de ser dueño de un negocio, pero con gran parte del riesgo eliminado. En efecto, el franquiciado puede lanzar un nuevo negocio sin muchos de los dolores de crecimiento asociados. Alguien más ya ha cometido, y aprendido de los errores más importantes y obvios, ha pulido y perfeccionado un sistema que funciona de manera eficiente.</p>
                            <p>Las franquicias también brindan a los franquiciados el beneficio de un apoyo continuo. Mientras están en el negocio por sí mismos, nunca lo hacen solos. Significa poder beneficiarse del uso de un nombre destacado y de la fuerza colectiva de una gran cadena a la hora de comprar suministros y publicidad.</p>
                            <p>Desde el punto de vista de los consumidores, la franquicia ofrece familiaridad y coherencia. Los consumidores esperan que la pizza que compran en México sea idéntica a la que compraron en la misma franquicia en Nueva York. Lo mismo ocurre con las hamburguesas, los cortes de pelo y las habitaciones de hotel. Los consumidores no solo se sienten tranquilos por esta confiabilidad, sino que han llegado a exigirla y, en igualdad de condiciones, lo recompensarán con su fidelidad.</p>
						</div>					  
				    </div>
                    <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
							<h6>¿CÓMO PUEDO FRANQUICIAR MI NEGOCIO?</h6>
							<p>El primer paso para los que desean franquiciar su negocio, es considerar y evaluar ciertos aspectos, para determinar en principio si su concepto es franquiciable o no, entre ellos:</p>
                            <p>▸ Que sea un negocio probado.
                                ▸ Que sea un negocio rentable.
                                ▸ Que se pueda replicar.
                                ▸ Que exista una demanda comprobada en el mercado por los productos o servicios que ofrece.
                                ▸ Que tenga algún elemento diferenciador o alguna ventaja competitiva importante.
                            </p>
                            <p>Desarrollo de un proyecto de franquicia</p>
                            <p>Franquiciar un negocio puede ser el proyecto de crecimiento y expansión más trascendental de una empresa. De ahí la importancia de realizarlo de manera profesional, basado en análisis, investigaciones y planificación. Contar con la asesoría de Consultoras especializadas puede resultar de gran utilidad.</p>
						</div>					  
				    </div>
                    <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
							<h6>ELEMENTOS PARA SOLICITAR AL FRANQUICIANTE</h6>
							<ul class="franchising__list || js-active">

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_2">Descripción del sector o actividad del negocio.</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_3">Filosofía y concepto del negocio.</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_4">Perfil del consumidor. Perspectivas del mercado según el franquiciante.</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_5">Titularidad o derecho de uso de la marca con que opera la franquicia.</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_6">Información económica-financiera sobre la evolución de 2 años de unidades similares a la ofrecida, en franquicias que hayan operado un tiempo suficiente en el país o extranjero.</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_7">Quién o quiénes son su competencia. Los valores diferenciales e innovadores de la marca</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_8">Datos de identificación de la empresa: domicilio y razón social, capital social y distribución del mismo. Quiénes son los dueños o los accionistas y sus porcentajes. Experiencia de la empresa y principales etapas de su evolución, su estructura entre otras. Constancia de registro de marca o autorización de uso de la misma a tal fin (si la franquiciante no es titular).</span></li>

<li class="franchisng__list__item franchising__text franchising__text--medium">
▸ <span class="lang-franchise_request_9">Sobre la estructura de la red y su organización:</span><br>
        – <span class="lang-franchise_request_10">Cantidad y distribución de las franquicias</span><br>
        – <span class="lang-franchise_request_11">Distribución de locales propios</span><br>
        – <span class="lang-franchise_request_12">Cantidad de Franquicias rescindidas - motivos</span><br>
        – <span class="lang-franchise_request_13">¿Estas asociado a la Asociación de Franquicias?</span>
</li>

<li class="franchisng__list__item franchising__text franchising__text--medium">▸ <span class="lang-franchise_request_14">Contrato  de Franquicias</span></li>
</ul>
                        </div>					  
				    </div>						
			  	</div>
            </div>	
			<br/><br/>
		</section>


		<section class="cta-section">
		    <div class="container">
			    <div class="row align-items-center">
				     <div class="col-lg-7">
					      <div class="cta-content">
							<h3>CIRCULAR DE OFERTA   <br>
								<span>DE FRANQUICIA</span>							     
							  </h3>
							  <p>Conoce la importancia y el uso del documento más importante junto al Contrato</p>
							  <div class="cta-btn">
							     <a
								 	href="/flipbook/ley_de_franquicias.docx"
									target="_blank"
								 	class="btn-primary postad-btn"
								 >
								 	<i class="fas fa-file-download"></i> Descargar Ahora
							  </a>						  
							  </div>
						  </div>
					 </div>
				     <div class="col-lg-5">
					       <div class="cta-img about-img">
						        <img src="../../../assets/img/cta-img2.jpg" class="img-fluid" alt="CTA">
						   </div>
					 </div>
				</div>
			</div>
		</section>



        
		<section class="howitworks">
		    <div class="container">
			  <br/><br/>
		      <h5>Para franquiciar nuestro negocio, podemos destacar estas etapas:</h5>
			  <div class="row">
			        <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>01</h5>
							<h6>PLANIFICACIÓN ESTRATÉGICA</h6>
							<p class="franchising__text franchising__text--medium">
				<span class="lang-franchise_step_4">La Planificación Estratégica, debe realizarse de manera personalizada y adaptada a cada empresa. Puntos a tener en cuenta en esta etapa:</span>
			</p>

			<ul class="franchising__list">

				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_5">* Diagnóstico de franquiciabilidad del negocio.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_6">* Análisis del concepto de negocio.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_7">* Características de la unidad modelo.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_8">* Evaluación del mercado de clientes del negocio.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_9">* Evaluación del mercado de las franquicias.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_10">* Tipos de franquicias a ofrecer.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_11">* Políticas de territorialidad.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_12">* Perfil del franquiciado.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_13">* Elaboración de programas de capacitación y entrenamiento.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_14">* Elaboración de programas de soporte y asistencia técnica.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_15">* Estructura organizacional de la empresa franquiciante.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_16">* Aspectos legales.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_17">* Dictamen de la protección marcaria y de los elementos confidenciales.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_18">* Requerimientos de inversión inicial.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_19">* Fuentes de Ingreso.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_20">* Evaluación financiera del proyecto.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_21">* Evaluación financiera del negocio franquiciado.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_22">* Proyecciones de expansión de la franquicia.</span></li>
			</ul>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>02</h5>
							<h6>INSTRUMENTACION DEL PROYECTO</h6>
							<p class="franchising__text franchising__text--medium">
				<span class="lang-franchise_step_25">En esta etapa, desarrollaremos:</span>
			</p>

			<ul class="franchising__list">
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_26">* Los Manuales Operativos</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_27">* Los Programas de Asistencia Técnica (de Apertura, de llave en mano, de Capacitación, de Asistencia Continua, de Supervisión, entre otros).</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_28">* El Contrato de Franquicia.</span></li>
			</ul>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>03</h5>
							<h6>COMERCIALIZACION DE LA FRANQUICIA</h6>
							<p class="franchising__text franchising__text--medium">
				<span class="lang-franchise_step_32">En esta instancia, debemos determinar las estrategias y herramientas que nos ayudarán en la promoción y generación de potenciales franquiciados, identificando las formas y mensajes adecuados, por medio de los cuales podamos llegar al inversionista que cumpla con el perfil indicado. Debemos crear la identidad corporativa, planificar y desarrollar las estrategias de comunicación y prensa en aquellos eventos y medios especializados, que nos permitan llegar al target de nuestra franquicia, al mejor costo-beneficio, entre ellos:</span>
			</p>

			<ul class="franchising__list">
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_33">* Revistas especializadas</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_34">* Ferias de franquicias</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_35">* Ferias empresariales</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_36">* Ferias de nuestra industria o sector</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_37">* Portales de internet especializados en franquicias</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_38">* La Asociación de Franquicias de tu país</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_39">* Publireportajes en periódicos y revistas</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_40">* Publicidad directa con el apoyo de bases de datos.</span></li>
			</ul>

			<p class="franchising__text">
			<span class="lang-franchise_step_41">Asimismo, vamos a requerir de ciertas herramientas fundamentales, como:</span>
			</p>

			<ul class="franchising__list">
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_42">* El brochure o folleto de la franquicia.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_43">* La presentación de la franquicia.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_44">* El cuestionario o solicitud de franquicia.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_45">* Videos promocionales.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_46">* Página web del negocio y de la franquicia.</span></li>
				<li class="franchisng__list__item franchising__text franchising__text--medium"><span class="lang-franchise_step_47">* Posicionamiento web. Desarrollo de redes sociales.</span></li>
			</ul>
						</div>					  
				    </div>		
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>04</h5>
							<h6>GESTIÓN DE LA RED DE FRANQUICIAS</h6>
							<p>La expansión de la red de franquicias es un elemento importantísimo, no lo es menos su gestión. Una vez que tu empresa se expande a través de este sistema, debes asegurarte que los franquiciados, cumplan con los estándares que has establecido en tus manuales operativos, realizando las auditorías necesarias para tal fin. De la correcta gestión que realices de tu red, dependerá el éxito de tu franquicia, lograrás una mejor rentabilidad, fortalecerás la imagen y el posicionamiento de tu marca.</p>
						</div>					  
				    </div>						
			  	</div>
            </div>	
		</section>

</template>