<template>

    <header class="header header-three">
        <navbartwoHeader></navbartwoHeader>
        <div class="container">
            <nav class="navbar navbar-expand-lg header-nav" 
                   >
                <div class="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);">
                        <span class="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </a>
                    <router-link to="/" class="navbar-brand logo">
                        <img src="../assets/img/logo.svg" class="img-fluid" alt="Logo"  style="width:240px;">
                    </router-link>
                    <router-link to="/" class="navbar-brand logo-small">
							<img src="../assets/img/logo.png" class="img-fluid" alt="Logo"  style="width:240px;">
                    </router-link>
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link to="/" class="menu-logo">
                            <img src="../assets/img/logo.svg" class="img-fluid" alt="Logo"  style="width:240px;">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
                    </div>
                    <ul class="navbar-nav main-nav my-2 my-lg-0">

                        <li v-if="isLoggedIn" class="d-sm-block d-md-none">
                            <router-link to="/add-listing">
                                <i class="fa-solid fa-plus"></i> Nueva Ficha
                            </router-link>
                        </li>
                        <li v-if="isLoggedIn" class="d-sm-block d-md-none">
                            <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown" aria-expanded="false">
                                <img 
                                    :src="userAvatar" 
                                    alt=""
                                >
                                <span>{{ userData }}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <router-link class="dropdown-item" to="/dashboard">Mi Cuenta</router-link>
                                <router-link class="dropdown-item" to="/profile">Configuraciones</router-link>
                                <router-link class="dropdown-item" to="/login">Cerrar Sesión</router-link>
                            </div>
                        </li>	
                        <li v-if="!isLoggedIn" class="d-sm-block d-md-none">
                            <router-link 
                                to="/signup"
                            >
                                <i class="fa-solid fa-plus"></i> <strong>Crea una cuenta</strong>
                            </router-link>
                        </li>	
                        <li v-if="!isLoggedIn" class="d-sm-block d-md-none">
                            <router-link 
                                to="/login"
                            >
                                <i class="fa-solid fa-plus"></i> Inciar Sesión
                            </router-link>
                        </li>
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0)">Franquiciar <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'about' ? 'active' : 'notactive'"><router-link to="/about">¿Qué es Franquiciar?</router-link></li>
                                <li :class="currentPath == 'prpricingofile' ? 'active' : 'notactive'"><router-link to="/pricing">Registra tu Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/howitworks">Tu Asesor</router-link>
                        </li>
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0)">Franchising <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'the-franchise' ? 'active' : 'notactive'"><router-link to="/the-franchise">La Franquicia</router-link></li>
                                <li :class="currentPath == 'association-franchise' ? 'active' : 'notactive'"><router-link to="/association-franchise">Asociaciones de Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li 
                            class="has-submenu"
                            :class=" BlogMenu ? 'active' : 'notactive'"
                            >
                            <a  href="javascript:void(0)">Actualidad <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li 
                                    :class="currentPath == 'news' ? 'active' : 'notactive'"
                                >
                                    <a href="/news">Noticias</a>
                                </li>
                                <li 
                                    :class="currentPath == 'interviews' ? 'active' : 'notactive'"
                                >
                                    <a href="/interviews">Entrevistas</a>
                                </li>
                                <li 
                                    :class="currentPath == 'videos' ? 'active' : 'notactive'"
                                >
                                    <a href="/videos">Videos</a>
                                </li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/contact">Contacto</router-link>
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center block-e">
                    <div v-if="!isLoggedIn" class="cta-btn">
                        <router-link to="/login" class="btn">Franquicias /</router-link>
                        <router-link to="/signup" class="btn ms-1"> Inversionistas</router-link>
                    </div>
                    <div 
                        class="d-none d-lg-block"
                        v-else
                    >
                        <a 
                            href="#" 
                            class="dropdown-toggle profile-userlink" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <img 
                                :src="userAvatar" 
                               
                            >
                            <span>{{ userData }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <router-link class="dropdown-item" to="/dashboard">Mi Cuenta</router-link>
                            <router-link class="dropdown-item" to="/profile">Configuraciones</router-link>
                            <router-link class="dropdown-item" to="/login">Cerrar Sesión</router-link>
                        </div>					
                    </div>
                </div>
            </nav>
        </div>
</header>
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: false,
            userData: '',
            userAvatar: '',
            idioms: ["[ES] - Español", "[EN] - Ingles", "[PT] - Portugues"],
            countries: []
        }
    },
    computed:{
            currentPath() {
                return this.$route.name
            },
            ListingsMenu() {
                return this.$route.name == 'listing-grid' || this.$route.name == 'listing-grid-sidebar' || this.$route.name == 'listing-list-sidebar' || this.$route.name == 'listingmap-list'  || this.$route.name == 'listingmap-grid';
            },
            PagesMenu() {
                return this.$route.name == 'about' || this.$route.name == 'service-details' || this.$route.name == 'pricing' || this.$route.name == 'faq' || this.$route.name == 'gallery' || this.$route.name == 'categories'
                || this.$route.name == 'howitworks' || this.$route.name == 'terms-condition' || this.$route.name == 'privacy-policy' || this.$route.name == 'error-404' || this.$route.name == 'error-500';
            },
            UserMenu() {
                return this.$route.name == 'dashboard' || this.$route.name == 'profile' || this.$route.name == 'my-listing' || this.$route.name == 'bookmarks' || this.$route.name == 'messages' || this.$route.name == 'reviews' || this.$route.name == 'add-listing';
            },
            BlogMenu() {
                return this.$route.name == 'blog-list' || this.$route.name == 'blog-grid' || this.$route.name == 'blog-details' || this.$route.name == 'blog-list-sidebar' || this.$route.name == 'blog-grid-sidebar';
            }
        },
        mounted() {

            if (localStorage.getItem('tokenSession')) {
                this.isLoggedIn = true;
            }
            this.userData = localStorage.getItem('userName')
            this.userAvatar = localStorage.getItem('userAvatar')

            $(window).scroll(function(){
	        var sticky = $('.header'),
	        scroll = $(window).scrollTop();
	            if (scroll >= 50) sticky.addClass('fixed');
	            else sticky.removeClass('fixed');
	        });

            $('body').append('<div class="sidebar-overlay"></div>');
            $(document).on('click', '#mobile_btn', function () {
                $('main-wrapper').toggleClass('slide-nav');
                $('.sidebar-overlay').toggleClass('opened');
                $('html').addClass('menu-opened');
                return false;
            });

            $(document).on('click', '.sidebar-overlay', function () {
                $('html').removeClass('menu-opened');
                $(this).removeClass('opened');
                $('main-wrapper').removeClass('slide-nav');
            });

            $(document).on('click', '#menu_close', function () {
                $('html').removeClass('menu-opened');
                $('.sidebar-overlay').removeClass('opened');
                $('main-wrapper').removeClass('slide-nav');
            });
        },
}
</script>
