<template>
    <!-- Footer -->
		<footer class="footer">
		    <div class="container">
			   	<div class="stay-tuned">
			        <h3 style="color: #0D233E;">Conéctate con Oportunidades Exclusivas</h3>
					<p style="color: #0D233E;">Descubre las últimas tendencias empresariales, consejos de expertos y oportunidades únicas directamente en tu bandeja de entrada. </p>
			        <router-link to="/signup" class="btn btn-primary rounded-pill">REGISTRATE GRATIS AHORA</router-link>				
				</div>			
			</div>
		
			<!-- Footer Top -->
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget">
								<div class="footer-logo">
									<router-link to="#"><img src="../../assets/img/footerlogo.svg" alt="logo"></router-link>
								</div>
								<div class="footer-content">

									<p>DESTACÁ TU MARCA EN LA 1° MULTIPLATAFORMA
DE FRANQUICIAS DE LATINOAMERICA  </p>

								</div>
								<div class="social-icon">
									<ul>
										<!--<li>
											<router-link to="#" target="_blank"><i class="fab fa-facebook-f"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-twitter"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-instagram"></i></router-link>
										</li> -->
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-linkedin-in"></i></router-link>
										</li>
									</ul>
                                </div>								
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Nosotros</h2>
								<ul>
									<li>
										<router-link 
											to="/about"
										>¿Qué es Franquiciar?</router-link>
									</li>
									<li>
										<router-link 
											to="/howitworks"
										>Tu Asesor</router-link>
									</li>
									<li>
										<router-link 
											to="/pricing"
										>Registra tu Franquicia</router-link>
									</li>
									<li>
										<router-link 
											to="/contact"
										>Contacto</router-link>
									</li>									
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Actualidad</h2>
								<ul>
									<li>
										<a href="/news">Noticias</a>
									</li>
									<li>
										<a href="/interviews">Entrevistas</a>
									</li>
									<li>
										<a href="/videos">Videos</a>
									</li>							
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Categorías</h2>
								<ul>
									<li
										v-for="item in IndexDestination"
										:key="item.id"
									>
										<a 
											:href="'/listing?category='+item.id" 
										>{{ item.name }}</a>
									</li>
									<li>
										<a href="/categories">Ver todos</a>
									</li>									
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-3 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget">
								<h2 class="footer-title">Contacto</h2>
								<div class="footer-contact-info">
										<div class="footer-address">
											<img src="../../assets/img/call-calling.svg" alt="Callus">
											<p><span>Teléfono</span> <br> +1 (786) 408-935	 </p>
										</div>
										<div class="footer-address">
											<img src="../../assets/img/sms-tracking.svg" alt="Callus">
											<p><span>E-mail</span> <br> info@franquiciar.com	 </p>
										</div>							
								</div>																
							</div>
							<!-- /Footer Widget -->							
						</div>
					</div>
					
					<!-- Footer Counter Section-->
					<div class="footercount">
						    <div class="row">
							         <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>Comunidad Activa</p>
											<p class="visitors-value">5.532 Usuarios</p>										
										</div>									 
									 </div>
									  <div class="col-lg-3 col-md-3">
									      <div class="vistors-details">
										    <p>Presencia en</p>
											<p class="visitors-value">13 Países</p>										
										</div>									 
									 </div> 
									 <div class="col-lg-3 col-md-3">
									     <div class="vistors-details">
										    <p>Diversificación Global</p>
											<p class="visitors-value">15 Categorías</p>										
										</div>									 
									 </div>
									  <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>Descarga nuestra app</p>
											<ul class="d-flex">
												<li>
													<a href="javascript:void(0)">
														<img 
															class="img-fluid" 
															style="width: 100px;"
															src="../../assets/img/play.png" 
															alt="amex"
														>
													</a>
												</li>
												<li>
													<a href="javascript:void(0)">
														<img 
															class="img-fluid" 
															style="width: 100px;"
															src="../../assets/img/app_store.png" 
															alt="visa"
														>
													</a>
												</li>
											</ul>										   								
										</div>									 
									 </div>
							</div>
					</div>			
			        <!-- /Footer  Counter Section-->	
					
				</div>
			</div>
			<!-- /Footer Top -->
						
			<!-- Footer Bottom -->
			<div class="footer-bottom">
				<div class="container">
					<!-- Copyright -->
					<div class="copyright">
						<div class="row">
							<div class="col-md-6">
								<div class="copyright-text">
									<p class="mb-0">Todos los derechos reservados © {{ new Date().getFullYear() }} - Franquiciar.</p>          
								</div>
							</div>
							<div class="col-md-6">
								<!-- Copyright Menu -->
								<div class="copyright-menu">
									<ul class="policy-menu">
										<li>
											<router-link to="/privacy-policy">Politicas de Privacidad </router-link>
										</li>
										<li>
											<router-link to="/terms-condition">Terminos y Condiciones</router-link>
										</li>										
									</ul>
								</div>
								<!-- /Copyright Menu -->
							</div>
						</div>
					</div>
					<!-- /Copyright -->
				</div>
			</div>
			<!-- /Footer Bottom -->
			
		</footer>
		<!-- /Footer -->
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            IndexDestination: [],
            countCategories: 0,
        };
    },
    mounted() {
        this.fetchCategories(); // Llama a fetchCategories cuando el componente se monta
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=3&type=0");
                // Actualiza IndexDestination con los datos obtenidos
                this.IndexDestination = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
    }
};
</script>