<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />
            
            <bloggridsidebar />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Listings - Blog",
            text: "Bienvenido",
            text1: "Blog",
            name: "/",
        }
    },
}
</script>