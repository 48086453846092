<template>
    <div class="main-wrapper">
        <layouts></layouts>

            <div class="page-wrapper">
                <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
                
                <pricingplan />

                <foot />

                <scroll />
            </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "¿POR QUÉ UNIRME A FRANQUICIAR?",
            text: "Bienvenido",
            text1: "¿POR QUÉ UNIRME A FRANQUICIAR?",
            cover: "pricing.jpg",
            name: "/",
        }
    },
}
</script>