<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                :title="title" 
                :name="name" 
                :text="text" 
                :text1="text1" 
                :cover="cover" 
            />
            
            <howworks />

            <howitpricing />

            <foot /> 

            <scroll />

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Tu Asesor",
            text: "Bienvenido",
            text1: "Tu Asesor",
            cover: "advisory.jpg",
            name: "/",
        }
    },
}
</script>