<template>
    <div class="main-wrapper">
        <navbartwoHeader></navbartwoHeader>
        <navbartwo></navbartwo>

        <div class="page-wrapper">
            
            <indexwedding />

            <indexcelebrate />
            
            <indexvendor />

            <indexpackage />

            <indexportfolio />

            <indextimeline />

            <weddingindexblog />

            <indexappointment />

            <weddingfooter />

            <scroll />
        </div>
    </div>
</template>
<script>
    export default {
        name: 'index-2'
    }
</script>