<template>
    <!-- Activities Banner -->
		<section class="section-blk activities-blk">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="title-blk justify-content-center text-center">
							<h2 class="m-0">Descarga nuestra app</h2>
						</div>	
						<div class="row">
				     <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
							<img src="../../../assets/img/icons/work1.jpg" class="img-fluid" alt="">
						  </div>
					 </div>
					  <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
							
						      <h5 class="mt-0">Con nuestra nueva aplicación, encontrar la franquicia perfecta es más fácil que nunca</h5>

							  <p>Explora más de 15 categorías empresariales, descubre conceptos innovadores y conecta con oportunidades que impulsarán tu éxito.

</p>
						      <img src="../../../assets/img/icons/work2.jpg" class="img-fluid" alt="">							  
						  </div>
					 </div>
					  <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
							<img src="../../../assets/img/icons/work3.jpg" class="img-fluid" alt="">
							</div>
					 </div>
				</div>	
					</div>			
				</div>
			</div>
		</section>
		<!-- /Activities Banner -->
</template>

<script>
import IndexActivity from '../../../assets/json/indexThreeActivity.json'
export default {
	data() {
		return {
			IndexActivity: IndexActivity
		}
	},
}
</script>