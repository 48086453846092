<template>
	<!-- Gallery section-->
	<div class="gallerypage-info work-section">
		<div class="container">
			<div class="gallery-content">
				<div class="row">
					<div class="col-lg-3 col-md-3 col-sm-3" v-for="(src, index) in imgs" :key="index" @click="() => showImg(index)">
						<div class="gallery-widget">
							<a href="javascript:void()" data-fancybox="gallery2">
								<img 
                                    :src="require(`../../../assets/img/gallery/gallery2/${src.src}`)" 
                                    class="img-fluid"
									alt="Image"
                                >
                                <h6
                                    class="text-center p-2"
                                >
                                    Federación Iberoamericana de Franquicias
                                </h6>
							</a>
						</div>
					</div>
					<vue-easy-lightbox 
						:visible="visible" 
						:imgs="img" :index="index" 
						@hide="handleHide"
					></vue-easy-lightbox>


				</div>
			</div>
		</div>
	</div>
	<!-- /Gallery section-->
</template>

<script>
export default {
	data() {
		return {
			visible: false,
			index: 0, // default: 0
			imgs: [
				{
					src: "galleryimage-1.jpg",
				},
				{
					src: "galleryimage-5.jpg"
				},
				{
					src: "galleryimage-6.jpg"
				},
				{
					src: "galleryimage-14.jpg"
				}
			],
			img: [
				{
					src: "https://picsum.photos/785/501",
				},
				{
					src: "https://picsum.photos/785/502",
				},
				{
					src: "https://picsum.photos/785/503",
				},
				{
					src: "https://picsum.photos/785/504",
				},
				{
					src: "https://picsum.photos/785/505",
				},
				{
					src: "https://picsum.photos/785/506",
				},
				{
					src: "https://picsum.photos/785/507",
				},
				{
					src: "https://picsum.photos/785/508",
				},
				{
					src: "https://picsum.photos/785/509",
				},
				{
					src: "https://picsum.photos/785/510",
				},
				{
					src: "https://picsum.photos/785/511",
				},
				{
					src: "https://picsum.photos/785/512",
				},
			],

		};
	},
	methods: {
		showImg(index) {
			this.index = index;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
	},

};
</script>