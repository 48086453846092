<template>
    <mainsidebar/> 
      <div 
        class="main-wrapper"
        style="background: #f9fafc;"  
      >
          <div class="page-wrapper"
            style="background: #f9fafc;"
          >
                  <!-- Dashboard Content -->
                  <div class="dashboard-content">		
			<div class="container">
				<dashboardmenu />
				<div class="profile-content">
				    <div class="messages-form">
					    <div class="card">
						    <div class="card-header">
                                <h2> {{ post.title }} </h2>	
							    <h4>Información Basica</h4>							
							</div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-lg-6 col-md-6">
									    <ul>
                                            <li>
                                                <label class="custom_check">
                                                    <input 
														type="checkbox" 
														name="wireless-internet"
														v-model="post.active"
														:value="post.active"
													>
                                                    <span class="checkmark"></span> Activa 
                                                </label>
                                            </li>
                                        </ul>
									</div>
                                    <div class="col-lg-6 col-md-6">
									    <ul>
                                            <li>
                                                <label class="custom_check">
                                                    <input 
														type="checkbox" 
														name="wireless-internet"
														v-model="post.outstanding"
														:value="post.outstanding"
													>
                                                    <span class="checkmark"></span> Publicación Destacada
                                                </label>
                                            </li>
                                        </ul>
									</div>										
								</div>
							    <div class="form-group">
								    <label class="col-form-label">Titulo de la Publicación <span>*</span></label>								    
									<input 
                                        type="text" 
                                        class="form-control pass-input" 
                                        placeholder="Escriba el nombre de la franquicia o negocio"
										v-model="post.title"
                                    >									   
								</div>
                                <div class="form-group">
								    <label class="col-form-label">Descripcion corta de la Publicación <span>*</span></label>								    
									<input 
                                        type="text" 
                                        class="form-control pass-input" 
                                        placeholder="Descripcion corta de la Publicación"
										v-model="post.shortdesc"
                                    >									   
								</div>
								<div class="form-group">
								    <label class="col-form-label">Contenido<span>*</span></label>
								   	
                                    <QuillEditor 
                                        theme="snow" 
                                        v-model:content="post.content"
                                        style="height: 500px;"
                                        contentType="html"
                                    />												
								</div>
								<div class="form-group">
                                    <label class="col-form-label label-heading">Seleccione una Categoria </label>
                                    <div class="row category-listing">
                                    <div v-for="(column, index) in categorizedColumns" :key="index" class="col-lg-4">
                                        <ul>
											<li 
												v-for="category in column" 
												:key="category.id"
											>
												<label class="custom_check">
												<input 
													type="checkbox" 
													:name="category.name"
													:value="category.id"
													v-model="categoryData"
													:checked="isChecked(category.id)"
												>
												<span class="checkmark"></span> {{ category.name }}
												</label>
											</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
								<div class="form-group formlast-input">
								    <label class="col-form-label label-heading">Enlace</label>								    
									<textarea 
                                        rows="2" 
                                        class="form-control tagline" 
                                        readonly
                                        placeholder=""
                                    >{{ post.slug }}</textarea>									   
								</div>
								<div class="form-group formlast-input">
								    <label class="col-form-label label-heading">Palabras Clave (Coleccion de palabras clave para posicionar esta ficha)</label>								    
									<textarea 
                                        rows="1" 
                                        class="form-control" 
                                        placeholder=""
                                        v-model="post.keywords"
                                    >{{ post.keyword }}</textarea>									   
								</div>					
							</div>   							
						</div>	
                        <div class="card">
						    <div class="card-header">
							    <h4>Información de contacto</h4>							
							</div>
                            <div class="card-body">
							    
								<div class="row">
									<div class="col-lg-6 col-md-6">
									    <div class="form-group formlast-input">
											<label class="col-form-label">Autor</label>
											<input 
                                                type="text" 
                                                class="form-control pass-input" 
                                                placeholder="Escriba el nombre del Autor"
												v-model="post.author"
                                                :value="post.author"
                                            >		
										</div>
									</div>											
								</div>
							</div>
						</div>	
                        <div class="card">
						    <div class="card-header">
							    <h4>Llamado a la acción</h4>							
							</div>
                            <div class="card-body">
								<div class="row">
									<div class="col-lg-6 col-md-6">
										<ul>
											<li>
												<label class="custom_check">
													<input 
														type="checkbox" 
														name="wireless-internet"
														v-model="checkCRM"
														@change="toggleCRM"
													>
													<span class="checkmark"></span> Formulario CRM 
												</label>
											</li>
										</ul>
									</div>									
								</div>
								<div class="row" v-if="!checkCRM">
									<div class="col-lg-6 col-md-6">
										<div class="form-group formlast-input formlast-input-inner">
											<label class="col-form-label">Texto del Boton</label>
											<input 
												type="text" 
												class="form-control pass-input" 
												placeholder="Escriba el texto del boton"
												v-model="post.cta_text"
											>
										</div>
									</div>	
									<div class="col-lg-6 col-md-6">
										<div class="form-group formlast-input">
											<label class="col-form-label">Enlace</label>
											<input 
												type="text" 
												class="form-control pass-input" 
												placeholder="Escriba el nombre del Autor"
												v-model="post.cta_link"
												@input="addHttp"
											>		
										</div>
									</div>										
								</div>
								<div class="form-group formlast-input mt-4" v-if="checkCRM">
									<label class="col-form-label">Codigo del CRM</label>							    
									<textarea 
										rows="2" 
										class="form-control tagline" 
										placeholder=""
										v-model="post.cta_text"
									>{{ post.cta_text }}</textarea>									   
								</div>
							</div>
						</div>					
                        <div class="card">
						    <div class="card-header">
							    <h4>Ubicaciones</h4>							
							</div>
                            <div class="card-body">
							    <div class="form-group">
								    <label class="col-form-label">Dirección</label>								    
									<input 
                                        type="text" 
                                        class="form-control pass-input" 
                                        placeholder="Escriba una dirección"
										v-model="post.address"
                                        :value="post.address"
                                    >									   
								</div>
								<b-form-group 
									label="Seleccione los paises en que la Franquicia tiene presencia" 
									label-for="tags-with-dropdown"
								>
									<b-form-tags id="tags-with-dropdown" v-model="value" no-outer-focus class="mb-2">
										<template v-slot="{ tags, disabled, addTag, removeTag }">
										<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
											<li v-for="tag in tags" :key="tag" class="list-inline-item">
											<b-form-tag
												@remove="removeTag(tag)"
												:title="tag"
												:disabled="disabled"
												variant="info"
											>{{ tag }}</b-form-tag>
											</li>
										</ul>

										</template>
									</b-form-tags>
								</b-form-group>							
							</div>
						</div>	

						<b-overlay :show="showSave" rounded="sm">
							<b-button 
								variant="primary" 
								type="submit"
								@click="updatePost()"
							>Guardar</b-button>		
                        </b-overlay>
                  		
						<b-overlay :show="showFile" rounded="sm">
                        <div class="card media-section mt-4">
						    <div class="card-header">
							    <h4>Documentos e Imagenes </h4>							
							</div>
                            <div class="card-body">
							    <div class="row">
									<div class="row">
										<div class="col-lg-6 col-md-6 featured-img2">
											<h6 class="Featured image">Portada</h6>
											<b-overlay :show="showCover" rounded="sm">
												<div class="galleryimg-upload">
													<!-- Renderizar las imágenes de la galería -->
													<div 
														v-for="(image, index) in post.uploads" 
														:key="index" 
														class="gallery-upload"
													>
														<div
															v-if="image.type == 'cover'"
														>
															<img 
																:src="image.route" 
																class="img-fluid"
																style="max-width: 200px; max-height: 200px;" 
																alt=""
															>
															<!-- Añadir un botón para eliminar la imagen -->
															<a 
																href="javascript:void(0)" 
																class="profile-img-del" 
																@click="deleteGalleryImage(image.id)">
																<i class="feather-trash-2"></i>
															</a>
														</div>
													</div>
												</div>
												</b-overlay>
												<!-- Añadir un botón para subir imágenes -->
												<div class="settings-upload-btn"
													v-if="!hasCoverImage"
												>
													<input type="file"  
														@change="uploadGalleryImage($event, 'cover')" 
														accept="image/*" 
														name="image" 
														class="hide-input image-upload" 
														id="file2"
													>
													<label for="file2" class="file-upload">Subir Imagen para Portada</label>
												</div>
										</div>
										<div class="col-lg-6 col-md-6 featured-img1">
											<h6 class="media-title">Foto Principal</h6>
												<b-overlay :show="showCover" rounded="sm">
												<div class="galleryimg-upload">
													<!-- Renderizar las imágenes de la galería -->
													<div 
														v-for="(image, index) in post.uploads" 
														:key="index" 
														class="gallery-upload"
													>
														<div
															v-if="image.type == 'logo'"
														>
															<img 
																:src="image.route" 
																class="img-fluid"
																style="max-width: 200px; max-height: 200px;" 
																alt=""
															>
															<!-- Añadir un botón para eliminar la imagen -->
															<a 
																href="javascript:void(0)" 
																class="profile-img-del" 
																@click="deleteGalleryImage(image.id)">
																<i class="feather-trash-2"></i>
															</a>
														</div>
													</div>
												</div>
												</b-overlay>
												<!-- Añadir un botón para subir imágenes -->
												<div class="settings-upload-btn"
													v-if="!hasCoverLogo"
												>
													<input type="file"  
														@change="uploadGalleryImage($event, 'logo')" 
														accept="image/*" 
														name="image" 
														class="hide-input image-upload" 
														id="file2"
													>
													<label for="file2" class="file-upload">Subir Imagen Principal</label>
												</div>
										</div>
										
									</div>										
								</div>
								<hr/>
                                <div class="gallery-media">
									<b-overlay :show="showImage" rounded="sm">
									<h6 class="media-title">Galeria de Imagenes</h6>
									<div class="galleryimg-upload">
										<!-- Renderizar las imágenes de la galería -->
										<div 
											v-for="(image, index) in post.uploads" 
											:key="index" 
											class="gallery-upload"
										>
											<div
												v-if="image.type == 'image'"
											>
												<img 
													:src="image.route" 
													class="img-fluid"
													style="max-width: 200px; max-height: 200px;" 
													alt=""
												>
												<!-- Añadir un botón para eliminar la imagen -->
												<a 
													href="javascript:void(0)" 
													class="profile-img-del" 
													@click="deleteGalleryImage(image.id)">
													<i class="feather-trash-2"></i>
												</a>
											</div>
										</div>
									</div>
									</b-overlay>
									<!-- Añadir un botón para subir imágenes -->
									<div class="settings-upload-btn">
										<input type="file"  @change="uploadGalleryImage($event, 'image')" accept="image/*" name="image" class="hide-input image-upload" id="file2">
										<label for="file2" class="file-upload">Subir Imagen para Galería</label>
									</div>
								</div>							
							</div>
						</div>	
						<template #overlay>
							<div class="text-center">
							<b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
							<p id="cancel-label">Para agregar documentos e imagenes primero configure una ficha</p>
							<b-button
								ref="cancel"
								variant="outline-danger"
								size="sm"
								aria-describedby="cancel-label"
								@click="show = false"
							>
								Cancel
							</b-button>
							</div>
						</template>
                        </b-overlay>				
					</div>			
				</div>
			</div>		
		</div>		
  
          
  
              <scroll />
          </div>
      </div>
  </template>
  
  
  <script>
  import Dashboard from '../../../assets/json/dashboard.json'
  import DashboardReview from '../../../assets/json/dashboardReview.json'
  import axios from 'axios'; // Importar Axios
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';

  export default {
    components: {
        QuillEditor
    },
      data() {
          return {
            categories: [],
			editData: {
                description: '',
                type: '',
                selection: '',
                icon: '',
                company_type: '',
            },
			itemValue: '',
        	cleanedValue: '',
            post: [],
			fileSelected: false,
        	search: '',
			checkCRM: false,
			showImage: false,
			showCover: false,
			showCof: false,
			showLogo: false,
			showSave: false,
			showFile: false,
        	value: [],
			categoryData: [],
            Dashboard: Dashboard,
              DashboardReview: DashboardReview,
              title: "Login",
              text: "Inicio",
              text1: "Login",
              name: "/",
          }
      },
      mounted() {
            // Llamar a la API para obtener la lista de compañías
            this.fetchCategories();
            this.fetchPost();
			this.fetchTags();
        },
	   computed: {
			categorizedColumns() {
				const columns = 3; // Número de columnas
				const categoriesCopy = [...this.categories];
				const categorized = Array.from({ length: columns }, (_, index) =>
					categoriesCopy.filter((_, i) => i % columns === index)
				);
				return categorized;
			},
			criteria() {
				return this.search.trim().toLowerCase()
			},
			hasCoverImage() {
				if (this.post && this.post.uploads) {
					return this.post.uploads.some(image => image.type === 'cover');
				}
				return false;
			},
			hasCoverLogo() {
				if (this.post && this.post.uploads) {
					return this.post.uploads.some(image => image.type === 'logo');
				}
				return false;
			},
	  },
      methods: {
        fetchCategories() {
			axios.get(process.env.VUE_APP_API_DIRECTORY+'categories?limit=300&type=1', {
				headers: {
					"Accept": "application/json",
					"Authorization": "Bearer "+process.env.VUE_APP_TOKEN
				}
			})
			.then(response => {
				this.categories = response.data; // Actualiza la propiedad categories con la respuesta de la API
			})
			.catch(error => {
				console.error('Error al obtener categorías:', error);
			});
		},


		addHttp() {
            // Verificar si el usuario ha proporcionado un valor
            if (this.post.cta_link && !/^https?:\/\//i.test(this.post.cta_link)) {
                // Agregar "http://" si no está presente
                this.post.cta_link = 'https://' + this.post.cta_link;
            }
        },

		toggleCRM() {
            if (this.checkCRM) {
                // Si el checkbox está marcado, oculta los campos de texto y limpia el enlace
                this.post.cta_link = 'crm';
            } else {
                // Si el checkbox está desmarcado, muestra los campos de texto y reinicia el enlace
                this.post.cta_link = '';
            }
        },
        
        fetchPost() {
				if(this.$route.params.idPost == 0){
					this.showFile = true
				}else{
					this.showFile = false
				}
                axios.get(process.env.VUE_APP_API_DIRECTORY+'publications/'+this.$route.params.idPost, {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                    }
                })
                .then(response => {
                	this.post = response.data; // Actualiza la propiedad categories con la respuesta de la API
					this.categoryData = this.post.categories.map(category => category.id);
					if(this.post.cta_link == 'crm'){
						this.checkCRM = true
						this.toggleCRM()
					}
					

				})
                .catch(error => {
                	console.error('Error al obtener fichas:', error);
                });
            },

		onOptionClick({ option, addTag }) {
			addTag(option)
			this.search = ''
		},

		isChecked(categoryId) {
			return this.categoryData.includes(categoryId);
		},

		fetchTags() {
			const config = {
				headers: {
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + process.env.VUE_APP_TOKEN
				}
			};

			axios.get(process.env.VUE_APP_API_DIRECTORY + "countries", config)
				.then(response => {
				// Actualizar las opciones con los datos de la respuesta
				this.options = response.data.data.map(country => country.name);
				})
				.catch(error => console.log('error', error));
		},

		getBlockTitle(type) {
			// Buscar el nombre del bloque en base al código del tipo
			const typeData = this.typeDatas.find(data => data.code === parseInt(type));
			return typeData ? typeData.name : `Contact Information ${type}`;
		},

	uploadGalleryImage(event, type) {
		if(type == 'cover'){
			this.showCover = true;
		}else if(type == 'logo'){
			this.showLogo = true;
		}else{
			this.showImage = true;
		}
		
		const formData = new FormData();
		formData.append('_method', 'patch');
		formData.append('fileType', type);
   		formData.append('file', event.target.files[0]); 
		formData.append('type', this.post.type);

		const headers = {
			'Content-Type': 'multipart/form-data',
			'Accept': 'application/json',
			"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
		};

		axios.post(process.env.VUE_APP_API_DIRECTORY + 'publications/' + this.$route.params.idPost, formData, { headers })
			.then(response => {
				this.fetchPost();
				console.log('Imagen subida exitosamente:', response.data);
				this.showImage = false;
				this.showCof = false;
				this.showLogo = false;
				this.showCover = false;
			})
			.catch(error => {
				console.error('Error al subir la imagen:', error);
				this.showImage = false;
				this.showCover = false;
				this.showCof = false;
				this.showLogo = false;
			});
	},

	// Método para eliminar archivos de la galería
	deleteGalleryImage(imageId) {
		axios.delete(process.env.VUE_APP_API_DIRECTORY + 'upload-management/delete-upload/' + imageId, {
			headers: {
				"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
			}
		})
		.then(response => {
			console.log('Imagen de la galería eliminada exitosamente:', response.data);
			this.fetchPost()
		})
		.catch(error => {
			console.error('Error al eliminar la imagen de la galería:', error);
		});
	},

	async updatePost() {
		let response;
		try {    
			this.showSave = true
			const formData = new FormData();
            const today = new Date();
			formData.append("content", this.post.content);
			formData.append("title", this.post.title);
            formData.append("date", today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
			if(this.post.active === true){
				formData.append("active", 1);
			}else{
				formData.append("active", 0);
			}
			if(this.post.outstanding === true){
				formData.append("outstanding", 1);
			}else{
				formData.append("outstanding", 0);
			}
			if(this.categoryData !== null){
				this.categoryData.forEach((value, index) => {
					formData.append("categories["+index+"]", value);
				});
			}
			formData.append("keywords", this.post.keywords);
            formData.append("shortdesc", this.post.shortdesc);
			formData.append("author", this.post.author); 
            formData.append("contentType", "text");
			formData.append("cta_text", this.post.cta_text); 
			formData.append("cta_link", this.post.cta_link); 

			if (this.$route.params.idPost == 0) {
                formData.append("type", this.$route.params.publicationType);
				response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "publications", formData, {
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/x-www-form-urlencoded",
						"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
					}
				});
			} else {
				formData.append("_method", "patch");
                formData.append("type", this.post.type);
				response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "publications/" + this.$route.params.idPost, formData, {
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/x-www-form-urlencoded",
						"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
					}
				});
			}
			if (response.status === 201) {
				this.showSave = false
				this.showFile = false
				if(this.$route.params.idPost == 0){

					this.$swal.fire({
						title: "Creado!",
						text: "La ficha de esta empresa ha sido creada exitosamente.",
						icon: "success",
					}).then(() => {
						// Redireccionar a la misma página con el ID del objeto creado
						const id = response.data.publication.id;
						console.log(id)
						const newRoute = this.$route.path.replace(/\/\d+\/news$/, `/${id}/${this.$route.params.publicationType}`);
						this.$router.push(newRoute);

					});

				}
			}else if (response.status === 200) {
				this.showSave = false
				this.$swal.fire({
					title: "¡Actualizado!",
					text: "La descripción de la empresa se ha actualizado exitosamente.",
					icon: "success",
				});
			} else {
				this.showSave = false
				console.error(
					`Error al actualizar la descripción de la empresa: ${response.statusText} (${response.status})`
				);
				this.$swal.fire({
					title: "Ocurrio un error!",
					text: `Error al actualizar la descripción de la empresa: ${response.statusText} (${response.status})`,
					icon: "warning",
				});
			}

			console.log(response.data); // Muestra la respuesta del servidor
		} catch (error) {
			console.error("Error al actualizar la descripción de la empresa:", error);
			this.$swal.fire({
					title: "Ocurrio un error!",
					text: "Error al actualizar la descripción de la empresa: "+error,
					icon: "warning",
				});
		}
	}

    },
  }
  </script>