<template>
    <!-- Pricing Plan Section -->
		<section class="pricingplan-section pricing-page">
		     	<div class="section-heading">
						<div class="container">				
							 <div class="row text-center">
									<h2><span>Pla</span>nes</h2>
									<p>DESTACÁ TU MARCA EN LA 1° MULTIPLATAFORMA <br/>
DE FRANQUICIAS DE LATINOAMERICA</p>
							 </div>									 
						</div>	 
				</div>
				<div class="container">
					<div class="row">
					    <div 
						class="col-lg-4 d-flex col-md-4" 
						v-for="item in IndexPricing" 
						:key="item.id"
						>
						    <div class="price-card flex-fill">
								<div class="price-head">
									<div class="price-level">
										<h6>{{ item.title }}</h6>
									</div>
									<h4>{{ item.price }}</h4>								
								</div>	
								<div class="price-body">
									<p>{{ item.content }}</p>
									<ul>
										<li class="active">{{ item.basic }}</li>
										<li class="active">{{ item.one }}</li>
										<li class="active">{{ item.days }}</li>
										<li class="inactive">{{ item.limit }}</li>
										<li class="inactive">{{ item.edit }}</li>
									</ul>
									<div>
										<router-link to="/login" class="btn viewdetails-btn">{{ item.view }}</router-link>
									</div>							
								</div>							
						    </div>
						</div>
						
					</div>
				</div>
		</section>
		<!-- /Pricing Plan Section -->

		<section 
			class="howitworks pb-5"
		>
		    <div class="container">
			  <br/><br/>
		      <h5>¿Por que unirme a Franquiciar?</h5>
			  <div class="row">
			        <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>01</h5>
							<h6>AHORRAR DINERO</h6>
							<p class="franchising__text franchising__text--medium">
								<span class="lang-franchise_step_4">
									Anunciá tu marca a bajos costos.
								</span>
							</p>

			
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>02</h5>
							<h6>AUTOGESTIONAR TU PERFIL</h6>
							<p class="franchising__text franchising__text--medium">
								<span class="lang-franchise_step_25">
									Gestiona la información de tu franquicia con tu usuario de empresa.
								</span>
							</p>
						</div>					  
				    </div>
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>03</h5>
							<h6>AHORRAR TIEMPO</h6>
							<p class="franchising__text franchising__text--medium">
								<span class="lang-franchise_step_32">
									Actualiza el perfil de tu franquicia desde cualquier dispositivo, las 24 hs., los 365 días.
								</span>
							</p>

						</div>					  
				    </div>		
                    <div class="col-lg-3 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>04</h5>
							<h6>VIRALIZAR TU MARCA</h6>
							<p>Integración y sincronización con redes sociales.</p>
						</div>					  
				    </div>						
			  	</div>
            </div>	
		</section>

</template>

<script>
import IndexPricing from '../../../assets/json/indexPricing.json'
export default {
	data() {
		return {
			IndexPricing: IndexPricing
		}
	},
}
</script>