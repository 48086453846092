<template>

  <b-modal 
  	v-if="imgs[0]"
  	id="modal-brochure" 
	ref="modal-brochure" 
	hide-footer
	hide-header
	v-model="modalShow"
	class="modal-fullscreen"
	size="xl"
	title="Ver Brochure"
  >
		<b-button 
			class="position-absolute top-0 end-0 mt-2 mr-5" 
			style="border: none; background-color: white;"
			v-b-modal.modal-brochure
		><i class="fas fa-times fa-2x"></i></b-button>
  		<pdfviewfranchise 
			:file="imgs[0]"
		/>

      <b-button 
	  	class="mt-3" 
		block 
		v-b-modal.modal-brochure 
	  >Cerrar</b-button>
  </b-modal>

  <b-modal 
  	id="modal-compare" 
	ref="modal-compare" 
	hide-footer
	hide-header
	class="modal-fullscreen"
	size="xl"
  >
  	<b-button 
        class="position-absolute top-0 end-0 mt-2 mr-5" 
		style="border: none; background-color: white;"
        v-b-modal.modal-compare
    >
		<i class="fas fa-times fa-2x"></i>
	</b-button>

	<br/><br/>
    <div class="row">
        <comparefranchise 
			initialCompanyId="309"
		/>
    </div>
    
	<b-button 
	  	class="mt-3" 
		block 
		v-b-modal.modal-compare 
	  >Cerrar</b-button>
  </b-modal>


  <b-modal 
  	id="modal-login" 
	ref="modal-login" 
	hide-footer
	hide-header
	class="modal-fullscreen"
  >
  	<b-button 
        class="position-absolute top-0 end-0 mt-2 mr-5" 
		style="border: none; background-color: white;"
        v-b-modal.modal-login
    ><i class="fas fa-times fa-2x"></i></b-button>

	<br/><br/>
    <div class="row">
		
		<loginmodal />
        
    </div>
    
	<b-button 
	  	class="mt-3" 
		block 
		v-b-modal.modal-login 
	  >Cerrar</b-button>
  </b-modal>


  <b-modal 
  	id="modal-crm" 
	ref="modal-crm" 
	hide-footer
	hide-header
	class="modal-fullscreen"
  >
  	<b-button 
        class="position-absolute top-0 end-0 mt-2 mr-5" 
		style="border: none; background-color: white;"
        v-b-modal.modal-crm
    ><i class="fas fa-times fa-2x"></i></b-button>

	<br/><br/>
    <div class="row">
		
		<img src="../../../assets/img/bitrix.png" class="img-fluid" alt="">
		
        
    </div>
    
	<b-button 
	  	class="mt-3" 
		block 
		v-b-modal.modal-crm 
	  >Cerrar</b-button>
  </b-modal>

	<!-- Detalles de la descripción de la sección -->
	<section class="details-description">
	  <div class="container"><b-overlay :show="show" rounded="sm">
		<div class="about-details">
			
		  <div class="about-headings">
			<div class="author-img">
			  <img 
				v-if="companyData.logo"
			  	:src="companyData.logo.route" 
				alt="logo"
			  >
			</div>
			<div class="authordetails">
			  <h5>{{ companyData.name }}</h5>
			  <div class="recommended-tabs-blk">
				<ul class="nav tabs-style gap-3" data-bs-tabs="tabs">
					<li
						v-if="companyData.categories"
					>
						
						<a 
							class="active" 
							v-for="(column, index) in companyData.categories" 
							:key="index" 
							aria-current="true" 
							data-bs-toggle="tab" 
							 @click="goToCategory(column.id)"
						>
							<span>
								<i
									:class="column.icon" 
									alt="Dreamstrip Recommended"
								></i>
							</span>
							{{ column.name }}
						</a>
						
					</li>
				</ul>
			  </div>
			</div>
		  </div>
		  <div 
		  	class="rate-details" 
			style="font-weight: 600;"
		  	v-if="companyData.data && companyData.data.length > 0"
		  >
			<range-price 
				:idCompany="companyData.id"
			></range-price>
			<p
				style="font-weight: 400;"
			>Inversión total</p>
		  </div>

		 
		</div> </b-overlay>
		<div class="descriptionlinks">
			<b-overlay :show="show" rounded="sm">
			<div class="row">
				<div class="col-lg-2">
					<a
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
						v-b-modal.modal-brochure
						v-if="imgs[0]"
					>
						<i class="feather-book-open"></i> Brochure
					</a>
					<a
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
						v-else
					>
						<i class="fas fa-file-signature"></i> Solicitar Brochure
					</a>
				</div>
				<div class="col-lg-2">
					<a 
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
						v-b-modal.modal-compare
					>
						<i class="fas fa-compress-alt"></i> Comparar 
					</a>
				</div>
				<div class="col-lg-2">
					<a 
						@click="shareButtons(companyData.link)"
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px; "
					>
						<i class="feather-share-2"></i> Compartir
					</a>
				</div>
				<div 
					class="col-lg-2"
					v-if="!confirmFavoriteCheck"
				>
					<a 
						v-if="isLoggedIn && isFavorite"
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
					>
						<i class="fas fa-heart" style="color: #ff4545;"></i> Guardado en Favoritos
					</a>
					<a 
						v-else-if="isLoggedIn"
						@click="confirmFavorite()"
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
					>
						<i class="feather-heart"></i> Favorito 
					</a>
					<a 
						v-else
						v-b-modal.modal-login
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
					>
						<i class="feather-heart"></i> Favorito
					</a>
				</div>
				<div 
					class="col-lg-2"
					v-else
				>
					<a 
						class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
						style="font-size: 14px;"
					>
						<i class="fas fa-heart" style="color: #ff4545;"></i> Guardado en Favoritos
					</a>
				</div>
				<div class="col-lg-4">
					<a 
						v-if="isLoggedIn"
						v-b-modal.modal-crm
						class="btn btn-block btn-primary  viewdetails2-btn" 
					>
						<i class="far fa-handshake"></i> Solicitar Reunión
					</a>
					<a 
						v-else
						v-b-modal.modal-login
						class="btn btn-block btn-primary  viewdetails2-btn" 
					>
						<i class="far fa-handshake"></i> Solicitar Reunión
					</a>
				</div>
			</div>
			</b-overlay>
		</div>
	  </div>
	</section>
	<!-- / Detalles de la descripción de la sección -->
  </template>
  <script>
import axios from 'axios';

  export default {
	props: ["companyData", "imgs", "show", "isLoggedIn", "isFavorite"],
	data() {
	  return {
		modalShow: false,
		confirmFavoriteCheck: false,
		idCompany: this.companyData.id,
		companiesList: [],
      	userName: "",
		userToken: "",
		userId: 0
	  };
	},
	mounted() {
		console.log(this.isFavorite)
	},
	methods: {
		hideModal() {
			this.$refs['modal-brochure'].hide()
		},
		goToCategory(category){
			this.$router.push('/listing?category=' + category);
		},
		confirmFavorite() {
			this.confirmFavorite = true
            this.userId = localStorage.getItem("userId");
            this.userToken = localStorage.getItem("tokenSession");
			const axios = require('axios');
			const data = new FormData();
			data.append("model", "company");
			data.append("model_id", this.companyData.id);
			data.append("score", "1");

			const config = {
				method: 'post',
				url: process.env.VUE_APP_API_DIRECTORY + 'rating-management/set-rating',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + this.userToken
				},
				data: data
			};

			axios(config)
			.then(response => {
				console.log(JSON.stringify(response.data));
				// Mostrar el mensaje de confirmación
				this.confirmFavoriteCheck = true
				this.$swal.fire({
					text: "Esta franquicia ha sido marcada como tu favorita.",
					imageUrl: "https://cdn.dribbble.com/users/2207/screenshots/2254886/heart.gif",
					imageWidth: 400,
					imageHeight: 300,
					imageAlt: "Custom image"
				});
				
			})
			.catch(error => {
				console.log(error);
				// Manejar errores
				this.$swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Hubo un problema al marcar esta franquicia como favorita. Por favor, inténtalo de nuevo más tarde.'
				});
			});
		},



		shareButtons(url){

			let uri = process.env.VUE_APP_WEBSITE + '/franquicia/' + url
			this.$swal.fire({
				html: `
					<br/><br/>
					<a 
						href="whatsapp://send?text=Compartiendo esta opción de franquicia que encontré. ¡Espero que sea útil para ustedes! ${uri}" 
						data-action="share/whatsapp/share"
						style=" border: 2px solid #25D366;
								padding: 10px 40px; 
								background: #fff;
								width: 300px;
								border-radius: 25px;"
					>Compartir por WhatsApp</a>
					<br/><br/><br/>
					
					<a 
						class="twitter-share-button"
						style=" border: 2px solid #000;
								padding: 10px 77px; 
								background: #fff;
								width: 300px;
								border-radius: 25px;"
						href="https://twitter.com/intent/tweet?text=Compartiendo%20esta%20opción%20de%20franquicia%20que%20encontré.%20¡Espero%20que%20sea%20útil%20para%20ustedes%21${uri}"
					>Compartir por X</a>
					<br/><br/><br/>
					<div 
						class="fb-share-button" 
						data-href="${uri}" 
						data-layout="" 
						data-size=""
					>
						<a 
							target="_blank" 
							href="https://www.facebook.com/sharer/sharer.php?u=${uri}e" 
							class="fb-xfbml-parse-ignore"
							style=" border: 2px solid #4267B2;
								padding: 10px 43px; 
								background: #fff;
								width: 300px;
								border-radius: 25px;"
						>Compartir por Facebook</a>
					</div>
					<br/><br/>
				`,
				showCloseButton: false,
				showCancelButton: false,
				focusConfirm: false,
				confirmButtonText: `
					<i class="fa fa-thumbs-up"></i> OK
				`
			});
		}
    },
  };
  </script>
  <style>
    .modal-fullscreen {
    display: flex;
    z-index: 1000000 !important;
	}
	.modal-dialog {
		display: flex;
		z-index: 1900 !important;
	}
	.flipbook {
	width: 60vw;
	height: calc(100vh - 50px - 40px);
	}
	.flipbook .bounding-box {
	box-shadow: 0 0 20px #000;
	}
	.disabled{
		color: #dbdbdb;
		border-color: #dbdbdb;
	}
  </style>
  