<template>
    <!-- Space Section -->
    <section class="adventure-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                    <div class="featuring-img">
                        <img src="../../../assets/img/feature.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-md-6 aos" data-aos="fade-up">
                    <div class="adventure-info">
                        <div class="section-heading heading-five aos" data-aos="fade-up">
                            <h6>Why Choose Us</h6>
                            <h2>Its Time For New Adventures Escapes thrills & experiences</h2>
                        </div>
                        <div class="advent-info">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                architecto beatae vitae dicta sunt explicabo.</p>
                        </div>
                        <router-link to="/service-details" class="btn btn-grey">
                            Get Started <i class="feather-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Space Section -->

    <!-- City Section -->
    <section class="gallery-section-five">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-heading heading-five aos" data-aos="fade-up">
                        <h2>Featured Cities</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 aos" data-aos="fade-up">
                    <div class="gal-wrap">
                        <img src="../../../assets/img/gallery/gallery-1.jpg" class="img-fluid" alt="img">
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 aos" data-aos="fade-up">
                    <div class="gal-wrap">
                        <img src="../../../assets/img/gallery/gallery-2.jpg" class="img-fluid" alt="img">
                    </div>
                    <div class="gal-wrap">
                        <img src="../../../assets/img/gallery/gallery-3.jpg" class="img-fluid" alt="img">
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 aos" data-aos="fade-up">
                    <div class="gal-wrap">
                        <img src="../../../assets/img/gallery/gallery-4.jpg" class="img-fluid" alt="img">
                    </div>
                    <div class="gal-wrap">
                        <img src="../../../assets/img/gallery/gallery-5.jpg" class="img-fluid" alt="img">
                    </div>
                </div>
            </div>
        </div>

</section>
<!-- /Space Section -->
</template>