<template>
    <!-- Profile Content -->
    <div class="dashboard-content">		
			<div class="container">
				<div class="">
					<ul class="dashborad-menus">
						<li>
							<router-link 
								to="/dashboard"
							>
								<i class="feather-grid"></i> <span>Mi Cuenta</span>
							</router-link>
						</li>
						<li class="active">
							<router-link 
								to="/profile"
							>
								<i class="fa-solid fa-user"></i> <span>Perfil</span>
							</router-link>
						</li>
						<li>
							<router-link 
								to="/my-listing"
							>
								<i class="feather-list"></i> <span>Mis Fichas</span>
							</router-link>
						</li>
						<li>
							<router-link 
								to="/bookmarks"
							>
								<i class="fas fa-solid fa-heart"></i> <span>Mis Favoritos</span>
							</router-link>
						</li>
						<li>
							<router-link 
								to="/login"
							>
							<i class="fas fa-sign-out-alt"></i>
								<span>Cerrar Sesión</span>
							</router-link>
						</li>
					</ul>
				</div>
				<div class="profile-content">
				     <div class="row dashboard-info">
					    <div class="col-lg-9">
						    <div class="card dash-cards">
								<div class="card-header">
									<h4>Datos Personales</h4>																
								</div>
                                <div class="card-body">
								    <div class="profile-photo">
									    <div class="profile-img">
											<div class="settings-upload-img">
												<img 
													:src="imageUrl" 
													alt="profile"
												>
											</div>										    
											<div class="settings-upload-btn">
												<input type="file" accept="image/*" name="image" class="hide-input image-upload" @change="onFileSelected">
												<label for="file" class="file-upload">Foto de perfil</label>												
											</div>	
											<span>Tamaño maximo: 10 MB</span>
										</div>                                        									
                                        <a href="javascript:void(0)" class="profile-img-del"><i class="feather-trash-2"></i></a>										
									</div>
									<div class="profile-form">
									     <b-form>
										    <div class="form-group">
											    <label class="col-form-label">Nombre Completo</label>
										        <div class="pass-group group-img">
													<span class="lock-icon"><i class="feather-user"></i></span>
													<b-form-input 
														type="text"
														class="form-control"
														v-model="userData.name"
														placeholder="Tu Nombre"
													/>													
												</div>
										    </div> 
											<div class="row">
											     <div class="col-lg-6 col-md-6">
												    <div class="form-group">
														<label class="col-form-label">Teléfono</label>
														<div class="pass-group group-img">
															<span class="lock-icon"><i class="feather-phone-call"></i></span>
															<b-form-input
																type="tel"
																class="form-control"
																v-model="userData.phone"
																placeholder="+44 215346 1223" />													
														</div>
													</div>
												 </div>
												 <div class="col-lg-6 col-md-6">
												    <div class="form-group">
														<label class="col-form-label">Correo electrónico</label>
														<div class="group-img">
															<i class="feather-mail"></i>
															<b-form-input
																type="text"
																class="form-control"
																v-model="userData.email"
																placeholder="tucorreo@email.com"
															/>
														</div>
													</div>
												 </div>											
											</div>
											<div class="form-group">
											    <label class="col-form-label">Notas</label>
												<div class="pass-group group-img">
													<textarea 
														rows="4"
														readonly
														disabled
														class="form-control"
													>
														{{ userData.extra }}
													</textarea>												
												</div>
											</div>	
											
											<b-button variant="primary" type="submit"> Actualizar</b-button>	
										 </b-form>
								    </div> 
								</div>								
							</div>	
							
						</div>
                        <div class="col-lg-3">
						    <div class="profile-sidebar">
							    <div class="card">
								    <div class="card-header">
									    <h4>Cambiar contraseña</h4>
									</div>	
                                    <div class="card-body">
                                       <b-form>
                                            <div class="form-group">
											    <label class="col-form-label">Nueva Contraseña</label>
										        <div class="pass-group group-img">
													<span class="lock-icon"><i class="feather-lock"></i></span>
													<b-form-input
														v-if="showPassword"
														type="text"
														class="form-control pass-input"
														v-model="password"
														placeholder=".............."
													/>
													<b-form-input v-else type="password"
														class="form-control pass-input"
														placeholder=".............." v-model="password" />
													<span
														class="toggle-password"
														@click="toggleShow"
														:class="{
														'feather-eye': showPassword,
														'feather-eye-off': !showPassword,
														}"
													></span>
												</div>
										    </div>
                                            <div class="form-group">
											    <label class="col-form-label">Confirmar nueva Contraseña</label>
										        <div class="pass-group group-img">
													<span class="lock-icon"><i class="feather-lock"></i></span>
													<b-form-input
														v-if="showPassword2"
														type="text"
														class="form-control pass-input"
														v-model="password2"
														placeholder=".............."
													/>
													<b-form-input v-else type="password"
														class="form-control pass-input"
														placeholder=".............." v-model="password2" />
													<span
														class="toggle-password"
														@click="toggleShow1"
														:class="{
														'feather-eye': showPassword2,
														'feather-eye-off': !showPassword2,
														}"
													></span>
												</div>
										    </div>	
                                            <b-button variant="primary" type="submit"> Confirmar </b-button>											
									   </b-form>								
									</div>  									
								</div>							
							</div>
						</div>							
					 </div>				
				</div>
			</div>		
		</div>		
		<!-- /Profile Content -->
</template>

<script>
import axios from 'axios'; // Importar axios

const image = require(`@/assets/img/profile-img.jpg`)
	export default {
		 data() {
		 return {
		 	title: "Profile",
		 	title1: "User Profile",
			showPassword: false,
		 	password: null,
			title01: "Profile",
		 	title2: "User Profile",
			showPassword2: false,
		 	password2: null,
			 userData: {
                name: "", // Agrega más campos según sea necesario
            },
			 imageUrl: image
		};
	},
	computed: {
		buttonLabel() {
			return this.showPassword ? "Hide" : "Show";
		},
		buttonLabel1() {
			return this.showPassword2 ? "Hide" : "Show";
		},
	},
	mounted() {
        this.fetchUserData();
    },
	methods: {
		toggleShow() {
		 	this.showPassword = !this.showPassword;
		},
		toggleShow1() {
		 	this.showPassword2 = !this.showPassword2;
		},

		fetchUserData() {
            axios.get(process.env.VUE_APP_API_DIRECTORY + "auth/user", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("tokenSession")
                },
            })
            .then(response => {
                this.userData = response.data;
            })
            .catch(error => {
                console.error("Error al obtener los datos del usuario:", error);
            });
        },

		onFileSelected(event) {
		const file = event.target.files[0]
		const reader = new FileReader()

		reader.onload = (event) => {
			this.imageUrl = event.target.result
		}

		reader.readAsDataURL(file)
		}
	},
};
</script>
	