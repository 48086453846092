<template>
    <!-- Package Section -->
		<section class="package-section">
		    <div class="container">
			    <div class="row">
			        <div class="col-md-12 aos" data-aos="fade-up">
						<div class="section-heading-two white-text text-center">
							<p>Loved One</p>
							<h2>Wedding Packages</h2>
							<img src="../../../assets/img/title-img-white.png" class="img-fluid" alt="title-img">
						</div>		
					</div>			     
				</div>	
				<div class="row justify-content-center">
					<div class="col-lg-4 col-md-6 d-flex aos" data-aos="fade-up">
						<div class="package-wrap flex-fill">
							<div class="section-heading-two text-center">
								<h2>Sliver Package</h2>
								<p>$7200 Package </p>
								<img src="../../../assets/img/title-img.png" class="img-fluid" alt="title-img">
							</div>		
							<ul>
								<li>Bridal Makeup (3)</li>
								<li>Morning Breakfast</li>
								<li>Bridal and Groom Wear</li>
								<li>Single Photography</li>
								<li>Decoration</li>
								<li>2 Rooms for bridal and Groom</li>
							</ul>
							<router-link to="/login" class="btn btn-primary">Choose Plan</router-link>
							<div class="couple-img">
								<img src="../../../assets/img/bg/couple-01.png" class="img-fluid" alt="title-img">
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 d-flex aos" data-aos="fade-up">
						<div class="package-wrap flex-fill">
							<div class="section-heading-two text-center">
								<h2>Gold Package</h2>
								<p>$15000 Package</p>
								<img src="../../../assets/img/title-img.png" class="img-fluid" alt="title-img">
							</div>		
							<ul>
								<li>Bridal Makeup (6)</li>
								<li>Morning Breakfast & Noon Lunch</li>
								<li>Bridal and Groom Wear</li>
								<li>Double Photography</li>
								<li>Decoration, E-Invitation and DJ</li>
								<li>10 Rooms</li>
							</ul>
							<router-link to="/login" class="btn btn-primary">Choose Plan</router-link>
							<div class="couple-img">
								<img src="../../../assets/img/bg/couple-02.png" class="img-fluid" alt="title-img">
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 d-flex aos" data-aos="fade-up">
						<div class="package-wrap flex-fill">
							<div class="section-heading-two text-center">
								<h2>Platinum Package</h2>
								<p>$18000 Package </p>
								<img src="../../../assets/img/title-img.png" class="img-fluid" alt="title-img">
							</div>		
							<ul>
								<li>Bridal Makeup (6)</li>
								<li>Morning Breakfast & Noon Lunch</li>
								<li>Bridal and Groom Wear</li>
								<li>Double Photography</li>
								<li>Decoration, E-Invitation and DJ</li>
								<li>15+ Rooms, Cake and Beverage</li>
							</ul>
							<router-link to="/login" class="btn btn-primary">Choose Plan</router-link>
							<div class="couple-img">
								<img src="../../../assets/img/bg/couple-03.png" class="img-fluid" alt="title-img">
							</div>
						</div>
					</div>
				</div>			   
			</div>		
		</section>		
		<!-- /Package Section -->
		
		<!-- Success Section -->
		<section class="success-section">
		    <div class="container">
			    <div class="row align-items-center">
			        <div class="col-md-5 aos" data-aos="fade-up">
						<div class="success-img">
							<img src="../../../assets/img/success-img.png" class="img-fluid" alt="img">
						</div>	
					</div>	
					<div class="col-md-7 aos" data-aos="fade-up">
						<div class="success-wrap">
							<div class="section-heading-two text-center">
								<p>Who we are</p>
								<h2>Our Success Story</h2>
								<img src="../../../assets/img/title-img.png" class="img-fluid" alt="title-img">
							</div>		
							<div class="success-sec">
								<div class="row justify-content-center">
									<div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
										<div class="success-item">
											<img src="../../../assets/img/icons/wed-icon-01.svg" class="img-fluid" alt="title-img">
											<h6>180+Wedding Venues & Vendors</h6>
											<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
										</div>
									</div>
									<div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
										<div class="success-item">
											<img src="../../../assets/img/icons/wed-icon-02.svg" class="img-fluid" alt="title-img">
											<h6>350+ Real Wedding Event Success</h6>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</p>
										</div>
									</div>
									<div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
										<div class="success-item">
											<img src="../../../assets/img/icons/wed-icon-03.svg" class="img-fluid" alt="title-img">
											<h6>More than 65+ Cities Available</h6>
											<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature
											text.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>			   
			</div>		
		</section>		
		<!-- /Celebrating Section -->
</template>