<template>
<!-- Recommended Banner -->
    <section class="section-blk recommended-blk style1-blk">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="title-blk justify-content-center text-center">
                        <h2>Encuentra tu franquicia ideal</h2>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="recommended-tabs-blk">
                                <ul class="nav tabs-style gap-3" data-bs-tabs="tabs">
										<li
											v-for="item in categories"
											:key="item.id"
										>
											<a 
                                                aria-current="true" 
                                                :class="{ 'active': category === item.id }" 
                                                data-bs-toggle="tab" 
                                                @click="setCategory(item.id)"
                                            >
												<span>
													<i 
														:class="item.icon" 
														alt="Dreamstrip Recommended"
													></i>
												</span>
												{{ item.name }}
											</a>
										</li>
									</ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body tab-content">
                        <div class="tab-pane active">
                            <div class="recommended-carousel">
                                <b-overlay 
                                        :show="show" 
                                        rounded="sm"
                                        opacity="1"
                                        variant="white"
                                    >
                                <div class="row">
                                    
                                    <div 
                                        class="col-lg-3 col-md-6 aos" 
                                        data-aos="fade-up" 
                                        v-for="item in companies" 
                                        :key="item.id"
                                    >
                                        <div class="space-wrap">
                                            <div class="space-img">
                                                <router-link 
                                                    :to="'/franquicia/'+item.link"
                                                >
                                                    <img 
                                                        v-if="item.uploads[0]"
                                                        :src="item.uploads[0]" 
                                                        class="img-fluid" 
                                                        alt="blog-img"
                                                    >
                                                    <img 
                                                        v-else
                                                        :src="require(`../../../assets/img/work/city-01.jpg`)" 
                                                        class="img-fluid" 
                                                        alt="blog-img"
                                                    >
                                                    
                                                </router-link>
                                                <div class="fav-item">
                                                    <span class="featured-text">Oportunidad</span>
                                                </div>	
                                            </div>
                                            <div class="space-content">
                                                <div class="space-cat">
                                                    <img
                                                        v-if="item.logo"
                                                        :src="item.logo.route" 
                                                        class="img-fluid" 
                                                        alt="img"
                                                    >
                                                    <img
                                                        v-else
                                                        :src="require(`../../../assets/img/logos/1.jpeg`)" 
                                                        class="img-fluid" 
                                                        alt="img"
                                                    >
                                                </div>
                                                <h5>
                                                    <router-link 
                                                        :to="'/franquicia/'+item.link"
                                                    >
                                                        {{ item.name }}
                                                    </router-link>
                                                </h5>
                                                <ul>
                                                    <div 
                                                        v-if="item.categories"
                                                    >
                                                        <li 
                                                            class="addr" 
                                                            v-for="(column, index) in item.categories" 
                                                            :key="index" 
                                                        >
                                                            <i
                                                                :class="column.icon" 
                                                            ></i>
                                                            {{ column.name }}
                                                        </li>
                                                    </div>

                                                    <li><a href="javascript:void(0);" class="fav-icon"><i class="fa-solid fa-heart"></i></a></li>
                                                </ul>
                                                <div class="price">
                                                    <img 
                                                        :src="require(`../../../assets/img/profiles/investment.png`)" 
                                                        class="img-fluid" 
                                                        alt="img"
                                                    >
                                                    <span>
                                                        {{ item.rate }}
                                                    </span>

                                                    <range-price 
                                                        :idCompany="item.id"
                                                    ></range-price>

                                                </div> 
                                            </div> 
                                        </div> 
                                    </div>	 
                                   
                                </div>	
                            </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Recommended Banner -->

</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            categories: [],
            category: 11,
            show: true,
            companies: [],
            imgs: [],
            countCategories: 0,
        };
    },
    mounted() {
        this.fetchCategories(); 
        this.fetchCompanies();
    },
    methods: {
        async fetchCategories() 
        {
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=300&type=0");
                // Actualiza IndexDestination con los datos obtenidos
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchCompanies() 
        {
            try {
                const response = await axios.get(
                        process.env.VUE_APP_API_DIRECTORY + 
                        "companies-list?outstanding=1&active=1&limit=4&random=1&category_id=" + 
                        this.category);
                this.companies = response.data;
                this.show = false;

                for (let i = 0; i < this.companies.length; i++) {
                    const company = this.companies[i];
                    const data_company = await this.fetchCompanyData(company.id);
                    if (data_company) {
                        company.uploads = data_company.uploads
                            .filter(upload => upload.type === 'image')
                            .map(upload => upload.route);
                    }
                }


            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        },
        async fetchCompanyData(companyId) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY+'companies/'+companyId, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer '+process.env.VUE_APP_TOKEN,
                    },
                });
                if (response && response.data && response.data.data) {
                    let uploads = null;
                    uploads = response.data.uploads
                    return { uploads };
                } else {
                    console.error("Error en la API");
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching total investment for company ${companyId}:`, error);
                return null;
            }
        },
        setCategory(number)
        {
            this.show = true;
            this.category = number;
            this.fetchCompanies();
        }
    }
};
</script>