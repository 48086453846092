<template>
    <!-- Footer -->
		<footer class="footer">
		    <div class="container">
			   	<div class="stay-tuned">
			        <h3>Stay Tuned holis Us</h3>
			        <p>Subcribe to our newletter and never miss our latest news and promotions. Our newsletter is sent once a week, every thursday.</p>
			        <b-form>
                        <div class="form-group">
						    <div class="group-img">
								<i class="feather-mail"></i>
								<b-form-input type="text" class="form-control" placeholder="Enter Email Address" />
							</div>							
						</div>
                        <b-button variant="primary" type="submit"> Subscribe</b-button>	 						
                    </b-form>					
				</div>			
			</div>
		
			<!-- Footer Top -->
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget">
								<div class="footer-logo">
									<router-link to="#"><img src="../../../assets/img/footerlogo.svg" alt="logo"></router-link>
								</div>
								<div class="footer-content">
									<p>Holis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt et magna aliqua.  </p>
								</div>
								<div class="social-icon">
									<ul>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-facebook-f"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-twitter"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-instagram"></i></router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-linkedin-in"></i></router-link>
										</li>
									</ul>
                                </div>								
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">About us</h2>
								<ul>
									<li>
										<router-link to="/about">Our product</router-link>
									</li>
									<li>
										<a href="javascript:void(0)">Documentation</a>
									</li>
									<li>
										<router-link to="/service-details">Our Services</router-link>
									</li>
									<li>
										<a href="javascript:void(0)">Get Started Us</a>
									</li>
									<li>
										<router-link to="/contact">Contact Us</router-link>
									</li>									
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Quick links</h2>
								<ul>
									<li>
										<a href="javascript:void(0)">Market Place</a>
									</li>
									<li>
										<a href="javascript:void(0)">Documentation</a>
									</li>
									<li>
										<a href="javascript:void(0)">Customers</a>
									</li>
									<li>
										<a href="javascript:void(0)">Carriers</a>
									</li>
									<li>
										<router-link to="blog-list">Our Blog</router-link>
									</li>									
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-2 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Top Cities</h2>
								<ul>
									<li>
										<a href="javascript:void(0)">Manhatten</a>
									</li>
									<li>
										<a href="javascript:void(0)">Los Angeles</a>
									</li>
									<li>
										<a href="javascript:void(0)">Houston</a>
									</li>
									<li>
										<a href="javascript:void(0)">Chicago</a>
									</li>
									<li>
										<a href="javascript:void(0)">Alabama</a>
									</li>									
								</ul>
							</div>
							<!-- /Footer Widget -->
						</div>
						<div class="col-lg-3 col-md-6">
							<!-- Footer Widget -->
							<div class="footer-widget">
								<h2 class="footer-title">Communication</h2>
								<div class="footer-contact-info">
										<div class="footer-address">
											<img src="../../../assets/img/call-calling.svg" alt="Callus">
											<p><span>Llamanos</span> <br> +1 (786) 408-935	 </p>
										</div>
										<div class="footer-address">
											<img src="../../../assets/img/sms-tracking.svg" alt="Callus">
											<p><span>Correo electrónico</span> <br> info@franquiciar.com	 </p>
										</div>							
								</div>																
							</div>
							<!-- /Footer Widget -->							
						</div>
					</div>
					
					<!-- Footer Counter Section-->
					<div class="footercount">
						    <div class="row">
							         <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,532</p>										
										</div>									 
									 </div>
									  <div class="col-lg-3 col-md-3">
									      <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,53264546</p>										
										</div>									 
									 </div> 
									 <div class="col-lg-3 col-md-3">
									     <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,53264546</p>										
										</div>									 
									 </div>
									  <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>We Accept</p>
											<ul class="d-flex">
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/amex-pay.svg" alt="amex"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/apple-pay.svg" alt="pay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/gpay.svg" alt="gpay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/master.svg" alt="paycard"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/phone.svg" alt="spay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../../assets/img/visa.svg" alt="visa"></a></li>
											</ul>										   								
										</div>									 
									 </div>
							</div>
					</div>			
			        <!-- /Footer  Counter Section-->	
					
				</div>
			</div>
			<!-- /Footer Top -->
						
			<!-- Footer Bottom -->
			<div class="footer-bottom">
				<div class="container">
					<!-- Copyright -->
					<div class="copyright">
						<div class="row">
							<div class="col-md-6">
								<div class="copyright-text">
									<p class="mb-0">All Copyrights Reserved © {{ new Date().getFullYear() }} - Listee.</p>          
								</div>
							</div>
							<div class="col-md-6">
								<!-- Copyright Menu -->
								<div class="copyright-menu">
									<ul class="policy-menu">
										<li>
											<router-link to="/privacy-policy">Privacy </router-link>
										</li>
										<li>
											<router-link to="/faq">Faq </router-link>
										</li>
										<li>
											<router-link to="/terms-condition">Terms</router-link>
										</li>										
									</ul>
								</div>
								<!-- /Copyright Menu -->
							</div>
						</div>
					</div>
					<!-- /Copyright -->
				</div>
			</div>
			<!-- /Footer Bottom -->
			
		</footer>
		<!-- /Footer -->
</template>

<script>
	export default {
		
	}
</script>