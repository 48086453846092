<template>
    <div class="main-wrapper">
        <layouts></layouts>

            <div class="page-wrapper">
                <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
                
                <associationlist />

                <foot />

                <scroll />
            </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "ASOCIACIONES DE FRANQUICIAS",
            text: "Bienvenido",
            cover: "associations.jpg",
            text1: "ASOCIACIONES DE FRANQUICIAS",
            name: "/",
        }
    },
}
</script>