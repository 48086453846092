<template>

    <b-modal id="modal-1" v-model="modal" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal>
    
        <mainsidebar/> 
          <div 
            class="main-wrapper"
            style="    background: #f9fafc;"  
          >
          
              
      
              <div class="page-wrapper"
                style="    background: #f9fafc;"
              >
      
                  
                      <!-- Dashboard Content -->
                      <div class="dashboard-content">
            <div class="container">
                <dashboardmenu />
    
                
    
                <div class="dash-listingcontent dashboard-info">
                    <div class="dash-cards card">
                        <div class="card-header">
                            <h4>Datos del Usuario</h4>
                            <b-button 
                                variant="outline-primary"
                                v-b-toggle.accordion-2
                                size="sm"
                                @click="createData"
                            >
                                Nuevo Usuario
                            </b-button>
    
    
                                   
                        </div>
    
                        
    
                        <div class="card-body">
                            <div class="accordion" role="tablist">
                                <b-card no-body class="mb-4">
                                    <b-collapse 
                                        id="accordion-2" 
                                        accordion="my-accordion" 
                                        role="tabpanel"
                                        v-model="modal" 
                                    >
                                        <b-card-body>
                                                <div class="card-header">
                                                    <h5 v-if="recordId == 0">Crear Usuario</h5>	
                                                    <h5 v-else>Editar Usuario</h5>							
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input">
                                                                <label class="col-form-label">Descripción</label>
                                                                <input 
                                                                    type="text" 
                                                                    class="form-control pass-input" 
                                                                    placeholder="Escriba el numero de teléfono"
                                                                    v-model="editData.description"
                                                                >		
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input formlast-input-inner">
                                                                <label class="col-form-label">Tipo de Empresa</label>
                                                                <b-form-select 
                                                                    :options="typeCompanies"
                                                                    value-field="code"
                                                                    text-field="name"
                                                                    v-model="editData.company_type"
                                                                ></b-form-select>
                                                            </div>
                                                        </div>											
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input">
                                                                <label class="col-form-label">Icono</label>
                                                                <input 
                                                                    type="text" 
                                                                    class="form-control" 
                                                                    placeholder="Escriba el codigo del icono"
                                                                    v-model="editData.icon"
                                                                >		
                                                            </div>
                                                        </div>
                            
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input formlast-input-inner">
                                                                <label class="col-form-label">Tipo de Dato</label>
                                                                <b-form-select 
                                                                    :options="typeDatas"
                                                                    value-field="code"
                                                                    text-field="name"
                                                                    v-model="editData.type"
                                                                ></b-form-select>
                                                            </div>
                                                        </div>											
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input formlast-input-inner">
                        
                                                                <a 
                                                                    href="https://fontawesome.com/icons/coins?f=classic&s=solid"
                                                                    target="_blank"
                                                                >Galeria de Iconos</a>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group formlast-input">
                                                                <label class="col-form-label"></label>
                                                                <ul>
                                                                    <li>
                                                                        <label class="custom_check">
                                                                            <input 
                                                                                type="checkbox" 
                                                                                name="wireless-internet"
                                                                                v-model="editData.selection"
                                                                            >
                                                                            <span class="checkmark"></span> Dato Multiple
                                                                        </label>
                                                                    </li>
                                                                </ul>		
                                                            </div>
                                                        </div>											
                                                    </div>
                                                </div>
                                                <b-button 
                                                    variant="primary" 
                                                    type="submit"
                                                    @click="handleSubmit"
                                                >Guardar</b-button>	
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
    
                            <div class="listing-search">
                                <div class="filter-content form-group">
                                    <div class="group-img">
                                        <input type="text" class="form-control" placeholder="Search...">
                                        <i class="feather-search"></i>
                                    </div>
                                </div>
                                <div class="sorting-div">
                                    <div class="sortbyset">
                                        <span class="sortbytitle">Ordenar por</span>
                                        <div class="sorting-select">
                                            <vue-select :options="Newest" placeholder="Newest" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <a-table
                                    class="stripped table-hover listing-table datatable"
                                    :columns="columns"
                                    :data-source="companies" 
         
                                >
                                    <template #bodyCell="{ column, record }">


                                    <template
                                        v-if="column.key === 'name'"
                                    >
                                    <div class="media-img-wrap flex-shrink-0">
                                        <div class="avatar avatar-online">
                                            <img 
                                                :src="record.avatar.route" 
                                                alt="User Image" 
                                                class="avatar-img rounded-circle"
                                            />
                                        </div>
                                    </div>

                                    <div class="media-content">
                                        <span class="status-text">{{ record.name }}</span>
                                        <br>
                                        <small>{{ record.email }}</small>
                                    </div>
                                    </template>
                                    <template v-else-if="column.key === 'company_type'">
                                        <span 
                                           
                                        >
                                            {{ getCompanyName(record.company_type) }}
                                        </span>
                                    </template>
                                    <template 
                                        v-else-if="column.key === 'active'"
                                    >
                                        <span 
                                        v-if="record.active == true" 
                                        class="status-text">Activo</span>
    
                                        <span 
                                        v-else 
                                        style="color:red;"
                                        class="status-text">Inactivo</span>
    
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <div class="action">
                                            <a 
                                                class="action-btn btn-edit"
                                                @click="openEditModal(record.id)"
                                            >
                                                <i class="feather-edit-3"></i>
                                            </a>
                                            <a 
                                                href="javascript:void(0)" 
                                                class="action-btn btn-trash"
                                                @click="deleteUser(record.id)"
                                            >
                                                <i class="feather-trash-2"></i>
                                            </a>
                                        </div>
                                    </template>
                                    </template>
                                </a-table>
                        </div>
                        <div class="blog-pagination">
                            <nav>
                                <ul class="pagination">
                                    <li 
                                        class="page-item nextlink"
                                        v-if="page != 1"
                                    >
                                        <a 
                                            class="page-link" 
                                            @click="nextPage('left')"
                                            href="#"
                                        >
                                            <i class="fas fa-regular fa-arrow-left"></i>
                                        </a>
                                    </li>
                                    <li class="justify-content-center pagination-center">
                                        <div class="pagelink">
                                            <ul>
                                                <li 
                                                    class="page-item"
                                                    v-for="(column, index) in pages" 
                                                    :key="index" 
                                                >
                                                    <a 
                                                        :class="{ 'page-link': true, 'active': page === column }"
                                                        @click="nextPage(column)"
                                                        href="#"
                                                    >{{ column }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li 
                                        class="page-item nextlink"
                                        v-if="page != pages"
                                    >
                                        <a 
                                            class="page-link" 
                                            @click="nextPage('right')"
                                            href="#"
                                        >
                                            <i class="fas fa-regular fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
              
      
                  <scroll />
              </div>
          </div>
      </template>
      
      
      <script>
    
      import Dashboard from '../../../assets/json/dashboard.json'
      import DashboardReview from '../../../assets/json/dashboardReview.json'
      import axios from 'axios'; // Importar Axios
      
      export default {
          data() {
              return {
                typeDatas: [
                    { code: 10, name: '5 Razones para elegir nuestra franquicia' },
                    { code: 14, name: 'CONTRATACIONES' },
                    { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
                    { code: 3, name: 'DATOS OPERATIVOS' },
                    { code: 11, name: 'EXPERIENCIA' },
                    { code: 4, name: 'DATOS ECONOMICOS' },
                    { code: 13, name: 'INFORMACION GENERAL' },
                    { code: 9, name: 'Sucursales' },
                    { code: 1, name: 'OBJETIVO' },
                    { code: 5, name: 'OTROS DATOS IMPORTANTES' },
                    { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
                    { code: 6, name: 'Información de la Empresa para Facturación' },
                    { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
                    { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
                    { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
                ],
                typeCompanies: [
                    { code: 1, name: 'Franquicias' },
                    { code: 2, name: 'Asociaciones de Franquicias' },
                    { code: 3, name: 'Consultores' },
                    { code: 4, name: 'Proveedores' },
                    { code: 5, name: 'Negocios' }
                ],
                columns : [
                    {
                        title: "Nombre",
                        dataIndex: "name",
                        key: "name",
                        sorter: {
                        compare: (a, b) => {
                            a = a.name.toLowerCase();
                            b = b.name.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                        },
                    },
                    {
                        title: "Activo",
                        dataIndex: "active",
                        key: "active",
                        sorter: {
                        compare: (a, b) => {
                            a = a.active.toLowerCase();
                            b = b.active.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                        },
                    },
                    {
                        title: "Action",
                        key: "action",
                        class: "text-end",
                        sorter: true,
                    },
                ],
                Dashboard: Dashboard,
                DashboardReview: DashboardReview,
                companies: [],
                editData: {
                    description: '',
                    type: '',
                    selection: '',
                    icon: '',
                    company_type: '',
                },
                modal: false,
                recordId: 0,
                title: "Login",
                text: "Inicio",
                text1: "Login",
                name: "/",
                // variables para la paginacion
                page: 1,
                pages: 1,
                totalItems: 0,
                itemsPerPage: 10,
                loadingText: ''
              }
          },
          mounted() {
                // Llamar a la API para obtener la lista de compañías
                this.fetchUsers();
          },
          methods: {
            async fetchUsers() {
                try {
                    const response = await axios.get(
                            process.env.VUE_APP_API_DIRECTORY + 
                            "users?order=created_at&by=asc&page=" + 
                            this.page, 
                        {
                            headers: {
                                "Accept": "application/json",
                                "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                            }
                        });
    
                    // Almacenar la lista de compañías en la propiedad 'companies'
                    this.companies = response.data.data;
                    //datos para la paginacion
                    if (this.companies.length < 1) {
                        this.loadingText = 'No hay registros'
                    }
                    this.totalItems = response.data.total
                    this.itemsPerPage = response.data.per_page
                    if (response.data.from) {
                        this.page = response.data.current_page
                        this.pages = response.data.last_page
                    } else {
                        this.page = 1
                        this.pages = 1
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
    
            openEditModal(cordId) {
                const record = this.companies.find(company => company.id === cordId);
                this.recordId = cordId
                this.editData = {
                    description: record.description,
                    type: record.type,
                    selection: record.selection,
                    icon: record.icon,
                    company_type: record.company_type,
                };
                this.modal = true
            },
    
            getTypeName(typeCode) {
                const typeData = this.typeDatas.find(type => type.code === typeCode);
                return typeData ? typeData.name : '';
            },
    
            createData() {
    
                this.editData.description = '' 
                this.editData.type = '' 
                this.editData.selection = '' 
                this.editData.type = '' 
                this.editData.icon = '' 
                this.recordId = 0
    
            },
    
            nextPage(orientation) {
                if(orientation == 'right'){
                    this.page = this.page + 1;
                }else if(orientation == 'left'){
                    this.page = this.page - 1;
                }else{
                    this.page = orientation
                }
                this.fetchUsers();
            },
    
            deleteUser(corId) {
    
                this.$swal
                    .fire({
                        title: "¿Estás seguro?",
                        text: "¡No podrás revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí, eliminarlo",
                    })
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                const response = await axios.delete(
                                    `${process.env.VUE_APP_API_DIRECTORY}users/${corId}`,
                                    {
                                        headers: {
                                            Accept: "application/json",
                                            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                                        },
                                    }
                                );
    
                                // Si la eliminación es exitosa, muestra el mensaje de éxito
                                this.$swal.fire({
                                    title: "¡Eliminado!",
                                    text: "El usuario ha sido eliminado.",
                                    icon: "success",
                                });
    
                                // Recarga la lista de compañías después de la eliminación
                                this.fetchUsers();
                            } catch (error) {
                                // Si hay un error, muestra el mensaje de error
                                this.$swal.fire({
                                    title: "Error al eliminar",
                                    text: "Hubo un error al intentar eliminar el archivo.",
                                    icon: "error",
                                });
    
                                console.error("Error deleting data:", error);
                            }
                        }
                    });
            },
    
    
            getCompanyName(companyCode) {
                const companyData = this.typeCompanies.find(type => type.code === companyCode);
                return companyData ? companyData.name : '';
            },
    
    
            getMultipleName(status) {
                const companyData = this.typeCompanies.find(type => type.code === companyCode);
                return companyData ? companyData.name : '';
            },
    
            async handleSubmit() {
                try {
                    
                    if(this.editData.selection == true){
                        this.editData.selection = 1
                    }else{
                        this.editData.selection = 0
                    }
    
                    if(this.recordId == 0){
                        const response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "users", this.editData, {
                            headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    }else{
                        const response = await axios.patch(process.env.VUE_APP_API_DIRECTORY + "users/" + this.recordId, this.editData, {
                            headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    }
                    
                    //console.log('Respuesta de la API:', response.message);
    
                    this.modal = false;
                    this.fetchCompanies();
                } catch (error) {
                    console.error('Error al enviar la solicitud PATCH:', error);
                }
            },
    
        }
      }
      </script>