<template>

<b-modal 
	v-if="headquarters"
	id="modal-map" 
	ref="modal-map" 
	hide-footer
	hide-header
	v-model="modalShow"
	class="modal-fullscreen"
	size="xl"
	title="Sucursales"
>
	<b-button 
		class="position-absolute top-0 end-0 mt-2 mr-5" 
		style="border: none; background-color: white;"
		v-b-modal.modal-map
	><i class="fas fa-times fa-2x"></i></b-button>

	<div style="height: 500px;">
		<GoogleMap 
			api-key="AIzaSyDz228dol3MOtegTgC_2Qh5vjHAf9y79qA" 
			style="width: 100%; 
			height: 100%" 
			ref="mapRef"
			:center="center" 
			:zoom="13"
		>
		<MarkerCluster>
			<Marker 
				v-for="(location, index) in locations" 
				:key="index" 
				:options="{ 
					position: location, 
					icon: { url: companyData.logo.route,
						scaledSize: {width: 40, height: 35},
					 }, // Ruta del logo como icono
					backgroundColor: '#fff',
					size: 20
				}" 
			>
				<InfoWindow>
					<div id="content">
						<div id="siteNotice"></div>
						<h1 id="firstHeading" class="firstHeading">{{ headquarters[index].find(loc => loc.description === 'Titulo').value }}</h1>
						<div id="bodyContent">
							<h6>Dirección: {{ headquarters[index].find(loc => loc.description === 'Dirección').value }}</h6>
							
						</div>
					</div>
				</InfoWindow>
			</Marker>
			
		</MarkerCluster>
		</GoogleMap>
	</div>



	<b-button 
	class="mt-3" 
	block 
	v-b-modal.modal-map 
	>Cerrar</b-button>
</b-modal>

    <!--Details Main  Section-->
		<div class="details-main-wrapper">	
            <div class="container">
				
			        <div class="row">
					  <div class="col-lg-9">                             					
							<div class="card ">
							<b-overlay :show="show" rounded="sm">
								<div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span> 
									<h4>Descripción</h4>
								</div>
								<div class="card-body">
									<p> {{  companyData.description }}</p>
									<div 
										v-for="(group, type) in groupedData" 
										:key="type"
										
									>
										<div 
											class=""
											v-if="type == 5"
										>
											<div 
												v-for="(item, index) in group" 
												:key="index" 
											>
												<div 
													v-if="companyDataValueArray[item.id] != null"
												>
													<iframe 
														:src="convertToEmbedUrl(companyDataValueArray[item.id])" 
														title="YouTube video player" 
														frameborder="0" 
														style="width: 100%; height: 400px;"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
														allowfullscreen>
													</iframe>
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-overlay>
							</div>	
							
						<div 
							v-for="(group, type) in groupedData" 
							:key="type"
							
						>
							<div 
								class="card"
								v-if="type == 3 || type == 4"
							>
								<div class="card-header">
									<i class="feather-list"></i>
									<h4>{{ getBlockTitle(type) }}</h4>
								</div>
								<div class="card-body">
									<div class="row">
										<div 
											v-for="(item, index) in group" 
											:key="index" 
											class="col-lg-4 col-md-6 col-sm-12"
										>
											<div 
												class="featureslist d-flex align-items-center"
												v-if="companyDataValueArray[item.id] != null"
											>
												<div class="feature-img">
													<i 
															:class="item.icon" 
															style="margin: 5px;"
														></i>
												</div>
												<div class="featues-info">
													<h6
														style="text-transform: uppercase;"
													>{{ item.description }} <br> <strong>{{ companyDataValueArray[item.id] }}</strong></h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<!--Gallery Section-->							   
                        <div 
							class="card gallery-section"
							v-if="imgs.length > 0"
						>
							<div class="card-header ">
								<i class="far fa-images"></i>
								<h4>Imagenes de la Franquicia</h4>
							</div>
							<div class="card-body">
							  <div class="gallery-content">
							    <div class="row">
									<div 
										class="col-lg-3 col-md-3 col-sm-3" 
										v-for="(src, index) in imgs" 
										:key="index" 
										@click="showImg(index)"
									>
										<div class="gallery-widget">	
											<a href="javascript:void()" data-fancybox="gallery1">
												<img 
													class="img-fluid" 
													alt="Image" 
													:src="src"
												>
											</a>
										</div>								
									</div>

									<vue-easy-lightbox
										:visible="visible"
										:imgs="imgs"
										:index="index"
										@hide="handleHide"
									></vue-easy-lightbox>
									
                                </div>									
							  </div>									
							</div>					
						</div>									
						<!--/Gallery Section-->
							

						
					</div>
					<div class="col-lg-3 theiaStickySidebar">
						<div class="stickysidebar">
						<div class="rightsidebar">
						    <div class="card">
							<b-overlay :show="show" rounded="sm">
						     <h4>
								<i class="fa fa-search-plus" alt="details-icon"></i>
								Mas Detalles
							 </h4>

							 <div 
							 	class="row"
								v-if="companyDataValueArray[12] != null"
							 >
								<div 
									class="col-lg-12 col-md-12 col-sm-12"
								>
									<div class="featureslist d-flex align-items-center">
										<div class="feature-img">
											<i 
													class="fa-solid fa-clock" 
													style="margin: 5px;"
												></i>
										</div>
										<div class="featues-info">
											<h6
												style="text-transform: uppercase;"
											>Año de inicio <br> <strong>{{ companyDataValueArray[12] }}</strong></h6>
										</div>
									</div>
								</div>
							</div>
							<div 
							 	class="row"
								v-if="companyDataValueArray[11] != null"
							>
								<div 
									class="col-lg-12 col-md-12 col-sm-12"
								>
									<div class="featureslist d-flex align-items-center">
										<div class="feature-img">
											<i 
													class="fa-solid fa-shop" 
													style="margin: 5px;"
												></i>
										</div>
										<div class="featues-info">
											<h6
												style="text-transform: uppercase;"
											>Locales propios <br> <strong>{{ companyDataValueArray[11] }}</strong></h6>
										</div>
									</div>
								</div>
							</div>
							<div 
							 	class="row"
								v-if="companyDataValueArray[10] != null"
							>
								<div 
									class="col-lg-12 col-md-12 col-sm-12"
								>
									<div class="featureslist d-flex align-items-center">
										<div class="feature-img">
											<i 
													class="fa-solid fa-store" 
													style="margin: 5px;"
												></i>
										</div>
										<div class="featues-info">
											<h6
												style="text-transform: uppercase;"
											>Locales franquiciados <br> <strong>{{ companyDataValueArray[10] }}</strong></h6>
										</div>
									</div>
								</div>
							</div>
							</b-overlay>
							</div>

							<div class="card">
							    <h4>
									<i class="fa fa-qrcode" alt=""></i>
									Código QR
								</h4>
							    <div 
									v-if="uri"
  									class="qrcode-container"
									style="max-width: 250px;"
  									ref="templateReference"
								>
								  <vue-qrcode 
								  	:value="uri" 
									ref="qrcde"
									:options="{ width: 800 }"
									class="qrcode-image"
								  ></vue-qrcode>		
								</div>
								<button
								 class="btn btn-block btn-primary viewdetails-btn p-2 m-1" 
								 @click="downloadQR"
								><i class="feather-download"></i> Descargar</button>
							</div>

							<div class="card">
							    
								<!-- <div class="featues-info">
									<h6
										style="text-transform: uppercase;"
									>Dirección <br> <strong>{{ companyData.address }}</strong></h6>
								</div> -->
							    <div class="map-details">
								  <div 
								  	class="map-frame"
									v-if="headquarters.length > 0"
								  >
								  	<h4>
										<i class="fa fa-location" alt=""></i>
										<strong>{{ headquarters.length }}</strong>&nbsp; Sucursales
									</h4>
									<div class="row">
										<div class="col-md-12">
											<div class="category-item aos" data-aos="fade-up">
												<div class="category-img">
													<img :src="require(`../../../assets/img/location.png`)"  class="img-fluid" alt="img">
													<a 
														v-b-modal.modal-map
														class="btn"
													>Ver Sucursales</a>
												</div>
											</div>
										</div> 
									</div>
								  </div>	
								  <div 
								  	v-if="companyData.countries"
									class="mt-4"
								  >
									<h5 >Países donde la franquicia está presente</h5>	
									<hr>					  
									<ul class="info-list">
										<li
											v-for="(column, index) in companyData.countries" 
											:key="index" 
										>
											<img 
												:src="column.flag" 
												class="img-fluid" 
												style="width: 30px;"
												alt=""
											/> {{ column.name }}
										</li>
									</ul>
								  </div>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>		
		</div>		
		<!-- /Details Main Section -->
</template>

<script>
  import axios from 'axios';
  import VueQrcode from '@chenfengyuan/vue-qrcode';
  import * as htmlToImage from 'html-to-image';
  import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
  import { GoogleMap, Marker, MarkerCluster, CustomMarker, InfoWindow } from "vue3-google-map";

  export default {
	components: { GoogleMap, Marker, MarkerCluster, CustomMarker, InfoWindow, VueQrcode },
	setup() { 
		const center = { lat: 14.639032067506696, lng: -90.52786376234081 }

		const icon = {};

		return { center, icon }
	},
	data() {
	  return {
		typeDatas: [
                { code: 10, name: '5 Razones para elegir nuestra franquicia' },
                { code: 14, name: 'CONTRATACIONES' },
                { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
                { code: 3, name: 'DATOS OPERATIVOS' },
                { code: 11, name: 'EXPERIENCIA' },
                { code: 4, name: 'DATOS ECONOMICOS' },
                { code: 13, name: 'INFORMACIÓN GENERAL' },
                { code: 9, name: 'Sucursales' },
                { code: 1, name: 'OBJETIVO' },
                { code: 5, name: 'OTROS DATOS IMPORTANTES' },
                { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
                { code: 6, name: 'Información de la Empresa para Facturación' },
                { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
                { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
                { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
            ],
		companyData: {
		  name: '',
		  description: '',
		  logo: '',
		  rating: 0,
		  investment: '',
		},
		visible: false,
		customMarkerIcon: null,
		uri: process.env.VUE_APP_WEBSITE + '/franquicia/' + this.$route.params.slug,
		index: 0, // default: 0
		companyDataValue: [],
		imgs: [],
		headquarters: [],
		locations: [],
		show: true,
		companyDataValueArray: []
	  };
	},
	mounted() {
	  // Realiza la solicitud a la API utilizando Axios
	  
	  axios.get(process.env.VUE_APP_API_DIRECTORY+'companies-by-slug/'+this.$route.params.slug, {
		headers: {
		  Accept: 'application/json',
		  Authorization: 'Bearer '+process.env.VUE_APP_TOKEN, // Token de autorización
		},
	  })
		.then(response => {
		  // Actualiza los datos del estado con la respuesta de la API
		  this.companyData = response.data;
		  const data = response.data;
		  let headquartersMap = {};
		  this.imgs = data.uploads
                		.filter(upload => upload.type === 'image')
                		.map(upload => upload.route);
		  this.fetchCompanyData();
		  response.data.data.forEach(item => {
				let description = item.company_data.value;
				let value = item.id;
				const count = item.company_data.count;

				// Agregar la descripción y el valor al arreglo companyDataValue
				if(item.company_data.data_id != null){
					//this.companyDataValue.push(null);
					this.companyDataValueArray[value] = description;
				}

				if (item.id >= 33 && item.id <= 36 && item.type === 9) {
					if (!headquartersMap[count]) {
						headquartersMap[count] = [];
					}
					headquartersMap[count].push({
						id: item.id,
						description: item.description,
						value: item.company_data.value,
						count: count
					});
				}
			});
			this.headquarters = Object.values(headquartersMap);
			this.show = false

			// Asignar los valores de headquarters a locations
			this.locations = this.headquarters.map(headquarter => {
				return {
					lat: parseFloat(headquarter.find(loc => loc.description === 'Latitud').value),
					lng: parseFloat(headquarter.find(loc => loc.description === 'Longitud').value)
				};
			});
		})
		.catch(error => {
		  console.error('Error al obtener datos de la API:', error);
		});
	},
	computed: {
			groupedData() {
				const result = {};
				this.companyDataValue.forEach(item => {
					if (!result[item.type]) {
					result[item.type] = [];
					}
					result[item.type].push(item);
				});
				return result;
			},
	  },
	methods: {
		showImg(index) {
			this.index = index;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		downloadQR() {
			const node = this.$refs.qrcde.$el;
			htmlToImage.toPng(node)
				.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = 'qrcode.png';
				link.href = dataUrl;
				link.click();
				})
				.catch((error) => {
				console.error('Error al descargar el código QR:', error);
				});
		},
		getBlockTitle(type) {
			// Buscar el nombre del bloque en base al código del tipo
			const typeData = this.typeDatas.find(data => data.code === parseInt(type));
			return typeData ? typeData.name : `Contact Information ${type}`;
		},

		convertToEmbedUrl(originalUrl) {
			let videoId = null;

			// Verificar si la URL tiene un ID de video
			const regex = /[?&]v=([^&]+)/;
			const match = originalUrl.match(regex);
			if (match) {
				videoId = match[1];
			} else {
				// Verificar si la URL es de formato "youtu.be"
				if (originalUrl.includes("youtu.be")) {
					// Extraer el ID del video de la URL
					videoId = originalUrl.split("/").pop().split("?")[0];
				} else {
					// Verificar otros formatos de URL
					const formats = [
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:v|embed)\/([^?&]+)/,
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|watch\?.*&v=)([^&]+)/,
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?.*?&|\?)v=([^&]+)/,
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:v|e)\/([^#&?]+)/,
						/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^#&?]+)/,
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:attribution_link\?a=[^\/]*&u=)?\/(?:watch%3Fv%3D|watch\?v=|v\/|e\/|\?v=|\/)([a-zA-Z0-9_-]{11})/,
					];

					for (const format of formats) {
						const match = originalUrl.match(format);
						if (match) {
							videoId = match[1];
							break;
						}
					}
				}
			}

			if (videoId) {
				// Construir la URL de inserción
				const embedUrl = `https://www.youtube.com/embed/${videoId}`;
				return embedUrl;
			} else {
				return null;
			}
		},

		fetchCompanyData() {
			axios.get(process.env.VUE_APP_API_DIRECTORY + 'data?order=created_at&by=asc&limit=30&company_type=1', {
				headers: {
				"Accept": "application/json",
				"Authorization": "Bearer " + process.env.VUE_APP_TOKEN
				}
			})
			.then(response => {
				this.companyDataValue = response.data;
				
			})
			.catch(error => {
				console.error('Error al obtener datos de la compañía:', error);
			});
		},
	}
  }
  </script>
<style>
.qrcode-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    margin-bottom: 10px; /* Opcional: añade espacio debajo del código QR */
}

.qrcode-image {
    max-width: 210px;
    max-height: 210px;
    background-color: white;
}
</style>
