<template>
	<!-- Main Content Section -->
	<div class="list-content">
		<div class="container-fluid">
				<div class="col-lg-6 mt-lg-5 listingmaplist-content">
					<div class="p-2">
						<b-badge v-if="category !== 0" class="m-1" variant="primary" @click="clearCategory"><i class="fas fa-times"></i> {{ getCategoryName(category) }}</b-badge>
						<b-badge v-if="amount !== 0" class="m-1" variant="primary" @click="clearAmount"><i class="fas fa-times"></i> {{ getAmountText(amount) }}</b-badge>
						<b-badge v-if="searchInput !== ''" class="m-1" variant="primary" @click="clearSearchInput"><i class="fas fa-times"></i> {{ searchInput }}</b-badge>
					</div>
					<div>
						<b-form class="row">
							<div class="col-md-4">
								<b-form-input
									id="inline-form-input-name"
									v-model="searchInput"
									class="mb-1"
									placeholder="Buscar"
									@input="loadDataFromEndpoint(1)"
								></b-form-input>
							</div>

							<div class="col-md-4">
								<b-input-group class="mb-2 mr-sm-2 mb-sm-0">
									<b-form-select 
										:options="categories"
										value-field="id"
										text-field="text"
										v-model="category"
										class="mb-1"
										@input="loadDataFromEndpoint(1)"
									></b-form-select>
								</b-input-group>
							</div>

							<div class="col-md-4">
								<b-input-group class="mb-2 mr-sm-2 mb-sm-0">
									<b-form-select 
										:options="rangeAmount"
										value-field="id"
										text-field="text"
										class="mb-1"
										v-model="amount"
									></b-form-select>
								</b-input-group>
							</div>
						</b-form>
					</div>


					<div class="listmapgrid-content listmaplist-content">
						<div class="row sorting-div">
							<div class="col-lg-12 col-md-12 col-sm-12 align-items-center d-flex">
								<div class="count-search">
									<p><strong>{{ total }}</strong> Resultados</p>
								</div>
							</div>
						</div>
						<div class="blog-listview">

							

							<div 
								class="empty-state text-center"
								v-if="total == 0 && show == false"
							>
								<div class="icon">
									<img 
										class="img-fluid avatar-img"
										style="width: 180px; opacity: 0.7; padding: 10px;"
										:src="require('../../../../assets/img/not-found-listing.png')" 
										alt=""
									/>
								</div>
								<div class="message">
									<p>¡Qué alegría verte por aquí!</p>
									<br/>
									<p><i>No hay registros disponibles en este momento</i>,<br/>
										pero eso solo significa que<br/>
										¡Siempre hay más por descubrir!</p>
									<p>¿Por qué no exploras otras categorías mientras tanto?</p>
									<a 
										class="btn btn-primary"
										href="/listing"
										>Explorar</a>
								</div>
							</div>

							<b-overlay :show="show" rounded="sm">
							<div class="card" 
								v-for="item in ListingMapList" 
								:key="item.id"
							>
								<div class="blog-widget">
									<div class="blog-img" style="max-width: 200px;">
										<router-link 
											:to="'/franquicia/'+item.link" 
										>
											<img 
												v-if="item.logo"
												:src="item.logo.route" 
												class="img-fluid  logo-image"
												style="max-width: 300px;"
												alt="blog-img"
											>
										</router-link>
										<div class="fav-item">
											<a href="javascript:void(0)" class="fav-icon">
												<i class="feather-heart"></i>
											</a>										
										</div>
									</div>
									<div class="bloglist-content">
										<div class="card-body">
											<div class="blogfeaturelink">
												<div 
													class="blog-features"
													v-if="item.categories"
												>
													<a 
														class="active" 
														v-for="(column, index) in item.categories" 
														:key="index" 
														@click="goToCategory(column.id)"
														aria-current="true" 
														data-bs-toggle="tab" 
													>
														<b-badge class="m-1" style="font-size: 12px;" variant="light">
															<i
																:class="column.icon" 
																alt="Dreamstrip Recommended"
															></i>
														
														{{ column.name }}
														</b-badge>
													</a>
												</div>
												<div 
													class="location-info"
													v-for="(column, index) in item.countries"
												>
													<img 
														:src="column.flag" 
														width="21"
														class="m-1"
														alt="author"
													>
												</div>
											</div>
											<h6>
												<router-link 
													:to="'/franquicia/'+item.link" 
												>
													{{item.name}}
												</router-link>
											</h6>
											<p 
												class="ratings"
												v-if="item.outstanding"
											>
												<span>Recomendado</span> 
											</p>
											<div class="amount-details">
												<div class="">
													<range-price 
                                                        :idCompany="item.id"
                                                    ></range-price>
												</div>
												<router-link 
													:to="'/franquicia/'+item.link" 
												>
													Ver Perfil
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
							</b-overlay>
						</div>

						<!--Pagination-->
						<div class="blog-pagination" v-if="total != 0">
							<nav>
							<ul class="pagination">
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('prev') }"
								>
									<a @click="nextPage('left')" href="#"  class="page-link"  aria-label="Previous">
										<i class="fas fa-regular fa-arrow-left"></i>
									</a>
								</li>
								<li class="justify-content-center pagination-center">
									<div class="pagelink">
										<ul>
											<li 
                                                class="page-item"
                                                v-for="(column, index) in pages" 
                                                :key="index" 
                                            >
												<a 
                                                    :class="{ 'page-link': true, 'active': page === column }"
                                                    @click="nextPage(column)"
                                                    href="#"
                                                >{{ column }}</a>
											</li>
										</ul>
									</div>
								</li>
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('next') }"
								>
                                    <a 
                                        class="page-link" 
                                        @click="nextPage('right')"
                                        href="#"
                                    >
                                        <i class="fas fa-regular fa-arrow-right"></i>
                                    </a>
                                </li>
							</ul>
						</nav>
						</div>
						<!--/Pagination-->
					</div>
				</div>
				<div class="col-lg-6 mt-lg-3 map-right">
					<GoogleMap 
						api-key="AIzaSyDz228dol3MOtegTgC_2Qh5vjHAf9y79qA" 
						style="width: 100%; 
						height: 100%" 
						ref="mapRef" 
						:center="center" 
						:zoom="4"
						:options="{
							zoomControl: true,
							mapTypeControl: true,
							scaleControl: true,
							streetViewControl: true,
							rotateControl: true,
							fullscreenControl: true
						}"
					>
					<MarkerCluster>
						<Marker 
							v-for="(location, i) in locations" 
							:options="{ 
										position: location, 
										label: {
											fontFamily: 'Fontawesome',
											text: '\uf299'
										},
										icon: location.logo,
										size: 20
									 }" 
							:key="i"
						>
							<InfoWindow>
								<div id="content">
									<div id="siteNotice"></div>
									<h2 id="firstHeading" class="firstHeading">{{ location.title }}</h2>
									<div id="bodyContent">
										<p> {{ location.description }}</p>
										<p>
										<a class="btn" :href="location.uri">
											Mas detalles 
										</a>
										</p>
									</div>
								</div>
							</InfoWindow>
						</Marker>
					</MarkerCluster>
					</GoogleMap>
				</div>
		</div>
	</div>
	<!-- /Main Content Section -->
</template>

<script>
import axios from 'axios'; 
import ListingMapList from '../../../../assets/json/listingMapList.json'
import { GoogleMap, Marker, MarkerCluster, CustomMarker, InfoWindow } from "vue3-google-map";



export default {
	components: { GoogleMap, Marker, MarkerCluster, CustomMarker, InfoWindow },
  setup() { 
    const center = { lat: 14.639032067506696, lng: -90.52786376234081 }

	const icon = {
		url: "https://cdn3.iconfinder.com/data/icons/softwaredemo/PNG/256x256/Circle_Grey.png", // url
		scaledSize: new google.maps.Size(50, 50), // scaled size
		origin: new google.maps.Point(0,0), // origin
		anchor: new google.maps.Point(0, 0) // anchor
	};

	// Inicializa locations como un array vacío
	const locations = [];

	// Hacer la solicitud GET al endpoint para obtener las ubicaciones de las empresas
	axios.get(process.env.VUE_APP_API_DIRECTORY + "companies-map?order=updated_at&by=asc&limit=351")
    .then(response => {
        // Recorre los datos para obtener latitud y longitud de cada empresa
        const data = response.data;
        let lat, lng;

        for (let i = 0; i < data.length; i++) {
            let lat, lng;
            let logoUrl = "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png"; // Imagen por defecto

            if (data[i].logo && data[i].logo.route) {
                logoUrl = data[i].logo.route;
            }

            const logo = {
                url: logoUrl, // url
                scaledSize: new google.maps.Size(50, 50), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(0, 0) // anchor
            };

            let title = data[i].name;
            let description = data[i].description;
            let uri = process.env.VUE_APP_WEBSITE + '/franquicia/' + data[i].link;

            if (Object.keys(data[i].data).length != 0) {
                // Inicializamos lat y lng antes de procesar los datos de la empresa
                lat = undefined;
                lng = undefined;

                for (let j = 0; j < data[i].data.length; j++) {
                    if (data[i].data[j].id == 35) {
                        lat = parseFloat(data[i].data[j].company_data.value);
                    }

                    if (data[i].data[j].id == 36) {
                        lng = parseFloat(data[i].data[j].company_data.value);
                    }
                    // Verificar si latitud y longitud están definidas antes de agregar la ubicación
                    if (lat !== undefined && lng !== undefined) {
                        locations.push({ lat, lng, logo, title, description, uri });
                    }
                }
            }
        }
    })
	.catch(error => {
		console.error('Error al obtener las ubicaciones de las empresas:', error);
	});

	console.log(locations)
    return { center, locations, icon }
  },
	data() {
		return {
			filter: false,
			ListingMapList: [],
			categories: [],
			searchInput: '',
			category: 0,
			total: 0,
			amount: 0,
			rangeAmount: [
				{ "id": 1, "text": "0,00 USD a 5.000,00 USD" },
				{ "id": 2, "text": "5.001,00 USD a 15.000,00 USD" },
				{ "id": 3, "text": "15.001,00 USD a 40.000,00 USD" },
				{ "id": 4, "text": "40.001,00 USD a 100.000,00 USD" },
				{ "id": 5, "text": "100.001,00 USD a 300.000,00 USD" },
				{ "id": 6, "text": "Superior a 300.001,00 USD" },
			],
			value1: 50,
			show: true,
			// variables para la paginacion
			page: 1,
			pages: 1,
			totalItems: 0,
			itemsPerPage: 10,
			loadingText: ''
		}
	},
	mounted() {
		


		this.loadDataFromEndpoint(0);
		this.fetchCategories();

	},

	computed: {
		isPageButtonDisabled() {
			return direction => {
				if (direction === 'prev') {
				return this.page === 1;
				} else if (direction === 'next') {
				return this.page === this.pages;
				}
			};
		}
	},

	methods: {
		loadDataFromEndpoint(action) {
            // Obtener parámetros de la URL
			this.show = true;
            const { category, amount, searchInput } = this.$route.query;
			const currentPage = this.page;

			if(action == 0){
				if(category){
					this.category = category
				}
				if(amount){
					this.amount = amount
				}
				if(searchInput){
					this.searchInput = searchInput
				}
			}

            // Construir el URL de la solicitud GET
            let url = process.env.VUE_APP_API_DIRECTORY + 
					'companies-list?order=updated_at&by=asc&page=' +
					this.page;

            // Agregar parámetros de filtro si están presentes en la URL
            if (this.category != '') {
                url += `&category_id=${this.category}`;
            }
            if (this.amount != '' || this.category != 0) {
                url += `&amount=${this.amount}`;
            }

            if (this.searchInput != '') {
                url += `&search=${this.searchInput}`;
            }

            // Realizar la solicitud GET
            axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + process.env.VUE_APP_TOKEN
                }
            })
            .then(response => {
                // Asignar los datos recibidos al arreglo ListingMapList
                this.ListingMapList = response.data.data;
				this.currentPage = response.data.current_page;
                this.lastPage = response.data.last_page;
                this.links = response.data.links;
				//datos para la paginacion
                // Actualizar datos de paginación
				//this.page = response.data.current_page;
				this.pages = response.data.last_page;
				this.total = response.data.total;
				this.page = currentPage;
				this.show = false;
            })
            .catch(error => {
                console.error('Error al cargar los datos:', error);
            });
        },
		goToCategory(category){
			this.$router.push('/listing?category=' + category);
			this.loadDataFromEndpoint(0);
			this.fetchCategories();
		},
		nextPage(orientation) {
			if (orientation === 'right' && this.page < this.pages) {
				this.page++;
			} else if (orientation === 'left' && this.page > 1) {
				this.page--;
			} else {
				this.page = orientation;
			}
			this.loadDataFromEndpoint(0);
		},
		async fetchCategories() {
			try {
				const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=300&type=0");
				// Actualiza la propiedad Category con los datos obtenidos
				this.categories = response.data.map(category => ({
				id: category.id, // Suponiendo que category.id es el valor deseado para el selector
				text: category.name // Suponiendo que category.name es el texto que se mostrará en el selector
				}));
			} catch (error) {
				console.error('Error fetching categories:', error);
			}
		},
		clearCategory() {
			this.category = 0;
			this.loadDataFromEndpoint(1);
		},
		clearSearchInput() {
			this.searchInput = '';
			this.loadDataFromEndpoint(1);
		},
		clearAmount() {
			this.amount = 0;
			this.loadDataFromEndpoint(1);
		},
		getCategoryName(categoryId) {
			// Buscar la categoría por su ID en el arreglo de categorías
			const category = this.categories.find(cat => cat.id === categoryId);
			// Si se encuentra la categoría, devolver su nombre; de lo contrario, devolver una cadena vacía
			return category ? category.text : '';
		},
		getAmountText(amountId) {
			// Buscar el rango de inversión por su ID en el arreglo de rangos de inversión
			const amount = this.rangeAmount.find(range => range.id === amountId);
			// Si se encuentra el rango de inversión, devolver su texto; de lo contrario, devolver una cadena vacía
			return amount ? amount.text : '';
		}
	},
}
</script>


<style>
.roundskin .slider-connect {
  background: var(--slider-connect-bg, #3c4549) !important;
  cursor: pointer;
  height: 13px;
}
.roundskin .slider-base, .slider-connects {
	height: 13px !important;
}
.roundskin .slider-base,
.slider-connects {
  height: 4px;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #3c4549) !important;
  border: 1px solid var(--slider-tooltip-bg, #3c4549) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.roundskin-slider .slider-horizontal .slider-handle {
  top: calc(
    (var(--slider-handle-height, 30px) - var(--slider-height, 12px)) / 2 * -1 + 2px
  );
  width: 24px;
  height: 24px;
  position: absolute;
  display: block;
  border: 3px solid #fff;
  background-color: #3c4549;
  border-radius: 24px;
  box-shadow: none;
}
.logo-image {
  max-width: 100%; /* Establece un ancho máximo */
  height: auto; /* Altura automática para mantener la proporción */
  object-fit: contain; /* Escalar la imagen para que quepa dentro del contenedor manteniendo la proporción */
}
</style>
<style src="@vueform/slider/themes/default.css"></style>