<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                :title="title" 
                :name="name" 
                :text="text" 
                :text1="text1" 
                :cover="cover" 
            />

            <bloggridlist 
                :publicationType="this.publicationType" 
            />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    props: ['publicationType'],
    data() {
        return {
            title: this.$route.meta.title,
            text: "Bienvenido",
            cover: "news.jpg",
            text1: this.$route.meta.title,
            name: "/",
        }
    },
}
</script>