<template>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      variant="white"
      opacity="1"
    >
    <div>
    <span v-if="inversionInicial === 0 && inversionFinal === 0">
        A consultar
    </span>
    <span v-else-if="inversionInicial === inversionFinal">
        {{ formatMoney(inversionInicial) }} USD
    </span>
    <span v-else>
        Desde 
        {{ formatMoney(inversionInicial) }} USD 
        <br/>
        hasta 
        {{ formatMoney(inversionFinal) }} USD
    </span> 
    </div>
    </b-overlay>

</template>

<script>
import axios from 'axios'; // Importar Axios

export default {
      props: {
            idCompany: Number
      },
      data() {
          return {
        	inversionInicial: 0,
            inversionFinal: 0,
            show: true
          }
      },
      mounted() {
        this.fetchTotalInvestment(this.idCompany);
      },
      methods: {
        async fetchTotalInvestment(companyId) {
            this.show = true
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY+'companies/'+companyId, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer '+process.env.VUE_APP_TOKEN,
                    },
                });

                if (response && response.data && response.data.data) {

                    // Itera sobre los datos de la respuesta para obtener inversionInicial e inversionFinal
                    response.data.data.forEach(item => {
                        if (item.id === 8) {
                            this.inversionInicial = item.company_data.value;
                        } else if (item.id === 9) {
                            this.inversionFinal = item.company_data.value;
                        }
                    });
                    this.show = false

                } else {
                    console.error("Error en la API");
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching total investment for company ${companyId}:`, error);
                return null;
            }
        },
        formatMoney(amount) {
            amount = parseInt(amount)
            return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
      }

  }
</script>