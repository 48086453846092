<template>
<!-- Destination Banner -->
    <section class="section-blk destination-blk">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="title-blk">
                        <div class="title-left">
                            <h2>Diversidad de Oportunidades </h2>
                            <h5 v-if="IndexDestination.length > 0">
                                Contamos con mas de {{ IndexDestination.length }} categorías de Franquicias
                            </h5>
                        </div>
                        <div class="theme-btn">
                            <router-link to="/listing" class="search-btn">
                                Explorar Franquicias
                                <span>
                                    <i class="feather-arrow-right"></i>
                                </span>
                            </router-link>
                        </div>
                    </div>
                    
                    <div class="destination-carousel">
                        <b-overlay 
                            :show="show" 
                            rounded="sm"
                            opacity="1"
                            variant="white"
                        >
                        <div class="destination-slider owl-carousel owl-theme">
                            <Carousel 
                                :wrap-around="true" 
                                :transition="500"
                                :breakpoints="breakpoints"
                            >
                                <Slide 
                                    v-for="item in IndexDestination" 
                                    :key="item.id"
                                >
                                    <div class="destination-widget">
                                        <a 
                                            
                                            class="desti-link"
                                        >
                                            <img 
                                                v-if="item.image"
                                                :src="item.image.route" 
                                                class="img-fluid" 
                                                alt="find your destination"
                                            >
                                            <img 
                                                v-else
                                                :src="require(`../../../assets/img/category/category-01.jpg`)" 
                                                class="img-fluid" 
                                                alt="find your destination"
                                            >
                                            <div class="desti-country-list">
                                                <span class="desti-btn">{{ item.name }}</span>
                                            </div>
                                        </a>
                                        <div class="overlay-blk">
                                            <h3>{{ item.location }}</h3>
                                            <p>{{ item.name }}</p>
                                            <div class="theme-btn">
                                                <router-link 
                                                    :to="'/listing?category='+item.id" 
                                                    class="search-btn"
                                                >
                                                    Ver Franquicias
                                                    <span>
                                                        <i class="feather-arrow-right"></i>
                                                    </span>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <Pagination />
                                </template>
                            </Carousel>
                        </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 mb-6">
                <div class="flex-style text-center p-4">
                    <router-link to="/categories" class="btn btn-primary rounded-pill">Ver todas las categorías</router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- /Destination Banner -->

    <!-- Service Banner -->
    <section class="section-blk service-blk">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="service-info-card d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <div class="img-blk">
                                <img src="../../../assets/img/service-img.jpg" class="img-fluid" alt="Dreamstrip Services">
                            </div>
                        </div>
                        <div class="service-content flex-grow-1">
                            <h2>Por qué Franquiciar es la Elección Correcta para ti</h2>
                            <div class="body-content">
                                <p style="font-size: 12px;">Emprender el camino hacia el éxito empresarial requiere de una base sólida, y eso es precisamente lo que Franquiciar ofrece. Entendemos que cada aspirante a dueño de negocio es único, y nuestro enfoque está diseñado para satisfacer tus necesidades distintivas.</p>
                                <p style="font-size: 12px;">Desde una amplia gama de oportunidades probadas de franquicias hasta un apoyo personalizado a lo largo de tu aventura empresarial, creemos en potenciar tu historia de éxito. Elegir Franquiciar no es solo una decisión comercial; es una asociación estratégica dedicada a liberar todo tu potencial.</p>
                            </div>
                            
                            <div class="row">
                                <div class="p-2 d-flex align-items-center col-lg-6 col-md-6">
                                    <div class="feature-img">
                                        <img 
                                            src="../../../assets/img/service/atom.png" 
                                            alt="Service Feature 1"
                                            style="width: 30px;"
                                        >
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6>Ecosistema</h6>
                                    </div>
                                </div>
                                <div class="p-2 d-flex align-items-center col-lg-6 col-md-6">
                                    <div class="feature-img">
                                        <img 
                                            src="../../../assets/img/service/network.png" 
                                            alt="Service Feature 1"
                                            style="width: 30px;"
                                        >
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6>Redes de Negocios</h6>
                                    </div>
                                </div>
                                <div class="p-2 d-flex align-items-center col-lg-6 col-md-6">
                                    <div class="feature-img">
                                        <img 
                                            src="../../../assets/img/service/quality.png" 
                                            alt="Service Feature 1"
                                            style="width: 30px;"
                                        >
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 style="font-size: 14px;">Franquicias certificadas</h6>
                                    </div>
                                </div>
                                <div class="p-2 d-flex align-items-center col-lg-6 col-md-6">
                                    <div class="feature-img">
                                        <img 
                                            src="../../../assets/img/service/experience.png" 
                                            alt="Service Feature 1"
                                            style="width: 30px;"
                                        >
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6>Experiencia</h6>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Service Banner -->

        <!-- Promo Banner -->
        <section class="section-blk promo-blk">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="flex-style text-center">
                            <div>
                                <h3>Tu camino hacia una inversión sólida comienza aquí.</h3>
                            </div>
                        </div>
                    <div class="promo-style">
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Promo Banner -->

    <section class="cta-section">
		    <div class="container">
			    <div class="row align-items-center">
				     <div class="col-lg-7">
					      <div class="cta-content">
							<h3>¿CÓMO SABER SI MI NEGOCIO   <br>
								<span> ES FRANQUICIABLE?</span>							     
							  </h3>
							  <p> DEBE SER UN NEGOCIO PROBADO.</p>
                              <p>DEBE SER UN NEGOCIO RENTABLE.</p>
                              <p> SE DEBE DE PODER REPLICAR.</p>
                              <p>QUE EXISTA UNA DEMANDA COMPROBADA EN EL MERCADO POR LOS PRODUCTOS O SERVICIOS QUE OFRECE.</p>
							  <p>QUE TENGA ALGÚN ELEMENTO DIFERENCIADOR O ALGUNA VENTAJA COMPETITIVA IMPORTANTE.</p>
                              <div class="cta-btn">
							     <router-link 
								 	to="contact" 
								 	class="btn-primary postad-btn"
								 >
                                    Contáctanos
								</router-link>						  
							  </div>
						  </div>
					 </div>
				     <div class="col-lg-5">
					       <div class="cta-img about-img">
						        <img src="../../../assets/img/cta-img3.jpg" class="img-fluid" alt="CTA">
						   </div>
					 </div>
				</div>
			</div>
		</section>

</template>

<script>
import axios from 'axios';
import IndexDestination from '../../../assets/json/indexDestination.json'
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

export default {
    data() {
        return {
            IndexDestination: [],
            show: true,
            countCategories: 0,
            settings: {
				itemsToShow: 1
			},
			breakpoints: {
                200: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                400: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3.25,
                    snapAlign: 'center',
                },
            },
        };
    },
    components: {
		Carousel,
	    Slide,
		Pagination,
		Navigation,
	},
    mounted() {
        this.fetchCategories(); // Llama a fetchCategories cuando el componente se monta
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=300&type=0");
                // Actualiza IndexDestination con los datos obtenidos
                this.IndexDestination = response.data;
                this.show = false;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
    }
};
</script>


<style scoped>

.destination-widget {
	margin-right: 24px;
}
.carousel__slide {
  padding: 20px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>