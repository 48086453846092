<template>
    <!-- Login Section -->		
		<div class="login-content">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap password-form">
							
							<div class="login-header">
								<p>Ingresa el código de 6 digitos que enviamos a tu correo electrónico.</p>								
							</div>
							
							<!-- Login Form -->
							<b-form action="login">
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="fab fa-buromobelexperte"></i>
										<b-form-input 
                                            type="number" 
                                            min="100000" 
                                            max="999999"
                                            v-model="code"
                                            :state="codeState"
                                            class="form-control" 
                                            aria-describedby="code-feedback"
                                            placeholder="Código de confirmación" 
                                            required
                                        />

                                        <b-form-invalid-feedback 
                                            id="code-feedback"
                                            v-if="code && !codeState" 
                                        >
                                            El código debe ser un número de 6 dígitos
                                        </b-form-invalid-feedback>
									</div>
								</div>
                                <b-overlay
                                    :show="show"
                                    rounded
                                    opacity="1"
                                    spinner-small
                                    spinner-variant="primary"
                                    @hidden="onHidden"
								>
                                    <b-button 
                                        variant="primary w-100 login-btn" 
                                        type="submit"
                                        @click.prevent="active"
                                    >
                                        Activar
                                    </b-button>		
                                </b-overlay>							
							</b-form>
							<router-link 
                                to="/" 
                                class="back-home"
                            >
                                <i class="fas fa-regular fa-arrow-left me-1"></i> 
                                Regresar
                            </router-link>		
							<!-- /Login Form -->
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<!-- /Login Section -->
</template>

<script>
import axios from 'axios';
var urlSignupActivate = process.env.VUE_APP_API_DIRECTORY + 'auth/signup/activate/';

export default {
    name: 'ActivateSection',
    data: () => ({
        code: '',
        show: false,
        error: false,
        codeValid: false,
        valid: true,
        rules: {
            required: (value) => !!value || 'Requerido.',
            length: (v) => v.length == 6 || 'El código es de 6 caracteres',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'El formato de correo electrónico inválido'
            },
        },
    }),
    computed: {
      codeState() {
        const codePattern = /^[0-9]{6}$/; // Regular expression for a 6-digit number
        this.codeValid = codePattern.test(this.code);
      },
    },
    methods: {
        active() {
            this.show = true
            axios
                .get(urlSignupActivate + this.code)
                .then((response) => {
                    this.$swal({
                        title: `¡Hola!`,
                        html: `Activaste tu cuenta satisfactoriamente.<br>Inicia sesión para disfrutar de nuestro Directorio de Franquicias`,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#003a56',
                        confirmButtonText: 'Aceptar',
                    })
                    this.show = false
                    this.$router.push('/login')
                })
                .catch((error) => {
                    console.log(error)
                    this.error = true
                    this.show = false
                })
        },
    },
}

</script>