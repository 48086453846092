<template>

	<!-- CTA Section -->
	<div class="container">
		<div class="list-sec">
			<div class="row align-items-center">
					<div class="col-lg-7">
						<div class="listee-content aos" data-aos="fade-up">
							<h3>Estrategias, Soluciones, Plan</h3>
							<p>Los asesores de franquicias son profesionales especializados en este campo, que podrian orientarte, aconsejarte y brindarte recomendaciones practicas, para que puedas analizar las propuestas de franquicias existentes y elaborar tu plan de negocios.</p>
						</div>
					</div>
					<div class="col-lg-5">
						<div class="listee-img aos" data-aos="fade-up">
							<img src="../../../assets/img/space-img.png" class="img-fluid" alt="CTA">
						</div>
					</div>
			</div>
		</div>
	</div>	
	<!-- /CTA Section -->


		<!-- Wedding Section -->
		<section class="wedding-section">
		<div class="container">
			<div class="row">
				<div class="col-md-12 aos" data-aos="fade-up">
					<div class="section-heading-two text-center">
						<h2>Un ecosistema de servicios</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 col-md-6">
					<!-- Category -->
					<div class="wedding-wrap aos" data-aos="fade-up" v-for="item in IndexTwoCategory" :key="item.id">
						<div class="wedding-img">
							<router-link to="/categories">
								<img class="img-fluid" :src="require(`../../../assets/img/service/${item.image}`)" alt="locations">
							</router-link>
						</div>
						<div class="wedding-content">
							<h6><router-link to="categories">{{ item.title }}</router-link></h6>
							<p>{{ item.content }}</p>
						</div>
					</div>
					<!-- /Category -->
				</div>
				
				<div class="col-lg-6 col-md-6">
					<!-- Category -->
					<div class="wedding-wrap aos" data-aos="fade-up" v-for="item in IndexTwoWeds" :key="item.id">
						<div class="wedding-img">
							<router-link to="/categories">
								<img class="img-fluid" :src="require(`../../../assets/img/service/${item.image}`)" alt="locations">
							</router-link>
						</div>
						<div class="wedding-content">
							<h6><router-link to="categories">{{ item.title }}</router-link></h6>
							<p>{{ item.content }}</p>
						</div>
					</div>
					<!-- /Category -->
				</div>
			</div>
		</div>
	</section>
<!-- /Wedding Section -->

</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexCelebrate from '../../../assets/json/indexCelebrate.json'
import IndexTwoCategory from '../../../assets/json/indexTwoCategory.json'
import IndexTwoWeds from '../../../assets/json/indexTwoWeds.json'

export default {
	data() {
		return {
			IndexCelebrate: IndexCelebrate,
			IndexTwoWeds: IndexTwoWeds,
			IndexTwoCategory: IndexTwoCategory,
			settings: {
				itemsToShow: 1,
				snapAlign: "center"
			},
		
			breakpoints: {
				// 700px and up
				700: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 1,
					snapAlign: "start"
				}
			},
		}
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>