<template>
    <div class="main-wrapper">


        <navbarthree></navbarthree>
        
        <div class="page-wrapper">
            <indexhome />

            <indexthreedestination />

            <indexsubscribe />

            <indexoffer />

            <indexpromo />

            <indextestimonial />

            <indexactivity />

            <foot />

        </div>
    </div>
</template>