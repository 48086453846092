<template>
    <!-- Login Section -->		
	

		<div class="login-content">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap register-form">
							<!-- Login Form -->
							<b-form action="/login">
							    <div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-user"></i>
										<b-form-input 
											type="text" 
											class="form-control" 
											v-model="model.name"
											placeholder="Nombre y Apellido" 
										/>
									</div>
								</div>
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-mail"></i>
										<b-form-input 
											type="text" 
											class="form-control" 
											v-model="model.email"
											placeholder="Email" 
										/>
									</div>
								</div>
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-phone"></i>
										<b-form-input 
											type="text" 
											v-model="model.phone"
											class="form-control" 
											placeholder="Teléfono" 
										/>
									</div>
								</div>
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-globe"></i>
										<b-form-input 
											type="text" 
											v-model="model.country"
											class="form-control" 
											placeholder="País" 
										/>
									</div>
								</div>
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-user"></i>
										<vue-select 
											class="select"
											:options="idioms"
											v-model="model.timeInvestment"
											:settings="{ 
												dropdownAutoWidth: true, 
												width: '100%'
											}"
											placeholder="¿Cuándo piensa iniciar operaciones?"
										/>
									</div>
								</div>
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-dollar-sign"></i>
										<b-form-input 
											type="text" 
											v-model="model.amountInvestment"
											class="form-control" 
											placeholder="¿Cuanto dinero piensa invertir en una franquicia? (En dólares)" 
										/>
									</div>
								</div>
								<div class="form-group">
									<div class="pass-group group-img">
										<span class="lock-icon"><i class="feather-lock"></i></span>
											<b-form-input
												v-if="showPassword"
												type="text"
												class="form-control pass-input"
												v-model="model.password"
												placeholder="password"
											/>
											<b-form-input 
												v-else 
												type="password"
												class="form-control pass-input"
												placeholder="Contraseña" 
												v-model="model.password" 
											/>
										<span
											class="toggle-password"
											@click="toggleShow"
											:class="{
											'feather-eye': showPassword,
											'feather-eye-off': !showPassword,
											}"
										></span>
									</div>
								</div>
								<b-overlay
								:show="show"
								rounded
								opacity="1"
								spinner-small
								spinner-variant="primary"
								@hidden="onHidden"
								>
									<b-button 
										variant="primary w-100 login-btn" 
										type="submit"
										@click.prevent="signup"
									>
										Registrarse
									</b-button>
								</b-overlay>		
								<div class="register-link text-center">
									<p>¿Ya tienes una cuenta? <router-link class="forgot-link" to="/login">Ingresar</router-link></p>
								</div>							
							</b-form>
							<!-- /Login Form -->
											
						</div>
					</div>
				</div>
				
			</div>
		</div>

		<section class="activities-blk pb-4">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="row row-gap-14">
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div 
												class="icon-blk overflow-hidden ml-2"
												style="    margin-left: 10px;"
											>
												<i class="far fa-heart fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Guarda tus franquicias favoritas
											</h4>
										</div>
									</div>									
								</div>
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div class="icon-blk overflow-hidden"
												style="    margin-left: 10px;">
												<i class="fas fa-poll fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Opina en las encuestas
											</h4>
										</div>
									</div>									
								</div>
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div class="icon-blk overflow-hidden"
												style="    margin-left: 10px;">
												<i class="fas fa-envelope fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Recibe notificaciones por Email
											</h4>
										</div>
									</div>									
								</div>
							</div>
							<div class="row row-gap-14 mt-4">
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div class="icon-blk overflow-hidden"
												style="    margin-left: 10px;">
												<i class="fas fa-project-diagram fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Conéctate con las franquicias
											</h4>
										</div>
									</div>									
								</div>
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div class="icon-blk overflow-hidden"
												style="    margin-left: 10px;">
												<i class="far fa-calendar-check fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Solicita Reuniones
											</h4>
										</div>
									</div>									
								</div>
								<div class="col-sm-6 col-lg-4">
									<div class="shadow-box d-flex">
										<div class="flex-shrink-0">
											<div class="icon-blk overflow-hidden"
												style="    margin-left: 10px;">
												<i class="fas fa-hands-helping fa-2x"></i>
											</div>
										</div>
										<div class="flex-grow-1 ms-3">
											<h4 class="m-3">
												Asesorate sin cargos
											</h4>
										</div>
									</div>									
								</div>
							</div>
					</div>			
				</div>
			</div>
		</section>
		<!-- /Login Section -->
</template>

<script>
  import axios from 'axios';

	export default {
		data() {
		 return {
		 	title: "REGISTRO EXCLUSIVO DE INVERSIONISTAS",
		 	title1: "User Profile",
			showPassword: false,
		 	password: null,
			show: false,
			title01: "Profile",
		 	title2: "User Profile",
			showPassword2: false,
		 	password2: null,
			idioms: [
						"De inmediato", 
						"De 6 a 12 meses", 
						"Más de 12 meses", 
						"Dos años"
					],
			model: {
				name: '',
				phone: '',
				country: '',
				email: '',
 				timeInvestment: '',
				amountInvestment: '',
				emailRules: [
				(v) => !!v || 'Correo electrónico es requerido',
				(v) => /.+@.+\..+/.test(v) || 'El formato de correo electrónico es invalido',
				],
				password: '',
				passwordRules: [(v) => !!v || 'La contraseña es requerida'],
			},
		};
	},
	computed: {
		buttonLabel() {
			return this.showPassword ? "Hide" : "Show";
		},
	},
	methods: {
		toggleShow() {
		 	this.showPassword = !this.showPassword;
		},
		signup() {
			this.show = true
			axios.post(process.env.VUE_APP_API_DIRECTORY + 'auth/signup', {
				name: this.model.name,
				lastname: 'N/A',
				email: this.model.email,
				phone: this.model.phone,
				country: this.model.country,
				email: this.model.email,
				password: this.model.password,
				password_confirmation: this.model.password,
				country_id: 234,
				extra: '{"timeInvestment":"'+this.model.timeInvestment+'","amountInvestment":"'+this.model.amountInvestment+'"}',
				type: 1,
			})
			.then((response) => {

				this.$swal.fire({
					title: `¡Bienvenido!`,
					html: `Creaste tu cuenta satisfactoriamente.<br> Hemos enviado a tu correo electrónico un <strong>código para activar tu cuenta.</strong>`,
					showCancelButton: false,
					showConfirmButton: true,
					confirmButtonColor: '#003a56',
					confirmButtonText: 'Aceptar',
				})
				this.$router.push('/activate-account')
				this.loading = false
				this.show = false

			})
			.catch((error) => {
				if (error.response) {
					switch (error.response.status) {
						case 401:
						this.dialog = true
						this.error = 'Correo electrónico o contraseña incorrecta'
						break
						case 422:
						this.dialog = true
						this.error = 'Datos inválidos'
						break
						default:
						this.dialog = true
						this.error = 'Ocurrió un error'
						break
					}
					this.$swal({
						title: 'Registro inválido',
						text:
						'Se ha producido un error durante el registro de los datos',
						icon: 'error',
						showCancelButton: false,
						confirmButtonColor: '#e94e1b',
						showConfirmButton: true,
					})
					this.$swal.showValidationMessage(`Solicitud rechazada: ${this.error}`)
				}
				this.loading = false
				this.show = false
			})
		},
	},
	}
</script>