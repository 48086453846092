<template>
    <!-- Blog Section -->
		<section class="section-blk blog-block">
			<div class="container">				
				<div class="row text-center">		
					<div class="col-md-12">	
						<div class="title-blk justify-content-center text-center">
							<h2>CONOCE MÁS SOBRE EL MUNDO DE LAS FRANQUICIAS</h2>
						</div>	
						<div class="testim-slider owl-carousel owl-theme">
							<b-overlay 
                                        :show="show" 
                                        rounded="sm"
                                        opacity="1"
                                        variant="white"
                                    >
							<Carousel 
								:wrap-around="true" 
								:settings="settings" 
								:breakpoints="breakpoints"
								snapAlign="center"
							>
                				<Slide 
									v-for="item in publications" 
									:key="item.id"
								>
									<div class="blog-slide">
										<div class="blog grid-blog" style="max-width: 424px; height: 490px;">
											<div 
												class="blog-image"
												
											>
												<router-link 
													:to="'/actualidad/'+item.slug"
												>
													<img 
														class="img-fluid"
														v-if="item.logo" 
														:src="item.logo.route" 
														alt="Post Image"
													>
													<img 
														v-else
														class="img-fluid" 
														:src="require(`../../../assets/img/blog/blog-1.jpg`)" 
														alt="Post Image"
													>
												</router-link>
												<div class="fav-item">

                                                    <b-badge v-if="item.type == 'news'" variant="secondary"><i class="far fa-newspaper" style="color: white;"></i>&nbsp; Noticias</b-badge>
													<b-badge v-else-if="item.type == 'interviews'" variant="secondary"><i class="far fa-comments" style="color: white;"></i> &nbsp; Entrevistas</b-badge>
													<b-badge v-else variant="secondary"><i class="fas fa-play" style="color: white;"></i>&nbsp; Videos</b-badge>
                                                </div>	
												<div :class="item.class">
													<p>{{item.badge}}</p>
												</div>
											</div>
											<div class="blog-content">
												<ul class="entry-meta meta-item mb-0">
													<li>
														<div class="post-author">
															<div class="post-author-img">
																<img 
																	:src="require('../../../assets/img/user.png')" 
																	alt="Post Author"
																>
															</div>
															<a href="javascript:void(0);" class="mb-0"> <span> {{item.author}} </span></a>
														</div>
													</li>
													<li class="date-icon"><i class="fa-solid fa-calendar-days"></i> {{ tiempoTranscurrido(item.date) }}</li>
												</ul>
												<h3 class="blog-title">
													<router-link 
														:to="'/actualidad/'+item.slug"
													>
														{{item.title}}
													</router-link>
												</h3>
												<p class="viewlink">
													<router-link 
														:to="'/actualidad/'+item.slug"
													>
														Ver detalles<i class="feather-arrow-right"></i>
													</router-link></p>
											</div>
										</div>
									</div>
								</Slide>
                    				<template #addons>
										<Pagination />
                    				</template>
            				</Carousel>
							</b-overlay>
						</div>
					</div>
				</div>
			</div>
		</section>
</template>
<script>
import axios from 'axios';
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

	export default {
		props: ['publicationType'],
		data() {
			return {
				publications: [],
				show: true,
				settings: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				breakpoints: {
					// 700px and up
					767: {
						itemsToShow: 2,
						snapAlign: "center"
					},
					// 991 and up
					991: {
						itemsToShow: 2,
						snapAlign: "start"
					},
					// 1024 and up
					1000: {
						itemsToShow: 3,
						snapAlign: "start"
					}
				},
				types: [
					{ code: 'blogs', name: 'Blog' },
					{ code: 'news', name: 'Noticias' },
					{ code: 'events', name: 'Eventos' },
				],
			};
		},
		components: {
			Carousel,
			Slide,
			Pagination,
			Navigation,
		},
		mounted() {
			this.fetchPublications();
		},
		methods: {
			async fetchPublications() {
				try {
					const response = await axios.get(
						process.env.VUE_APP_API_DIRECTORY + 
						"publications?&order=created_at&by=desc&active=1");
					// Actualiza IndexDestination con los datos obtenidos
					this.publications = response.data.data;
					this.show = false;
				} catch (error) {
					console.error('Error fetching categories:', error);
				}
			},
			tiempoTranscurrido(fecha) {
				const fechaActual = new Date();
				const fechaOcurrencia = new Date(fecha);

				// Calcula la diferencia en milisegundos
				const diferencia = fechaActual.getTime() - fechaOcurrencia.getTime();

				// Convierte la diferencia de milisegundos a segundos, minutos, horas, días, meses y años
				const segundosTranscurridos = Math.floor(diferencia / 1000);
				const minutosTranscurridos = Math.floor(segundosTranscurridos / 60);
				const horasTranscurridas = Math.floor(minutosTranscurridos / 60);
				const diasTranscurridos = Math.floor(horasTranscurridas / 24);
				const mesesTranscurridos = Math.floor(diasTranscurridos / 30);
				const añosTranscurridos = Math.floor(mesesTranscurridos / 12);

				if (añosTranscurridos > 0) {
					if (añosTranscurridos === 1) {
						return 'Hace un año';
					} else {
						return `Hace ${añosTranscurridos} años`;
					}
				} else if (mesesTranscurridos > 0) {
					if (mesesTranscurridos === 1) {
						return 'Hace un mes';
					} else {
						return `Hace ${mesesTranscurridos} meses`;
					}
				} else if (diasTranscurridos > 0) {
					if (diasTranscurridos === 1) {
						return 'Ayer';
					} else {
						return `Hace ${diasTranscurridos} días`;
					}
				} else if (horasTranscurridas > 0) {
					return `Hace ${horasTranscurridas} horas`;
				} else if (minutosTranscurridos > 0) {
					return `Hace ${minutosTranscurridos} minutos`;
				} else {
					return `Hace ${segundosTranscurridos} segundos`;
				}
			},
		}
	};
</script>

<style>
.blog-slide {
	margin-right: 24px;
}

.fav-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    font-size: 14px;
}
</style>