<template>
				<div class="row">
					<div class="col-md-12 col-lg-12">
						<div class="login-wrap">
							
							<div class="login-header">
								<p>Para continuar inicie sesión</p>								
							</div>
							
							<!-- Login Form -->
							<b-form ref="form" v-model="valid" class="form">
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-mail"></i>
										<b-form-input 
											type="text" 
											class="form-control" 
											placeholder="Usuario" 
											v-model="model.email"
                  							:rules="[rules.required, rules.email]"
										/>
									</div>
								</div>
								<div class="form-group">
									<div class="pass-group group-img">
										<span class="lock-icon"><i class="feather-lock"></i></span>
											<b-form-input
												v-if="showPassword"
												type="text"
												class="form-control pass-input"
												v-model="model.password"
												placeholder="Contraseña"
												:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  								:rules="[rules.required, rules.min]"
                  								:type="show ? 'text' : 'password'"

											/>
											<b-form-input 
												v-else 
												type="password"
												class="form-control pass-input"
												placeholder="Contraseña" 
												v-model="model.password" 
											/>
										<span
											class="toggle-password"
											@click="toggleShow"
											:class="{
											'feather-eye': show,
											'feather-eye-off': !show,
											}"
										></span>
									</div>
								</div>
								<div class="row">								
									<div class="col-md-12 col-sm-12">
										<div class="text-md-end">
											<a 
												class="forgot-link" 
												href="/forgot-password"
											>
												¿Olvidaste tu contraseña?
											</a>
										</div>
									</div>									
								</div>
								<b-alert
								:show="erroMessage"
								fade
								variant="warning"
								>
									{{ error }}
								</b-alert>

								<b-overlay
								:show="show"
								rounded
								opacity="1"
								spinner-small
								spinner-variant="primary"
								@hidden="onHidden"
								>
								<b-button 
									variant="primary w-100 login-btn" 
									type="submit" 
									@click.prevent="login"
								>
									Ingresar
								</b-button>
								</b-overlay>
								<div class="register-link text-center">
									<p>
										<a
											class="forgot-link" 
											href="/signup"
										>
											REGISTRATE GRATIS AHORA
										</a>
									</p>
								</div>							
							</b-form>
							<!-- /Login Form -->
											
						</div>
					</div>
				</div>
</template>

<script>
  import axios from 'axios';

	export default {
		 data() {
		 return {

		 	title: "Profile",
		 	title1: "User Profile",
			showPassword: false,
			erroMessage: false,
		 	password: null,
			title01: "Profile",
		 	title2: "User Profile",
			showPassword2: false,
		 	password2: null,
			error: '',
			valid: true,
			dialog: false,
			loading: false,
			show: false,
			password: 'Password',
			rules: {
				required: (value) => !!value || 'Requerido',
				min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
				email: (value) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'El formato de correo electrónico inválido'
				},
			},
			model: {
				email: '',
				remenberMe: '',
				emailRules: [
				(v) => !!v || 'Correo electrónico es requerido',
				(v) => /.+@.+\..+/.test(v) || 'El formato de correo electrónico es invalido',
				],
				password: '',
				passwordRules: [(v) => !!v || 'La contraseña es requerida'],
			},
			lazy: false,

		};
	},
	computed: {
		buttonLabel() {
			return this.showPassword ? "Hide" : "Show";
		},
	},
	created() {
		// localStorage.clear();
    },
	methods: {
		toggleShow() {
		 	this.showPassword = !this.showPassword;
		}, 
		onHidden() {
			// Return focus to the button once hidden
			this.$refs.button.focus()
		},
		validate() {
			if (this.$refs.form.validate()) {

				this.login()

			}
		},
		login() {
			this.show = true
			this.erroMessage = false
			axios.post(process.env.VUE_APP_API_DIRECTORY + 'auth/login', {
				email: this.model.email,
				password: this.model.password,
			})
			.then((response) => {

				localStorage.setItem('tokenSession', response.data.access_token);
				localStorage.setItem('userId', response.data.user.id);
				localStorage.setItem('userName', response.data.user.name);
				localStorage.setItem('userLastname', response.data.user.lastname);
				localStorage.setItem('userAvatar', response.data.user.avatar.route);
				localStorage.setItem('roles', response.data.user.roles);
				
				window.location.reload();
			})
			.catch((error) => {
				if (error.response) {
					this.show = false
					switch (error.response.status) {
						case 401:
						this.dialog = true
						this.error = 'Correo electrónico o contraseña incorrecta'
						break
						case 422:
						this.dialog = true
						this.error = 'Datos inválidos'
						break
						default:
						this.dialog = true
						this.error = 'Ocurrió un error'
						break
					}
				}
				this.loading = false
				this.erroMessage = true
			})
		},

	},
};
</script>
<style>
.login-wrap{
	border: none !important;
	box-shadow: none !important;
	margin-left: 19px;
}
</style>