<template>
    <!-- Header -->
    <header class="header">
        <navbartwoHeader></navbartwoHeader>
        <div class="container">
            <nav class="navbar navbar-expand-lg header-nav">
                <div class="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);">
                        <span class="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </a>
                    <router-link to="/" class="navbar-brand logo">
					    <img src="../assets/img/logo.svg" class="img-fluid" alt="Logo" style="width:240px;">
				    </router-link>	
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link to="/" class="menu-logo">
                            <img src="../assets/img/logo.svg" class="img-fluid" alt="Logo"  style="width:240px;">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
                    </div>

                    
                    
                    <ul class="navbar-nav main-nav my-2 my-lg-0">


                        <li v-if="isLoggedIn" class="d-sm-block d-md-none">
                            <router-link to="/add-listing">
                                <i class="fa-solid fa-plus"></i> Nueva Ficha
                            </router-link>
                        </li>
                        <li v-if="isLoggedIn" class="d-sm-block d-md-none">
                            <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown" aria-expanded="false">
                                <img 
                                    :src="userAvatar" 
                                    alt=""
                                >
                                <span>{{ userData }}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <router-link class="dropdown-item" to="/dashboard">Mi Cuenta</router-link>
                                <router-link class="dropdown-item" to="/profile">Configuraciones</router-link>
                                <router-link class="dropdown-item" to="/login">Cerrar Sesión</router-link>
                            </div>
                        </li>	
                        <li v-if="!isLoggedIn" class="d-sm-block d-md-none">
                            <router-link 
                                to="/login"
                            >
                                <i class="fa-solid fa-plus"></i> Inciar Sesión
                            </router-link>
                        </li>
                        <li v-if="!isLoggedIn" class="d-sm-block d-md-none">
                            <router-link 
                                to="/signup"
                            >
                                <i class="fa-solid fa-plus"></i> Crea una cuenta
                            </router-link>
                        </li>					
                
                        
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void()">Franquiciar <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'about' ? 'active' : 'notactive'"><router-link to="/about">¿Qué es Franquiciar?</router-link></li>
                                <li :class="currentPath == 'prpricingofile' ? 'active' : 'notactive'"><router-link to="/pricing">Registra tu Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/howitworks">Tu Asesor</router-link>
                        </li>
                        <li class="has-submenu" :class=" UserMenu ? 'active' : 'notactive'">
                            <a href="javascript:void()">Franchising <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'the-franchise' ? 'active' : 'notactive'"><router-link to="/the-franchise">La Franquicia</router-link></li>
                                <li :class="currentPath == 'association-franchise' ? 'active' : 'notactive'"><router-link to="/association-franchise">Asociaciones de Franquicia</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class=" BlogMenu ? 'active' : 'notactive'">
                            <a href="#">Actualidad <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li 
                                    :class="currentPath == 'news' ? 'active' : 'notactive'"
                                >
                                    <a href="/news">Noticias</a>
                                </li>
                                <li 
                                    :class="currentPath == 'interviews' ? 'active' : 'notactive'"
                                >
                                    <a href="/interviews">Entrevistas</a>
                                </li>
                                <li 
                                    :class="currentPath == 'videos' ? 'active' : 'notactive'"
                                >
                                    <a href="/videos">Videos</a>
                                </li>
                            </ul>
                        </li>
                        <li v-bind:class="{'active': currentPath == 'contact'}">
                            <router-link to="/contact">Contacto</router-link>
                        </li>
                    </ul>

                    

                </div>
                <ul 
                    class="nav header-navbar-rht"
                    v-if="isLoggedIn"
                >
                    <li class="nav-item">
                        <router-link class="nav-link header-login add-listing" to="add-listing"><i class="fa-solid fa-plus"></i> Nueva Ficha</router-link>
                    </li>
                    <li class="nav-item dropdown has-arrow logged-item">
                        <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img 
                                :src="userAvatar" 
                                alt=""
                            >
                            <span>{{ userData }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <router-link class="dropdown-item" to="/dashboard">Mi Cuenta</router-link>
                            <router-link class="dropdown-item" to="/profile">Configuraciones</router-link>
                            <router-link class="dropdown-item" to="/login">Cerrar Sesión</router-link>
                        </div>
                    </li>						
                </ul>
                <ul 
                    class="nav header-navbar-rht"
                    v-else
                >
                    <div class="d-flex align-items-center block-e">
                        <div class="cta-btn">
                            <router-link to="/login" class="btn">Franquicias /</router-link>
                            <router-link to="/signup" class="btn ms-1"> Inversionistas</router-link>
                        </div>
                    </div>
		        </ul>
                
            </nav>
        </div>
    </header>
    <!-- /Header -->
</template>

<script>
import axios from 'axios'; // Importar Axios

export default {
    data() {
        return {
            isLoggedIn: false,
            userData: '',
            userAvatar: '',
            idioms: ["[ES] - Español", "[EN] - Ingles", "[PT] - Portugues"],
            countries: []
        }
    },
    async mounted() {

        if (localStorage.getItem('tokenSession')) {
            this.isLoggedIn = true;
        }

        try {
            const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "countries?active=1&limit=100");
            // Actualiza la propiedad Category con los datos obtenidos 555
            this.countries = response.data.map(country => ({
                id: country.id, 
                text: country.name 
            }));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }

        this.userData = localStorage.getItem('userName')
        this.userAvatar = localStorage.getItem('userAvatar')

        $('body').append('<div class="sidebar-overlay"></div>');
        $(document).on('click', '#mobile_btn', function () {
            $('main-wrapper').toggleClass('slide-nav');
            $('.sidebar-overlay').toggleClass('opened');
            $('html').addClass('menu-opened');
            return false;
        });

        $(document).on('click', '.sidebar-overlay', function () {
            $('html').removeClass('menu-opened');
            $(this).removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });

        $(document).on('click', '#menu_close', function () {
            $('html').removeClass('menu-opened');
            $('.sidebar-overlay').removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        }); 
    },
}
</script>