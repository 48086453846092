<template>
    <!-- Login Section -->		
		<div class="login-content">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap password-form">
							
							<div class="login-header">
								<p>Ingrese su email para hacerle el envio de un correo electrónico con un enlace para reinicar su contraseña.</p>								
							</div>
							
							<!-- Login Form -->
							<b-form action="login">
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-mail"></i>
										<b-form-input 
											type="email" 
											class="form-control" 
											v-model="email"
                    						:state="emailState"
                    						aria-describedby="email-feedback"
											placeholder="Email"
											required
										/>

										<b-form-invalid-feedback
											v-if="email && !emailState" 
											id="email-feedback"
										>
											El formato de correo electrónico es inválido
										</b-form-invalid-feedback>
									</div>
								</div>
								<b-overlay
								:show="show"
								rounded
								opacity="1"
								spinner-small
								spinner-variant="primary"
								@hidden="onHidden"
								>
								<b-button 
									@click.prevent="signup()"
									block
									variant="primary w-100 login-btn" 
									type="submit"
									:disabled="emailState === false"
								>Enviar</b-button>	
								</b-overlay>								
							</b-form>
							<router-link to="/" class="back-home"><i class="fas fa-regular fa-arrow-left me-1"></i> Regresar</router-link>		
							<!-- /Login Form -->
											
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<!-- /Login Section -->
</template>

<script>
import axios from 'axios';

export default {
name: 'Recovery',
data: () => ({
	email: '',
	error: false,
	show: false,
	valid: true,
	emailEntered: false,
	rules: {
	required: (value) => !!value || 'Requerido',
	min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
	email: (value) => {
		const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return pattern.test(value) || 'El formato de correo electrónico inválido'
	},
	},
}),
computed: {
    emailState() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.email);
    },
  },
methods: {
	checkEmail() {},
	onHidden() {
		// Return focus to the button once hidden
		this.$refs.button.focus()
	},
	signup() {
		this.show = true
		axios
			.post(process.env.VUE_APP_API_DIRECTORY + 'password/create', {
				email: this.email,
			})
			.then((response) => {
				this.$router.push('/change-password')
			})
			.catch((error) => {
				this.error = true
			})
	},
},
}

</script>