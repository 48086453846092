<template>
    <div class="aboutbanner innerbanner">
    <div class="inner-breadcrumb">
          <div class="container">
            <div class="row align-items-center text-center">
                <div class="col-md-12 col-12 ">
                   <h2 class="breadcrumb-title">Franquiciar: Transformando Ideas en Oportunidades Globales.</h2>
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Bienvenido</a></li>
                            <li class="breadcrumb-item active" aria-current="page">¿Qué es Franquiciar?</li>
                        </ol>
                    </nav>							
                </div>
            </div>
          </div>		            
    </div>		            
</div>
</template>
