<template>
    <div class="mainwrapper error-page">
	   	<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 mx-auto">
						<div class="error-wrap">
						
							<div class="error-logo">
								<router-link to="index"><img class="img-fluid" src="../../../../assets/img/logo.svg" alt="img"></router-link>
							</div>
							<h2>Something went wrong</h2>
							<div class="error-img">
								<img class="img-fluid" src="../../../../assets/img/404-error.jpg" alt="img">
							</div>
							<router-link to="/" class="btn btn-primary rounded-pill">Regresar al Inicio</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>