<template>
    <!--Review  Section
    <div class="card review-sec  mb-0">
        <div class="card-header  align-items-center">
            <i class="fa-regular fa-comment-dots"></i>
            <h4>Reseñas</h4>
        </div>
        <div class="card-body">
            <div class="review-list">
                <ul class="">
                    <li class="review-box ">
                        <div class="review-profile">
                            <div class="review-img">
                                <img src="../../../assets/img/profiles/avatar-11.jpg" class="img-fluid" alt="img">
                            </div>
                        </div>
                        <div class="review-details">
                            <h6>María Rodríguez</h6>
                            <div class="rating">
                                <div class="rating-star">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fa-regular fa-star rating-overall"></i>
                                </div>
                                <div><i class="fa-sharp fa-solid fa-calendar-days"></i> Hace 1 mes</div>
                                <div>by: Demo Test</div>
                            </div>
                            <p>Como propietaria de una franquicia Go Green, estoy encantada con la experiencia. La marca no solo se preocupa por el medio ambiente, sino que también brinda un sólido respaldo operativo. La formación y el apoyo continuo han sido fundamentales para el éxito de mi negocio. Go Green es más que una franquicia, es una comunidad comprometida con el éxito sostenible.</p>
                            <div class="row">
                                <vue-easy-lightbox
                                    :visible="visible"
                                    :imgs="img"
                                    :index="index"
                                    @hide="handleHide"
							    ></vue-easy-lightbox>
                            </div>
                            <div class="reply-box ">
                                <p><a href="#" class="thumbsup"><i class="feather-thumbs-up"></i> Me Gusta
                                    </a>
                                    <a href="#" class="thumbsdown"><i class="feather-thumbs-down"></i> No me gusta </a>
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="review-box">
                        <div class="review-profile">
                            <div class="review-img">
                                <img src="../../../assets/img/profiles/avatar-01.jpg" class="img-fluid" alt="img">
                            </div>
                        </div>
                        <div class="review-details">
                            <h6>Carlos Pérez</h6>
                            <div class="rating">
                                <div class="rating-star">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fa-regular fa-star rating-overall"></i>
                                </div>
                                <div><i class="fa-sharp fa-solid fa-calendar-days"></i> Hace 2 semanas</div>
                                <div>by: Demo Test</div>
                            </div>
                            <p>Mi experiencia con la franquicia Go Green ha sido positiva en general. La propuesta ecológica es única y atractiva, lo que ha generado un interés considerable en mi local. La plataforma de apoyo es robusta, aunque en ocasiones me gustaría una mayor flexibilidad en algunas operaciones. En definitiva, Go Green es una excelente opción para emprendedores comprometidos con la sostenibilidad.</p>
                            <div class="reply-box ">
                                <p>
                                    <a href="#" class="thumbsup"><i class="feather-thumbs-up"></i>
                                        Me Gusta
                                    </a>
                                    <a href="#" class="thumbsdown"><i class="feather-thumbs-down"></i>
                                        No Me Gusta 
                                    </a>
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="review-box feedbackbox mb-0">
                        <div class="review-details">
                            <h6>Escribe tu reseña sobre esta Franquicia</h6>
                        </div>
                        <div class="card-body">
                            <b-form class="">
                                <div class="namefield">
                                    <div class="form-group">
                                        <b-form-input type="text" class="form-control" placeholder="Nombre y Apellido *" required />
                                    </div>
                                    <div class="form-group me-0">
                                        <b-form-input type="email" class="form-control" placeholder="Email*" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <textarea rows="4" class="form-control" placeholder="Reseña*"
                                        required></textarea>
                                </div>
                                <div class="reviewbox-rating">
                                    <p><span> Calificación</span>
                                        <i class="fas fa-star filled me-1"></i>
                                        <i class="fas fa-star filled me-1"></i>
                                        <i class="fas fa-star filled me-1"></i>
                                        <i class="fas fa-star filled me-1"></i>
                                        <i class="fas fa-star filled me-1"></i>
                                    </p>
                                </div>
                                <div class="submit-section">
                                    <b-button variant="primary submit-btn" type="submit"> Enviar</b-button>
                                </div>
                            </b-form>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    data() {
        return {
            visible: false,
			index: 0, // default: 0
			imgs: [
					{
					src: "galleryimg-1.jpg",
					},
					{
					src: "galleryimg-2.jpg",
					},
					{
					src: "galleryimg-3.jpg"
					},
					{
					src: "galleryimg-9.jpg"
					}
				],
				img: [
					{
					src: "https://picsum.photos/785/501",
					},
					{
					src: "https://picsum.photos/785/502",
					},
					{
					src: "https://picsum.photos/785/503",
					},
					{
					src: "https://picsum.photos/785/504",
					}
				],
            }
        },
        methods: {
			showImg(index) {
			this.index = index;
			this.visible = true;
			},
			handleHide() {
			this.visible = false;
		},
	},
}
</script>