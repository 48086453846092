<template>
    <div class="main-wrapper">
        <navbarfour />
        
        <div class="page-wrapper">
            <indexnow />

            <indexcowork />

            <indexspace />

            <indexfourclient />

            <indexfourpricing />

            <indexfourblog />

            <indexfourfooter />

            <scroll />
        </div>
    </div>
</template>