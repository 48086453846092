<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                    :title="title" 
                    :name="name" 
                    :text="text" 
                    :text1="text1" 
                    :cover="cover" 
                />
            
            <loginform />

            <foot />

            <scroll />
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            title: "Bienvenido",
            text: "Home",
            text1: "Login",
            cover: "login.png",
            name: "/",
        }
    },
}
</script>