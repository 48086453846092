<template>

    <div class="banner-btm">
        <div class="search-tab-col">
            <b-form action="listing-grid-sidebar" method="post">
                <div class="row align-items-center search-form">
                    <div class="col-12 col-lg-10 datepicker-col search-group">
                        <ul class="gap-3 d-flex">
                            <li
                                style="padding-right: 7px;"
                            >
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="icon-blk rounded-circle">
                                            <span class="fa-solid fa-magnifying-glass"></span>
                                        </div>
                                    </div>
                                    <b-overlay
                                        :show="show"
                                        :opacity="opacity"
                                        spinner-variant="primary"
                                        spinner-type="grow"
                                        spinner-small
                                        variant="white"
                                        rounded="sm"
                                        style="max-width: 320px;"
                                    >
                                    <div class="flex-grow-1 ms-4">
                                        <span class="label">Nombre</span>
                                        <b-form-input 
                                            type="text" 
                                            v-model="searchInput"
                                            name="checkout" 
                                            @keyup.enter="submitForm"
                                            class="border-0 text-truncate px-0 form-control datetimepicker" 
                                            style="border-bottom: 1px solid #a47a69 !important; border-radius: 0px;"
                                        />
                                    </div>
                                    </b-overlay>
                                </div>
                            </li>
                            <li
                                style=" padding-right: 9px;"
                            >
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="icon-blk rounded-circle">
                                            <span class="fa-solid fa-store"></span>
                                        </div>
                                    </div>
                                    <b-overlay
                                        :show="show"
                                        :opacity="opacity"
                                        spinner-variant="primary"
                                        spinner-type="grow"
                                        variant="white"
                                        spinner-small
                                        rounded="sm"
                                        style="max-width: 320px;"
                                    >
                                    <div class="flex-grow-1 ms-4">
                                        <span class="label">{{ categoryName }}</span>
                                        <div class="selection-list">
                                            <div class="lang-select">
                                                <vue-select 
                                                    class="select"
                                                    :options="categories"
                                                    v-model="category"
                                                    @select="updateCategory"
                                                    :settings="{ 
                                                        dropdownAutoWidth: true, 
                                                        width: '100%',
                                                        placeholder: 'Selecciona un Rubro' 
                                                    }"
                                                    width="100%"
                                                    placeholder="Selecciona un Rubro"
                                                />
                                                <span class="select-icon">
                                                    <span 
                                                        class="material-symbols-outlined"
                                                        style="color:#a47a69;"
                                                    >arrow_drop_down</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    </b-overlay>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="icon-blk rounded-circle">
                                            <span class="fa-regular fa-money-bill-1"></span>
                                        </div>
                                    </div>
                                    <b-overlay
                                        :show="show"
                                        :opacity="opacity"
                                        spinner-variant="primary"
                                        spinner-type="grow"
                                        spinner-small
                                        variant="white"
                                        rounded="sm"
                                        style="max-width: 320px;"
                                    >
                                    <div class="flex-grow-1 ms-3">
                                        <span class="label">{{ amountName }}</span>
                                        <div class="selection-list">
                                            <div class="lang-select">
                                                <vue-select 
                                                class="select"
                                                :options="rangeAmount"
                                                v-model="amount"
                                                @select="updateAmount"
                                                :settings="{ 
                                                    dropdownAutoWidth: true, 
                                                    width: '100%',
                                                    placeholder: 'Seleccione un Rango de Inversión' 
                                                }"
                                                placeholder="Seleccione un Rango de Inversión"/>
                                                <span class="select-icon">
                                                    <span 
                                                        class="material-symbols-outlined"
                                                        style="color:#a47a69;"
                                                    >arrow_drop_down</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    </b-overlay>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    <div class="col-12 col-lg-2 search-group">
                        <ul class="p-0">
                            <li>
                                <b-button 
                                    variant="btn theme-btn"
                                    @click="submitForm"
                                >
                                    Buscar 
                                </b-button>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-form>	
        </div>
    </div>

</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      categories: [], 
      category: '',
      opacity: 1,
      amount: '',
      show: true,
      searchInput: '',
      categoryName: '',
      amountName: '',
      rangeAmount: [
        { "id": 1, "text": "0,00 USD a 5.000,00 USD" },
        { "id": 2, "text": "5.001,00 USD a 15.000,00 USD" },
        { "id": 3, "text": "15.001,00 USD a 40.000,00 USD" },
        { "id": 4, "text": "40.001,00 USD a 100.000,00 USD" },
        { "id": 5, "text": "100.001,00 USD a 300.000,00 USD" },
        { "id": 6, "text": "Superior a 300.001,00 USD" },
      ]
    }
  },
  mounted() {
    // Llama al método fetchCategories cuando el componente se monta
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      this.show = true
      try {
        const response = await axios.get(process.env.VUE_APP_API_DIRECTORY + "categories?limit=300&type=0");
        // Actualiza la propiedad Category con los datos obtenidos
        this.categories = response.data.map(category => ({
          id: category.id, // Suponiendo que category.id es el valor deseado para el selector
          text: category.name // Suponiendo que category.name es el texto que se mostrará en el selector
        }));
        this.show = false
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    updateCategory() {
      
      //this.categoryName = this.category.text;
        const foundCategory = this.categories.find(category => category.id == this.category);
        console.log(foundCategory);
        // Si se encuentra la categoría, actualiza this.category con su nombre
        if (foundCategory) {
            this.categoryName = foundCategory.text; // Suponiendo que 'text' contiene el nombre de la categoría
        } else {
            console.error('Categoría no encontrada.');
        }
    },
    updateAmount() {
      
      //this.categoryName = this.category.text;
        const foundAmount = this.rangeAmount.find(amount => amount.id == this.amount);
        //console.log(foundCategory);
        // Si se encuentra la categoría, actualiza this.category con su nombre
        if (foundAmount) {
            this.amountName = foundAmount.text; // Suponiendo que 'text' contiene el nombre de la categoría
        } else {
            console.error('Categoría no encontrada.');
        }
    },
    async submitForm() {
        // Construye la URL con los parámetros de búsqueda
        const queryParams = new URLSearchParams();
        queryParams.append('category', this.category);
        queryParams.append('amount', this.amount);
        queryParams.append('searchInput', this.searchInput);
        const queryString = queryParams.toString();
        const url = `/listing?${queryString}`;

        // Redirige al usuario a la nueva URL
        this.$router.push(url);
    },
  }
}
</script>
