<template>
  <div class="flipbook-viewer" >
    
    <div 
    class="container" 
    id="container333"
    style="height: 600px;"
    ></div>

  </div>
</template>


<script>
export default {
  props: {
      file: {
          type: String,
          default: "",
      }
  },
  data() {
    return {
      currentPageIndex: 0,
      isFlipped: false
    };
  },
  mounted() {
      console.log(this.file);
      $('#container333').FlipBook({
          pdf: 'https://cors.amlconsulting.one:8200/'+this.file,
          template: {
            html: '/flipbook/default-book-view.html',
            styles: [
              '/flipbook/short-black-book-view.css'
            ],
            links: [
              {
                rel: 'stylesheet',
                href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
              }
            ],
            script: '/flipbook/default-book-view.js'
          }
       });

  }
};
</script>
