<template>
<div  v-if="!showComparison.selectedCompanies.length">
      <b-button 
        @click="compareFranchises" 
        block
        variant="outline-secondary"
        v-if="selectedCompanies.length > 1"
      ><i class="fas fa-compress-alt"></i> Comparar Franquicias</b-button></div>

    <b-card-group deck v-if="!showComparison.selectedCompanies.length">
      <b-row>
        <b-col 
          lg="4" 
          v-for="item in companiesList" 
          :key="item.id"
        >
          <b-card
            v-if="item.logo"   
            :img-src="item.logo.route" 
            img-alt="Image"
            img-height="200"
            img-top
            tag="article"
            style="max-width: 20rem;"
            @click="toggleSelection(item)"
            :class="{ 'selected': isSelected(item) }"
          >
            <template #header>
              <small class="text-muted">{{ item.name }}</small>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card-group>

    <div v-else>
      <div class="comparison-title-container d-flex justify-content-center align-items-center flex-column">
        <h2>{{ comparisonTitle }}</h2>

        <b-button 
          @click="resetComparison"
          variant="outline-secondary"
          class="mb-3" 
        >
          <i class="fas fa-sync"></i> Reiniciar búsqueda
        </b-button>
      </div>

      <div class="container">
					<div class="row">
					    <div 
						class="col-lg-4 d-flex col-md-4" 
						v-for="item in comparisonData" 
						:key="item"
						>
						    <div class="price-card flex-fill">
								<div class="price-head">

                  <div class="about-headings">
                  <div class="author-img">
                    <img 
                    v-if="item.logo"
                      :src="item.logo.route" 
                    alt="logo"
                    >
                  </div>
                  </div>

									<div class="price-level">
										<h6>{{ item.name }}</h6>
									</div>
									<h4
                    style="font-weight: 600;"
                  >
                    <range-price 
                      :idCompany="item.id"
                    ></range-price>
                  </h4>								
								</div>	
								<div class="price-body">

                  <franchise-data-table :idCompany="item.id" />

									<div>
										<a
                      :href="'/franquicia/'+item.link"
                      class="btn viewdetails-btn"
                    >
                      Ver Ficha
                  </a>
									</div>							
								</div>							
						    </div>
						</div>
						
					</div>
				</div>
      
    </div>

    <div  v-if="!showComparison.selectedCompanies.length">
      <b-button 
        @click="compareFranchises" 
        block
        variant="outline-secondary"
        v-if="selectedCompanies.length > 1"
      ><i class="fas fa-compress-alt"></i> Comparar Franquicias</b-button>
    </div>

</template>

<script>
import axios from 'axios';

export default {
  props: {
    initialCompanyId: {
      type: Number,
      required: false // Puedes ajustar esto según tus necesidades
    }
  },
  //props: ['initialCompanyId'],
  data() {
    return {
      companyData: {
        name: '',
        description: '',
        logo: '',
        rating: 0,
        investment: '',
      },
      modalShow: false,
      companiesList: [],
      comparisonTitle: "",
      selectedCompanies: [],
      showComparison: {
        selectedCompanies: [],
        comparisonTitle: ""
      }
    };
  },
  computed: {
    comparisonData() {
      const data = [];
      for (const company of this.selectedCompanies) {
        const rowData = { name: company.name };
        for (const key in company) {
          if (key !== 'id' && key !== 'name') {
            rowData[key] = company[key];
          }
        }
        rowData['id'] = company['id'];
        data.push(rowData);
      }
      return data;
    },
    comparisonTitle() {
      const formattedNames = this.selectedCompanies.map(company => company.name.toUpperCase());
      return formattedNames.join(' vs ');
    },
    comparisonKeys() {
      if (this.selectedCompanies.length === 0) return [];
      return Object.keys(this.selectedCompanies[0]).filter(key => key !== 'id' && key !== 'name');
    },
  },
  mounted() {
    this.selectInitialCompany();
    this.loadDataFromEndpoint();
    
  },
  methods: {
    selectInitialCompany() {
      console.log('holis '+this.initialCompanyId)
      if (this.initialCompanyId) {
        const initialCompany = this.companiesList.find(company => company.id === this.initialCompanyId);
        if (initialCompany) {
          this.selectedCompanies.push(initialCompany);
        } else {
          console.error('La empresa inicial no se encontró en la lista de empresas disponibles.');
          // Aquí puedes mostrar un mensaje de error o manejar la situación según tu lógica de negocio.
        }
      }
    },
    loadDataFromEndpoint() {
      axios.get(process.env.VUE_APP_API_DIRECTORY + 'companies-list?order=updated_at&by=asc&limit=30&active=1', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + process.env.VUE_APP_TOKEN,
        },
      })
        .then(response => {
          this.companiesList = response.data;
        })
        .catch(error => {
          console.error('Error al cargar los datos:', error);
        });
    },
    toggleSelection(company) {
      if (this.isSelected(company)) {
        this.selectedCompanies = this.selectedCompanies.filter(c => c.id !== company.id);
      } else {
        if (this.selectedCompanies.length < 3) {
          this.selectedCompanies.push(company);
        } else {
          alert('Solo puedes seleccionar hasta 3 franquicias para comparar.');
        }
      }
    },
    isSelected(company) {
      return this.selectedCompanies.some(c => c.id === company.id);
    },
    compareFranchises() {
      //this.showComparison = true;
      this.showComparison.selectedCompanies = [...this.selectedCompanies];
    },
    resetComparison() {
      this.selectedCompanies = [];
      this.showComparison.selectedCompanies = [];
      //this.showComparison = false;
    },
    getLabel(key) {
      return key.toUpperCase();
    },
  },
};
</script>

<style>
.selected {
  border: 2px solid #ff9800 !important; /* Cambia el color del borde al seleccionar */
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3) !important;
  cursor: pointer; /* Cambia el cursor al pasar sobre el card */
}
.comparison-title-container {
  height: 15vh; /* Altura completa de la pantalla */
}
</style>
