<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <image-breadcrumb 
                :title="title" 
                :name="name" 
                :text="text" 
                :text1="text1" 
                :cover="cover" 
            />
            
            <signuplogin />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "REGISTRO EXCLUSIVO DE INVERSIONISTAS",
            text: "Bienvenido",
            text1: "Register",
            cover: "signup.jpg",
            name: "/",
        }
    },
}
</script>