<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <aboutbreadcrumb />
            
            <aboutcontent />

            <aboutpopular />

            <indexcta />

            <indexclient />

            <aboutpartners />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>

</script>

