<template>

    <b-modal id="modal-1" v-model="modal" title="BootstrapVue">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
  
    <mainsidebar/> 
    <div class="main-wrapper" style="background: #f9fafc;">
      <div class="page-wrapper" style="background: #f9fafc;">
        <!-- Dashboard Content -->
        <div class="dashboard-content">
          <div class="container">
            <dashboardmenu />
  
            <div class="dash-listingcontent dashboard-info">
              <div class="dash-cards card">
                <div class="card-header">
                  <h4>Datos de la Ficha</h4>
                  <b-button 
                    variant="outline-primary"
                    v-b-toggle.accordion-2
                    size="sm"
                    @click="createData"
                  >
                    Nuevo Dato
                  </b-button>
                </div>
  
                <div class="card-body">
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-4">
                      <b-collapse 
                        id="accordion-2" 
                        accordion="my-accordion" 
                        role="tabpanel"
                        v-model="modal" 
                      >
                        <b-card-body>
                          <div class="card-header">
                            <h5 v-if="recordId == 0">Crear Dato</h5>	
                            <h5 v-else>Editar Dato</h5>							
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input">
                                  <label class="col-form-label">Nombre</label>
                                  <input 
                                    type="text" 
                                    class="form-control pass-input" 
                                    placeholder="Escriba el nombre del país"
                                    v-model="editCountry.name"
                                  >		
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input formlast-input-inner">
                                  <label class="col-form-label">Código ISO</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="Escriba el código ISO"
                                    v-model="editCountry.iso2"
                                  >		
                                </div>
                              </div>											
                            </div>
                            <div class="row">
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input">
                                  <label class="col-form-label">Código de Marcación</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="Escriba el código de marcación"
                                    v-model="editCountry.dial"
                                  >		
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input formlast-input-inner">
                                  <label class="col-form-label">Moneda</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="Escriba el código de la moneda"
                                    v-model="editCountry.currency"
                                  >		
                                </div>
                              </div>											
                            </div>
                            <div class="row">
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input">
                                  <label class="col-form-label">Bandera (URL)</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="Escriba la URL de la bandera"
                                    v-model="editCountry.flag"
                                  >		
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group formlast-input">
                                  <label class="col-form-label"></label>
                                  <label class="custom_check">
                                    <input 
                                      type="checkbox" 
                                      name="country-active"
                                      v-model="editCountry.active"
                                    >
                                    <span class="checkmark"></span> Activo
                                  </label>
                                </div>
                              </div>											
                            </div>
                          </div>
                          <b-button 
                            variant="primary" 
                            type="submit"
                            @click="handleSubmit"
                          >Guardar</b-button>	
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
  
                  <!-- Resto de tu código aquí -->
                  <div class="listing-search">
							<div class="filter-content form-group">
								<div class="group-img">
									<input type="text" class="form-control" placeholder="Search...">
									<i class="feather-search"></i>
								</div>
							</div>
							<div class="sorting-div">
								<div class="sortbyset">
									<span class="sortbytitle">Ordenar por</span>
									<div class="sorting-select">
										<vue-select :options="Newest" placeholder="Newest" />
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<a-table
                                class="stripped table-hover listing-table datatable"
                                :columns="columns"
                                :data-source="countries" 
     
                            >
                                <template #bodyCell="{ column, record }">
                                <template
                                    v-if="column.key === 'name'"
                                >
                                    <div 
                                        class="listingtable-img"
                                    >
                                            <span class="status-text">{{ record.name }}</span>
                                            <br/>
                                            <img 
                                                class="img-fluid avatar-img"
                                                v-if="record.flag" 
                                                style="max-width: 110px; "
                                                :src="record.flag" 
                                                alt=""
                                            >
                                    </div>
                                </template>
                                <template 
                                    v-else-if="column.key === 'active'"
                                >
                                    <span 
                                    v-if="record.active == true" 
                                    class="status-text">Activo</span>

                                    <span 
                                    v-else 
                                    style="color:red;"
                                    class="status-text">Inactivo</span>

                                </template>
                                <template v-else-if="column.key === 'action'">
                                    <div class="action">
                                        <a 
                                            class="action-btn btn-edit"
                                            @click="openEditModal(record.id)"
                                        >
                                            <i class="feather-edit-3"></i>
                                        </a>
                                        <a 
                                            href="javascript:void(0)" 
                                            class="action-btn btn-trash"
                                            @click="deleteData(record.id)"
                                        >
                                            <i class="feather-trash-2"></i>
                                        </a>
                                    </div>
                                </template>
                                </template>
                            </a-table>
					</div>
					<!--Pagination-->
                    <div class="blog-pagination" v-if="total != 0">
							<nav>
							<ul class="pagination">
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('prev') }"
								>
									<a @click="nextPage('left')" href="#"  class="page-link"  aria-label="Previous">
										<i class="fas fa-regular fa-arrow-left"></i>
									</a>
								</li>
								<li class="justify-content-center pagination-center">
									<div class="pagelink">
										<ul>
											<li 
                                                class="page-item"
                                                v-for="(column, index) in pages" 
                                                :key="index" 
                                            >
												<a 
                                                    :class="{ 'page-link': true, 'active': page === column }"
                                                    @click="nextPage(column)"
                                                    href="#"
                                                >{{ column }}</a>
											</li>
										</ul>
									</div>
								</li>
								<li 
									class="page-item nextlink"
									:class="{ 'disabled': isPageButtonDisabled('next') }"
								>
                                    <a 
                                        class="page-link" 
                                        @click="nextPage('right')"
                                        href="#"
                                    >
                                        <i class="fas fa-regular fa-arrow-right"></i>
                                    </a>
                                </li>
							</ul>
						</nav>
						</div>
						<!--/Pagination-->
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        countries: [],
        editCountry: {
          name: '',
          iso2: '',
          dial: '',
          currency: '',
          flag: '',
          active: false,
        },
        modal: false,
        recordId: 0,
        columns : [
                {
                    title: "Nombre",
                    dataIndex: "name",
                    key: "name",
                    sorter: {
                    compare: (a, b) => {
                        a = a.name.toLowerCase();
                        b = b.name.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "ISO",
                    dataIndex: "iso2",
                    key: "iso2",
                    sorter: {
                    compare: (a, b) => {
                        a = a.iso2.toLowerCase();
                        b = b.iso2.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Activo",
                    dataIndex: "active",
                    key: "active",
                    sorter: {
                    compare: (a, b) => {
                        a = a.active.toLowerCase();
                        b = b.active.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                    },
                },
                {
                    title: "Action",
                    key: "action",
                    class: "text-end",
                    sorter: true,
                },
            ],

        // variables para la paginacion

        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        loadingText: ''
      };
    },
    mounted() {
      this.fetchCountries();
    },
    computed: {
		isPageButtonDisabled() {
			return direction => {
				if (direction === 'prev') {
				return this.page === 1;
				} else if (direction === 'next') {
				return this.page === this.pages;
				}
			};
		}
	},
    methods: {
      async fetchCountries() {
        try {

           const response = await axios.get(
                process.env.VUE_APP_API_DIRECTORY + 
                "countries?order=created_at&by=asc&page=" + 
                this.page, 
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer "+process.env.VUE_APP_TOKEN
                }
            });
          this.countries = response.data.data;
          //datos para la paginacion
          if (this.countries.length < 1) {
                    this.loadingText = 'No hay registros'
                }
                this.totalItems = response.data.total
                this.itemsPerPage = response.data.per_page
                if (response.data.from) {
                    this.page = response.data.current_page
                    this.pages = response.data.last_page
                } else {
                    this.page = 1
                    this.pages = 1
                }
            
        } catch (error) {
          console.error('Error fetching countries:', error);
        }
      },
      openEditModal(countryId) {
        const country = this.countries.find(c => c.id === countryId);
        this.recordId = countryId;
        this.editCountry = {
          name: country.name,
          iso2: country.iso2,
          dial: country.dial,
          currency: country.currency,
          flag: country.flag,
          active: country.active,
        };
        this.modal = true;
      },

      nextPage(orientation) {
			if (orientation === 'right' && this.page < this.pages) {
				this.page++;
			} else if (orientation === 'left' && this.page > 1) {
				this.page--;
			} else {
				this.page = orientation;
			}
			this.fetchCountries();
		},

      async deleteCountry(countryId) {
        try {
          const response = await axios.delete(process.env.VUE_APP_API_DIRECTORY + "countries/" + countryId );
          // Manejar la respuesta según sea necesario
        } catch (error) {
          console.error('Error deleting country:', error);
        }
      },
      async handleSubmit() {
                try {

                  if(this.editCountry.active === true){
                    this.editCountry.active = 1;
                  }else{
                    this.editCountry.active = 0;
                  }

                    if (this.recordId == 0) {
                        const response = await axios.post(process.env.VUE_APP_API_DIRECTORY + "countries", this.editCountry, {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    } else {
                        const response = await axios.patch(process.env.VUE_APP_API_DIRECTORY + "countries/" + this.recordId, this.editCountry, {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": "Bearer " + process.env.VUE_APP_TOKEN
                            }
                        });
                    }
                    this.modal = false;
                    this.fetchCountries();
                } catch (error) {
                    console.error('Error submitting data:', error);
                }
            },

    },
  };
  </script>
  