<template>
    <!-- Bookmark Content -->
    <div class="dashboard-content">		
			<div class="container">
				<div class="">
				<ul class="dashborad-menus">
					<li>
						<router-link 
							to="/dashboard"
						>
							<i class="feather-grid"></i> <span>Mi Cuenta</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/profile"
						>
							<i class="fa-solid fa-user"></i> <span>Perfil</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/my-listing"
						>
							<i class="feather-list"></i> <span>Mis Fichas</span>
						</router-link>
					</li>
					<li class="active">
						<router-link 
							to="/bookmarks"
						>
							<i class="fas fa-solid fa-heart"></i> <span>Mis Favoritos</span>
						</router-link>
					</li>
					<li>
						<router-link 
							to="/login"
						>
							<i class="fas fa-sign-out-alt"></i>
							<span>Cerrar Sesión</span>
						</router-link>
					</li>
				</ul>
			</div>
				<div class="bookmarks-content grid-view featured-slider">
					<b-overlay :show="show" rounded="sm">
				    <div class="row">
					    <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in Bookmarks" :key="item.id">
							<div class="card aos aos-init aos-animate" data-aos="fade-up">
				    			<div class="blog-widget">
									<div class="blog-img">
										<router-link 
											:to="'/franquicia/'+item.rateable.link" 
										>
											<img 
												v-if="item.rateable.uploads[0]"
												:src="item.rateable.uploads[0].route" 
												class="img-fluid" 
												alt="blog-img"
											>
											<img 
												v-else
												:src="require(`../../../assets/img/work/city-01.jpg`)" 
												class="img-fluid" 
												alt="blog-img"
											>
                                        </router-link>
					    				<div 
											class="fav-item"
											v-if="item.rateable.outstanding"
										>
											<span class="Featured-text">Recomendado</span>
												<a href="javascript:void(0)" class="fav-icon">
							    					<i class="fas fa-heart" style="color: #ff4545;"></i>
						    					</a>										
										</div>	
									</div>
									<div class="bloglist-content">
										<div class="card-body">
											<div class="blogfeaturelink">
											    <div 
													class="grid-author"
													style="background-color: white;"
												>
												<img 
													:src="item.rateable.logo.route" 
													alt="author"
												>	
												</div>
												<div class="blog-features">
												   <a 
												   	href="javascript:void(0)"
													v-for="(column, index) in item.rateable.categories" 
												   >
													<span> <i :class="column.icon"></i> {{ column.name }}
													</span>
												   </a>
												</div>		
											</div> 
											<h6>
											<router-link 
												:to="'/franquicia/'+item.rateable.link" 
											>{{ item.rateable.name }}</router-link></h6>																	 
											<div 
												class="blog-location-details"
												style="justify-content: left !important;"
												v-if="item.rateable.countries"
											>
												<div 
													class="location-info"
													v-for="(column, index) in item.rateable.countries"
												>
													<img 
														:src="column.flag" 
														width="19"
														class="m-1"
														alt="author"
													>
												</div>
											</div>
											<div class="amount-details">
												<div class="amount" style="text-decoration: none !important;">
													<range-price 
                                                        :idCompany="item.rateable.id"
                                                    ></range-price>
													
												</div>											
											</div>	
										</div>	
									</div>			 
								</div> 
							</div>
						</div>
						
						 <!--Pagination--> 
						<div class="blog-pagination">
						    <nav>
							    <ul class="pagination">
								    <li class="page-item previtem">
										<a class="page-link" href="#"><i class="fas fa-regular fa-arrow-left"></i></a>
									</li>
									<li class="justify-content-center pagination-center"> 
										<div class="pagelink">
										    <ul>
												<li class="page-item">
													<a class="page-link" href="#">1</a>
												</li>
												<li class="page-item active">
													<a class="page-link" href="#">2 <span class="visually-hidden">(current)</span></a>
												</li>
												<li class="page-item">
													<a class="page-link" href="#">3</a>
											   </li>
											   <li class="page-item">
													<a class="page-link" href="#">...</a>
											   </li>
											   <li class="page-item">
													<a class="page-link" href="#">14</a>
											   </li>
									       </ul>
									    </div>													
									</li>													
									<li class="page-item nextlink">
										<a class="page-link" href="#"><i class="fas fa-regular fa-arrow-right"></i></a>
									</li>
								</ul>
							</nav>
						</div>
						<!--/Pagination-->	
						
					</div>
					</b-overlay>						
				</div>
			</div>		
		</div>		
		<!-- /Bookmark Content -->
</template>

<script>
import axios from 'axios'; // Importar axios

export default {
    data() {
        return {
            Bookmarks: [],
			show: true
        }
    },
    mounted() {
        // Llamar a la función para cargar los marcadores
        this.loadBookmarks();
    },
    methods: {
        loadBookmarks() {
			
            // Configuración de la solicitud
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("tokenSession")
                }
            };

            // Realizar la solicitud HTTP
            axios.get(process.env.VUE_APP_API_DIRECTORY + "rating-management/get-user-list?userId=" + localStorage.getItem("userId"), config)
                .then(response => {
                    // Asignar los datos de la respuesta al array Bookmarks
                    this.Bookmarks = response.data.data;
					this.show = false
                })
                .catch(error => {
                    console.error('Error al cargar los marcadores:', error);
                });
        }
    }
}
</script>