<template>
    <!--Popular Location-->
		<div class="aboutpopular-locations">
		    <div class="container">
				<div class="row">
				    <div 
						class="col-lg-12 col-md-12" 
					>
					    <div 
							class="aboutlocation-details usa"
							style="height: 400px;"
						>
							<div 
								class="aboutloc-img"
							>
								<iframe 
									width="100%" 
									height="400px" 
									src="https://www.youtube.com/embed/S1YyFF3VnOk" 
									title="Franquicias y Negocios. Encontrá tu franquicia! Franquiciar Argentina." 
									frameborder="0" 
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
									allowfullscreen
								></iframe>
							</div>				
						</div>				
					</div>
				</div>
		   </div>
		</div>
		<!--/Popular location-->
</template>

<script>
	import AboutPopular from '../../../assets/json/aboutPopular.json'
	export default {
		data() {
			return {
				AboutPopular: AboutPopular
			}
		},
	}
</script>