<template>
    <!--contact Information-->	
    <div class="contactus-info">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 contactus-img col-md-12">
						<div class="contactleft-info">
							<img src="../../../assets/img/contactleftimg.jpg" class="img-fluid" alt="">
							<div class="contactinfo-content">
								<div class="contact-hours">
									 <h6>Contacto</h6>
									 <ul>
										 <li>Teléfono / WhatsApp : 1 (786) 408-9356</li>								 
										 <li>Email : info@franquiciar.com</li>								 
									 </ul>
								</div>						
							</div>
						</div>
					</div>
					<div class="col-lg-6 contactright-map col-md-12">
						 <div class="google-maps">
						     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.8862835683544!2d-73.98256668525309!3d41.93829486962529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd0ee3286615b7%3A0x42bfa96cc2ce4381!2s132%20Kingston%20St%2C%20Kingston%2C%20NY%2012401%2C%20USA!5e0!3m2!1sen!2sin!4v1670922579281!5m2!1sen!2sin" width="600" height="544" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
						 </div>
					</div>				
				</div>		
			</div>		
		</div>		
		<!--/contact Information-->	
		
		<!--contact Form-->	
        <section class="contactusform-section">
		       <div class="container">	
                    <div class="contact-info">
					   <h2>Solicita información acerca de las Franquicias</h2> 
					   <p>Estamos aquí para ayudarte</p>
				   </div>		       
                   <div class="row align-items-center">
						<div class="col-lg-5 col-md-5">
							<div class="cta-img">
								<img 
									src="../../../assets/img/contact.jpg" 
									class="img-fluid" 
									alt="CTA"
									style="border-radius: 30px;"
								>
							</div>
						</div>
						<div class="col-lg-7 col-md-7">
						    
							<div class="contactus-form">
								<iframe>
									<script data-b24-form="inline/18/ym9n73" data-skip-moving="true">
										(function(w,d,u){
										var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
										var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
										})(window,document,'https://cdn.bitrix24.com/b18211553/crm/form/loader_18.js');
									</script>
									<div id="loader"></div>
								</iframe>
							    
							
							</div>
						</div>
				   </div>				   
			   </div>		
		</section>		
		<!--/contact Form-->	
</template>

<script>
export default {
	data() {
		return {
			
		}
	},
}
</script>